import React from 'react'

import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'
import Content from '../../Shared/UI/Content'
import cx from 'classnames'

import styles from './style.module.sass'
import VideoPlayer from '../Video'
import AccessToCommunity from '../../Testosterone101Page/AccessToCommunity'

import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/helpers'

export default ({ podcastIntro }) => {
  return (
    <>
      <Container classes={styles.outer}>
        <Wrapper classes={styles.wrapper}>
          <Item classes={cx(styles.contentContainer, styles.offset)}>
            <VideoPlayer classes={styles.videoPlayer} videoSrc={podcastIntro.videoUrl} />
          </Item>
        </Wrapper>
      </Container>
      <AccessToCommunity />
      <Container classes={styles.outer}>
        <Wrapper classes={styles.wrapper}>
          <Item classes={cx(styles.contentContainer, styles.description)}>
            <div className={styles.aboutDoctor}>
              <Title classes={styles.aboutHeading}>{podcastIntro.aboutDoctor}</Title>
              {podcastIntro.paragraphs &&
                podcastIntro.paragraphs.map((paragraph, index) => {
                  return (
                    <React.Fragment key={paragraph._key}>
                      <Content classes={styles.content}>{prepareParagraph(paragraph.text)}</Content>
                      {index === 0 && (
                        <img
                          {...srcSetProps(sanityImageUrl(podcastIntro.image))}
                          alt={podcastIntro.image?.caption}
                          className={styles.image}
                        />
                      )}
                    </React.Fragment>
                  )
                })}
            </div>
            <img
              {...srcSetProps(sanityImageUrl(podcastIntro.image))}
              alt={podcastIntro.image?.caption}
              className={styles.image}
            />
          </Item>
        </Wrapper>
      </Container>
    </>
  )
}
