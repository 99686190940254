import React, { useState } from 'react'
import { prepareParagraph } from '/src/utils/helpers'
import ReactSlider from 'react-slider'
import styles from './style.module.sass'

export default ({ calculator }) => {
  if (!calculator || calculator.isHide) return null

  const [weight, setWeight] = useState(180)

  return (
    <div className={styles.calculator}>
      <div className={styles.wrap}>
        <div className={styles.info}>
          <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: calculator.title }} />
          <p className={styles.description}>{prepareParagraph(calculator.info)}</p>
        </div>
        <div className={styles.slider}>
          <p className={styles.sliderWeight}>{calculator.currentWeightTitle}</p>
          <p className={styles.sliderValue}>{weight}</p>
          <ReactSlider
            className={styles.sl}
            min={130}
            max={550}
            defaultValue={weight}
            thumbClassName={styles.slThumb}
            trackClassName={styles.slTrack}
            onChange={(value, index) => {
              setWeight(value)
            }}
          />
          <p className={styles.sliderLose}>{calculator.oneYearTitle}</p>
          <p className={styles.sliderAfter}>{parseInt(weight * 0.169)}</p>
        </div>
      </div>
    </div>
  )
}
