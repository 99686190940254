import React from 'react'
import cx from 'classnames'
import ChartImage from '../../../images/results-graph.png'
import { imageAutoFormat, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import Slider from 'react-slick'
import styles from './style.module.sass'

export default ({ testimonials }) => {
  const sliderSettings = {
    arrows: false,
    dots: true,
    autoplay: false,
    slidesToShow: 1,
    infinite: false,
    dotsClass: styles.dots,
  }

  const getResult = (index, result) => ({
    paddingBottom: `${result * 0.1 * 0.55}%`,
  })

  return (
    <div className={styles.testimonials}>
      <div className={styles.wrap}>
        <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: testimonials.title }} />

        <div className={styles.slider}>
          <Slider {...sliderSettings}>
            {testimonials.items &&
              testimonials.items.map((item, index) => (
                <div
                  className={cx(styles.sliderItem, { [styles.sliderItemActive]: index === 0 })}
                  key={item._key}
                  data-index={index}
                >
                  <div className={styles.inside}>
                    <div className={styles.left}>
                      <div className={styles.top}>
                        <div className={styles.image}>
                          <img {...srcSetProps(sanityImageUrl(item.image), [[200, 1980]])} alt={item.name} />
                        </div>
                        <div className={styles.about}>
                          <p className={styles.name}>{item.name}</p>
                          <p className={styles.information}>{item.information}</p>
                          <p className={styles.verified}>Verified buyer</p>
                        </div>
                      </div>
                      <div className={styles.bottom}>
                        <p className={styles.review} dangerouslySetInnerHTML={{ __html: item.review }} />
                      </div>
                    </div>
                    <div className={styles.right}>
                      <img src={imageAutoFormat(ChartImage)} alt="Chart" className={styles.chart} />
                      <div className={styles.before} style={getResult(index, item.resultBefore)}>
                        <span className={styles.label}>Before</span>
                      </div>
                      <div className={styles.after} style={getResult(index, item.resultAfter)}>
                        <span className={styles.label}>After</span>
                        <span className={styles.multiplier}>{item.multiplier}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}
