/* eslint-disable react/display-name */
import React from 'react'
import Button from '../../Shared/UI/Button'
import cx from 'classnames'
import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'
import Content from '../../Shared/UI/Content'
import styles from './style.module.sass'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/helpers'

export default ({ testosteroneRestoration }) => (
  <>
    <Container classes={styles.outer}>
      <Wrapper classes={styles.wrapper}>
        <Item classes={styles.contentContainer}>
          <Title classes={styles.containH2}>
            <div
              dangerouslySetInnerHTML={{
                __html: testosteroneRestoration.title,
              }}
            />
          </Title>
        </Item>
        <Item classes={styles.contentContainer}>
          <Title blueLine={false} classes={styles.headline}>
            {testosteroneRestoration.serms.title}
          </Title>
          <Content classes={styles.content} tag="div">
            {testosteroneRestoration.serms.paragraphs &&
              testosteroneRestoration.serms.paragraphs.map((paragraph, index) => (
                <React.Fragment key={paragraph._key}>
                  <p>{prepareParagraph(paragraph.text)}</p>
                  {index === 0 && (
                    <img
                      {...srcSetProps(sanityImageUrl(testosteroneRestoration.serms?.image))}
                      alt={testosteroneRestoration.serms?.image?.caption}
                      className={styles.sermImage}
                    />
                  )}
                </React.Fragment>
              ))}
          </Content>
        </Item>
        <Item classes={styles.contentContainer}>
          <Title blueLine={false} classes={styles.headline}>
            {testosteroneRestoration.enclomiphene.title}
          </Title>
          <Content classes={styles.content} tag="div">
            {testosteroneRestoration.enclomiphene.paragraphs &&
              testosteroneRestoration.enclomiphene.paragraphs.map((paragraph, index) => (
                <React.Fragment key={paragraph._key}>
                  <p>{prepareParagraph(paragraph.text)}</p>
                  {index === 0 && (
                    <img
                      {...srcSetProps(sanityImageUrl(testosteroneRestoration.enclomiphene?.image))}
                      alt={testosteroneRestoration.enclomiphene?.image?.caption}
                      className={styles.enclomipheneImage}
                    />
                  )}
                </React.Fragment>
              ))}
          </Content>
        </Item>
      </Wrapper>
    </Container>
    <div className={styles.fullBackground}>
      <Container classes={styles.outer}>
        <Wrapper classes={cx(styles.wrapper, styles.blueContainer)}>
          <Content classes={styles.blueText}>{prepareParagraph(testosteroneRestoration.blueTextFirst)}</Content>
        </Wrapper>
      </Container>
    </div>
    <Container classes={styles.outer}>
      <Wrapper classes={styles.wrapper}>
        <Item classes={cx(styles.contentContainer, styles.clinicallyMeaningfulResults)}>
          <Title blueLine={false} classes={cx(styles.headline)}>
            {testosteroneRestoration.clinicallyMeaningfulResults.title}
          </Title>
          <Content classes={styles.content}>
            {prepareParagraph(testosteroneRestoration.clinicallyMeaningfulResults.description)}
          </Content>
          <img
            {...srcSetProps(sanityImageUrl(testosteroneRestoration.clinicallyMeaningfulResults?.image))}
            alt={testosteroneRestoration.clinicallyMeaningfulResults?.image?.caption}
            className={styles.mainImage}
          />
          <Content classes={cx(styles.content, styles.revertOrdering)} tag="div">
            {testosteroneRestoration.clinicallyMeaningfulResults.paragraphs &&
              testosteroneRestoration.clinicallyMeaningfulResults.paragraphs.map((paragraph, index) => (
                <React.Fragment key={paragraph._key}>
                  {index === 0 && (
                    <img
                      {...srcSetProps(sanityImageUrl(testosteroneRestoration.clinicallyMeaningfulResults?.image2))}
                      alt={testosteroneRestoration.clinicallyMeaningfulResults?.image2?.caption}
                      className={styles.resultsImage}
                    />
                  )}
                  <p>{prepareParagraph(paragraph.text)}</p>
                </React.Fragment>
              ))}
          </Content>
        </Item>
        <Item classes={styles.contentContainer}>
          <Title blueLine={false} classes={cx(styles.headline, styles.withoutPaddingTop)}>
            {testosteroneRestoration.sideEffects.title}
          </Title>
          <Content classes={styles.content}>
            {prepareParagraph(testosteroneRestoration.sideEffects.description)}
          </Content>
          <div className={styles.labels}>
            {testosteroneRestoration.sideEffects.labels &&
              testosteroneRestoration.sideEffects.labels.map(label => (
                <div key={label._key}>
                  <Title blueLine={false} classes={styles.label}>
                    {label.label}
                  </Title>
                  <Content classes={styles.comment} tag="div">
                    {label.comment}
                  </Content>
                </div>
              ))}
          </div>
        </Item>
      </Wrapper>
    </Container>
    <div className={styles.fullBackground}>
      <Container classes={styles.outer}>
        <Wrapper classes={cx(styles.wrapper, styles.blueContainer)}>
          <Content classes={styles.blueText}>{prepareParagraph(testosteroneRestoration.blueTextSecond)}</Content>
          <Button
            btnType="full"
            gatsbyLink={true}
            classes={styles.button}
            url={testosteroneRestoration.getStartedNowUrl}
          >
            GET STARTED NOW
          </Button>
        </Wrapper>
      </Container>
    </div>
    <Container classes={styles.outer} id="supplement">
      <Wrapper classes={styles.wrapper}>
        <Item classes={styles.contentContainer}>
          <Title blueLine={false} classes={styles.headline}>
            {testosteroneRestoration.calciumSaccharate.title}
          </Title>
          <Content classes={styles.content} tag="div">
            {testosteroneRestoration.calciumSaccharate.paragraphs &&
              testosteroneRestoration.calciumSaccharate.paragraphs.map((paragraph, index) => (
                <React.Fragment key={paragraph._key}>
                  {paragraph.title && (
                    <span
                      style={{ fontWeight: '600', color: 'black' }}
                      dangerouslySetInnerHTML={{ __html: paragraph.title }}
                    />
                  )}
                  <p>{prepareParagraph(paragraph.text)}</p>
                  {index === 0 && (
                    <img
                      {...srcSetProps(sanityImageUrl(testosteroneRestoration.calciumSaccharate?.image))}
                      alt={testosteroneRestoration.calciumSaccharate?.image?.caption}
                      className={styles.sermImage}
                    />
                  )}
                </React.Fragment>
              ))}
          </Content>
        </Item>
      </Wrapper>
    </Container>
  </>
)
