import React from 'react'
import Navigation from '../../Nav/Navigation'
import { prepareParagraph, sanityImageUrl, srcSetProps, titleToId } from '../../../../utils/helpers'
import styles from './style.module.sass'

export default ({ howItWorks, links }) => {
  return (
    <div className={styles.howItWorks}>
      <div className={styles.anchor} id={titleToId(howItWorks.title)} data-js="scroll-section" />
      <div className={styles.left}>
        <h2 className={styles.title}>{howItWorks.title}</h2>
        <div className={styles.content}>
          <p className={styles.info}>{howItWorks.info}</p>
          <p className={styles.description}>{prepareParagraph(howItWorks.description)}</p>
        </div>
      </div>
      <div className={styles.right}>
        <Navigation links={links} inside={true} />
        <div className={styles.box}>
          <img
            {...srcSetProps(sanityImageUrl(howItWorks.image))}
            alt={howItWorks.image?.caption}
            className={styles.image}
          />
          <p className={styles.protocol}>{howItWorks.protocol}</p>
          <p className={styles.ingredients} dangerouslySetInnerHTML={{ __html: howItWorks.ingredients }} />
        </div>
      </div>
    </div>
  )
}
