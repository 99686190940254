import React, { useState } from 'react'
import cx from 'classnames'
import { formAccessHandler } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ classes }) => {
  const [submit, setSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const emailInputRandomId = Math.random()

  return (
    <div className={styles.subscription}>
      <div className={styles.wrap}>
        <div className={styles.left}>
          <h2 className={styles.title}>
            Early Access to New Products,
            <br />
            e-books & insights
          </h2>
        </div>
        <div className={styles.right}>
          {!submit && (
            <form
              onSubmit={(e) => formAccessHandler(e, loading, setLoading, setSubmit)}
              className={cx(styles.form, {
                [styles.loading]: loading,
              })}
            >
              <div className={styles.formRow}>
                <label for="email" className={styles.formLabel}>
                  Enter your email
                </label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  id="email"
                  className={cx(styles.formInput)}
                />
                <input type="submit" value="" className={cx(styles.formSubmit)} />
              </div>
              <p className={styles.formAgree}>By entering your email you agree to our privacy policy.</p>
            </form>
          )}
          {submit && (
            <div className={cx(styles.formDescription)}>
              We will send you an email shortly. Please check your mailbox.
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
