import React from 'react'
import { Link } from 'gatsby'
import { srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import SwiperObj, { FreeMode, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './style.module.sass'

export default ({ resources }) => {
  SwiperObj.use([FreeMode, Mousewheel])

  const sliderSettings = {
    spaceBetween: 24,
    slidesPerView: 4.2,
    freeMode: {
      enabled: true,
      momentumVelocityRatio: 0.5,
    },
    mousewheel: {
      releaseOnEdges: true,
      sensitivity: 0.5,
      forceToAxis: true,
    },
    className: styles.slider,
    breakpoints: {
      0: {
        slidesPerView: 1.3,
        spaceBetween: 16,
      },
      830: {
        slidesPerView: 2.2,
      },
      1200: {
        slidesPerView: 3.2,
      },
      1500: {
        slidesPerView: 4.2,
      },
    },
  }

  return (
    <div className={styles.resources}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: resources.title }} />
          <p className={styles.desc}>{resources.description}</p>
          <Link className={styles.link} to={resources.linkUrl}>
            {resources.linkText}
          </Link>
        </div>

        <div className={styles.items}>
          <Swiper {...sliderSettings}>
            {resources.items &&
              resources.items.map(item => (
                <SwiperSlide className={styles.item}>
                  <div className={styles.content}>
                    <img
                      className={styles.image}
                      {...srcSetProps(sanityImageUrl(item.image))}
                      alt={item.image?.caption}
                    />
                    <div className={styles.text}>
                      <div className={styles.textTop}>
                        <p className={styles.name}>{item.title}</p>
                        <p className={styles.info}>{item.body}</p>
                      </div>
                      <Link className={styles.redirect} to={item.url} aria-label={item.title}>
                        {item.linkText}
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}
