import React from 'react'

import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import cx from 'classnames'

import styles from './style.module.sass'

export default ({ testosteroneLevels, blockId = 't_levels' }) => {
  return (
    <div className={styles.box}>
      <div className={styles.blueBackground}></div>
      <Container classes={styles.outer}>
        <Wrapper classes={styles.wrapper}>
          <Item classes={styles.contentContainer} id={blockId}>
            <Title>Understanding Testosterone Levels</Title>
          </Item>
          <Item classes={styles.contentContainer}>
            <div className={styles.blueText}>{testosteroneLevels.blueHeader}</div>
            {testosteroneLevels.paragraphs &&
              testosteroneLevels.paragraphs.map(paragraph => (
                <div key={paragraph._key} className={styles.grayText}>
                  {prepareParagraph(paragraph.text)}
                </div>
              ))}
          </Item>
        </Wrapper>
        <Wrapper classes={styles.wrapper}>
          <Item classes={styles.contentContainer}>
            <div className={styles.border} />
            <div className={cx(styles.heading, styles.withLine)}>{testosteroneLevels.ranges.title}</div>
            {testosteroneLevels.ranges.paragraphs &&
              testosteroneLevels.ranges.paragraphs.map(paragraph => (
                <div key={paragraph._key} className={styles.grayText}>
                  {prepareParagraph(paragraph.text)}
                </div>
              ))}

            <div className={styles.heading}>{testosteroneLevels.normalVSAbnormal.title}</div>
            <img
              className={styles.image}
              {...srcSetProps(sanityImageUrl(testosteroneLevels.normalVSAbnormal?.image))}
              alt={testosteroneLevels.normalVSAbnormal?.image?.caption}
            />
            {testosteroneLevels.normalVSAbnormal.paragraphs &&
              testosteroneLevels.normalVSAbnormal.paragraphs.map(paragraph => (
                <div key={paragraph._key} className={styles.grayText}>
                  {prepareParagraph(paragraph.text)}
                </div>
              ))}

            <div className={styles.heading}>{testosteroneLevels.targetRanges.title}</div>
            <img
              className={styles.image}
              {...srcSetProps(sanityImageUrl(testosteroneLevels.targetRanges?.image))}
              alt={testosteroneLevels.targetRanges?.image?.caption}
            />
            {testosteroneLevels.targetRanges.paragraphs &&
              testosteroneLevels.targetRanges.paragraphs.map(paragraph => (
                <div key={paragraph._key} className={styles.grayText}>
                  {prepareParagraph(paragraph.text)}
                </div>
              ))}
          </Item>
        </Wrapper>
      </Container>
    </div>
  )
}
