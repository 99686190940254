import React from 'react'
import { sanityImageUrl, urlWithSearchParamsHandler } from '../../../utils/helpers'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ protocol }) => (
  <div className={styles.item}>
    <div className={styles.itemTop}>
      <Link to={protocol.learnMoreButtonUrl} className={styles.imageBox}>
        {protocol.tag && <span className={styles.tag}>{protocol.tag}</span>}
        <img src={sanityImageUrl(protocol.image)} className={styles.image} alt={protocol.image?.caption} />
      </Link>

      <div className={styles.itemWrap}>
        <p className={styles.name}>{protocol.title}</p>
        <div className={styles.benefits}>
          {protocol.benefits && protocol.benefits.map((item, index) => (
            <p className={styles.benefitsItem} key={index}>{item}</p>
          ))}
        </div>        
      </div>
    </div>

    <div className={styles.itemBottom}>
      <p className={styles.price}>Starting at <span>{protocol.price}</span>/mo</p>
      <div className={styles.buttons}>
        <Link className={styles.buttonsMore} to={protocol.learnMoreButtonUrl}>
          {protocol.learnMoreButtonText}
        </Link>
        <a
          href={protocol.getStartedButtonUrl}
          onClick={urlWithSearchParamsHandler}
          className={styles.buttonsStarted}
        >Start Treatment</a>
      </div>
      <p className={styles.available}>{protocol.available}</p>
    </div>
  </div>
)