import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { prepareParagraph, sanityImageUrl } from '../../../utils/helpers'
import { TimelineMax, gsap } from 'gsap'
import ScrollMagic from 'ScrollMagic'
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ parallax }) => {
  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TimelineMax)
    gsap.defaults({ ease: 'none', duration: 1 })

    const timeLine1 = new TimelineMax()
    const controller = new ScrollMagic.Controller()
    const vHeight = window.innerHeight
    const vWidth = window.innerWidth
    let sliderWidth = 361
    let sliderValue = 80

    if (vWidth < 901) {
      sliderWidth = 268
    }

    if (vWidth < 701) {
      sliderWidth = 108
      sliderValue = 55
    }

    timeLine1
      .set(`.${styles.scalePoint}`, { x: sliderWidth / 14 })
      .set(`.${styles.sliderNum1}`, { y: -2 * sliderValue })
      .set(`.${styles.sliderNum2}`, { y: -8 * sliderValue })
      .to(`.${styles.title1}`, { y: -vHeight })
      .from(`.${styles.injector}`, 0.7, { y: -vHeight }, '-=1')
      .from(`.${styles.title2}`, { y: vHeight }, '-=1')
      .to(`.${styles.title2}`, { y: -vHeight })
      .to(`.${styles.bottle}`, 0, { opacity: 0 }, '-=1')
      .to(`.${styles.bottle2}`, 0, { opacity: 1 }, '-=1')
      .from(`.${styles.title3}`, { y: vHeight }, '-=1')
      .to(`.${styles.injector}`, { y: -vHeight }, '-=1')
      .to(`.${styles.title3}`, { y: -vHeight })
      .from(`.${styles.white}`, { y: vHeight + 50 }, '-=1')
      .from(`.${styles.step1}`, { y: vHeight }, '-=1')
      .from(`.${styles.slider}`, { y: vHeight }, '-=1')
      .to(`.${styles.step1}`, { y: -vHeight })
      .to(`.${styles.sliderNum1}`, { y: -2 * sliderValue }, '-=1')
      .to(`.${styles.sliderNum2}`, { y: -5 * sliderValue }, '-=1')
      .from(`.${styles.step2}`, { y: vHeight }, '-=1')
      .to(`.${styles.scalePoint}`, { x: sliderWidth / 1.1 }, '-=1')
      .to(`.${styles.step2}`, { y: -vHeight })
      .from(`.${styles.step3}`, { y: vHeight }, '-=1')
      .to(`.${styles.sliderNum1}`, { y: -2 * sliderValue }, '-=1')
      .to(`.${styles.sliderNum2}`, { y: -2 * sliderValue }, '-=1')
      .to(`.${styles.scalePoint}`, { x: sliderWidth / 2.48 }, '-=1')

    new ScrollMagic.Scene({
      duration: vHeight * 4,
      triggerElement: `.${styles.parallax}`,
      triggerHook: 0,
    })
      .setPin(`.${styles.parallax}`)
      .setTween(timeLine1)
      .addTo(controller)
  }, [])

  return (
    <div className={cx(styles.container, styles.weightLoss)}>
      <div className={styles.parallax}>
        <div className={styles.wrap}>
          <p className={styles.title1}>
            <span>{parallax.title1}</span>
          </p>
          <p className={styles.title2}>
            <span>{parallax.title2}</span>
          </p>
          <p className={styles.title3}>
            <span>{parallax.title3}</span>
          </p>
          <div className={styles.injector} />
          <div className={styles.bottle} />
          <div className={styles.bottle2} />
          <div className={styles.white} />
          <div className={styles.step1}>
            <div className={styles.stepWrap}>
              <div className={styles.stepIndex}>1</div>
              <div className={styles.stepTitle}>{parallax.step1Title}</div>
              <div className={styles.stepText}>{parallax.step1text}</div>
            </div>
          </div>
          <div className={styles.step2}>
            <div className={styles.stepWrap}>
              <div className={styles.stepIndex}>2</div>
              <div className={styles.stepTitle}>{parallax.step2Title}</div>
              <div className={styles.stepText}>{parallax.step2text}</div>
            </div>
          </div>
          <div className={styles.step3}>
            <div className={styles.stepWrap}>
              <div className={styles.stepIndex}>3</div>
              <div className={styles.stepTitle}>{parallax.step3Title}</div>
              <div className={styles.stepText}>{parallax.step3text}</div>
            </div>
          </div>
          <div className={styles.slider}>
            <div className={styles.sliderTitle}>dosage (mg):</div>
            <div className={styles.scale}>
              <div className={styles.scaleProgress}>
                <div className={styles.scalePoint} />
              </div>
              <div className={styles.scaleLine}>
                <span>2.5</span>
                <span>5.0</span>
                <span>7.5</span>
                <span>10</span>
                <span>12.5</span>
                <span>15</span>
              </div>
            </div>
            <div className={styles.sliderValue}>
              <div className={cx(styles.sliderNum, styles.sliderNum1)}>
                <span>0</span>
                <span>1</span>
                <span>2</span>
                <span>3</span>
                <span>4</span>
                <span>5</span>
                <span>6</span>
                <span>7</span>
                <span>8</span>
                <span>9</span>
              </div>
              <div className={cx(styles.sliderNum, styles.sliderNum2)}>
                <span>0</span>
                <span>1</span>
                <span>2</span>
                <span>3</span>
                <span>4</span>
                <span>5</span>
                <span>6</span>
                <span>7</span>
                <span>8</span>
                <span>9</span>
              </div>
              <div className={cx(styles.sliderNum, styles.sliderNum3)}>
                <span>0</span>
                <span>1</span>
                <span>2</span>
                <span>3</span>
                <span>4</span>
                <span>5</span>
                <span>6</span>
                <span>7</span>
                <span>8</span>
                <span>9</span>
              </div>
            </div>
            <div className={styles.sliderInfo}>weight (lbs)</div>
          </div>
        </div>
      </div>
    </div>
  )
}
