import React from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ header }) => {
  if (!header) return null

  return (
    <div className={styles.header}>
      <img className={styles.image} {...srcSetProps(sanityImageUrl(header.image))} alt={header.image?.caption} />
      <div className={styles.wrap}>
        <div className={styles.text}>
          <h1 className={styles.title}>{header.title}</h1>
          <p className={styles.description}>{header.description}</p>
          <a href={header.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>
            {header.buttonText}
          </a>
        </div>
        <div className={styles.sign}>
          <img
            className={styles.signImage}
            {...srcSetProps(sanityImageUrl(header.signImage))}
            alt={header.signImage?.caption}
          />
          <p className={styles.signName}>{header.name}</p>
          <p className={styles.signInfo}>{header.info}</p>
        </div>
      </div>
    </div>
  )
}
