import React from 'react'
import { Helmet } from 'react-helmet'
import { sanityImageUrl } from '../../../utils/helpers'

export default ({ name, description, image, price, aggregateRatingValue, aggregateRatingCount, reviews = [] }) => (
  <Helmet>
    <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": "${name}",
          "image": ["${sanityImageUrl(image)}"],
          "description": "${description}",
          "offers": {
            "@type": "Offer",
            "priceCurrency": "USD",
            "price": "${price}",
            "availability": "https://schema.org/InStock"
          },
          "review": [${reviews.map(({ author, body, title, rating }) => {
            return JSON.stringify({
              '@type': 'Review',
              author: {
                '@type': 'Person',
                name: `${author}`,
              },
              reviewBody: `${body}`,
              name: `${title}`,
              reviewRating: {
                '@type': 'Rating',
                ratingValue: `${rating}`,
                bestRating: '5',
                worstRating: '1',
              },
            })
          })}]
          ${
            aggregateRatingValue && aggregateRatingCount
              ? `, "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "${parseInt(aggregateRatingValue)}",
            "reviewCount": "${parseInt(aggregateRatingCount)}"
          }`
              : ''
          }
        }
      `}
    </script>
  </Helmet>
)
