import React, { useState } from 'react'
import { formAccessHandler, sanityImageUrl } from '../../../utils/helpers'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ expanding }) => {
  if (!expanding || expanding.isHide) return null

  const [submit, setSubmit] = useState(false)
  const [loading, setLoading] = useState(false)

  return (
    <div className={styles.exp}>
      <div className={styles.wrap}>
        <div className={styles.map}>
          <img src={sanityImageUrl(expanding.image)} className={styles.image} alt={expanding.image?.caption} />
          <p className={styles.available}>{expanding.availableText}</p>
        </div>
        <div className={styles.info}>
          <h2 className={styles.title}>{expanding.title}</h2>
          <p className={styles.description}>{expanding.description}</p>
          {submit ? (
            <p className={styles.submitted}>Thanks! We'll be in touch</p>
          ) : (
            <form 
              className={cx(styles.form, {
                [styles.formLoading]: loading,
              })} 
              onSubmit={(e) => formAccessHandler(e, loading, setLoading, setSubmit)}
            >
              <input type="emails" name="email" className={styles.formEmail} placeholder="Enter your email"/>
              <input type="submit" value="Submit" className={styles.formSubmit}/>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}
