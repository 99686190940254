import React from 'react'
import { titleToId, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ team }) => {
  if (!team || team.isHide) return null

  const openLiveChat = () => {
    try {
      window.Intercom('showSpace', 'messages')
    } catch {}
  }

  return (
    <div className={styles.team} id={titleToId(team.title)}>
      <div className={styles.wrap}>
        <div className={styles.left}>
          <span className={styles.anchor} id={titleToId(team.title)}/>
          <h2 className={styles.title}>{team.title}</h2>
          <p className={styles.description}>{team.description}</p>
          <div className={styles.establishments}>
            {team.establishments && team.establishments.map(item => (
              <img
                className={styles.logo}
                key={item._key}
                {...srcSetProps(sanityImageUrl(item.logo))}
                alt={item.logo?.caption}
              />
            ))}
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.column}>
            {team.doctors && team.doctors.slice(0, 2).map(doctor => (
              <div className={styles.doctor} key={doctor._key}>
                <p className={styles.name}>{doctor.name}</p>
                <p className={styles.position}>{doctor.position}</p>
                <img
                  className={styles.image}
                  {...srcSetProps(sanityImageUrl(doctor.image))}
                  alt={doctor.name}
                />
              </div>
            ))}
            <div className={styles.chat} onClick={openLiveChat}>
              <div className={styles.chatImage}>
                <img src={sanityImageUrl(team.chatImage)} alt={team.chatImage?.caption} />
              </div>
              <div className={styles.chatText}>
                <p className={styles.chatTitle}>{team.chatTitle}</p>
                <p className={styles.chatLink}>{team.chatLink}</p>
              </div>
            </div>            
          </div>
          <div className={styles.column}>
            {team.doctors && team.doctors.slice(2, team.doctors.length).map(doctor => (
              <div className={styles.doctor} key={doctor._key}>
                <p className={styles.name}>{doctor.name}</p>
                <p className={styles.position}>{doctor.position}</p>
                <img
                  className={styles.image}
                  {...srcSetProps(sanityImageUrl(doctor.image))}
                  alt={doctor.name}
                />
              </div>
            ))}            
          </div>
        </div>
      </div>
    </div>
  )
}
