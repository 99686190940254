import React from 'react'
import { srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ partners }) => (
  <div className={styles.partners}>
    <div className={styles.wrap}>
      <div className={styles.list}>
        {partners.items &&
          partners.items.map(item => (
            <div className={styles.item} key={item._key}>
              {item.logoUrl ? (
                <a href={item.logoUrl} target="_blank" rel="noreferrer">
                  <img {...srcSetProps(sanityImageUrl(item.image))} alt={item.image?.caption} />
                </a>
              ) : (
                <img {...srcSetProps(sanityImageUrl(item.image))} alt={item.image?.caption} />
              )}
            </div>
          ))}
      </div>
    </div>
  </div>
)
