import React, { useState } from 'react'
import cx from 'classnames'

import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'

import styles from './style.module.sass'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import Content from '../../Shared/UI/Content'

export default ({ blameForDecline }) => {
  const [currentValue, setCurrentValue] = useState(blameForDecline.items[0])
  const [mobileCurrentItem, setMobileCurrentItem] = useState(null)

  const selectSection = item => {
    setCurrentValue(item)

    if (item == mobileCurrentItem) {
      setMobileCurrentItem(null)
    } else {
      setMobileCurrentItem(item)
    }
  }

  return (
    <Container classes={styles.outer}>
      <Wrapper classes={styles.wrapper}>
        <Item classes={styles.contentContainer}>
          <Title classes={styles.title}>{blameForDecline.title}</Title>
          <Content classes={styles.content} tag="div">
            {blameForDecline.paragraphs &&
              blameForDecline.paragraphs.map((paragraph, index) => {
                return (
                  <React.Fragment key={paragraph._key}>
                    <p>{prepareParagraph(paragraph.text)}</p>
                  </React.Fragment>
                )
              })}

            <div className={styles.imageWrap}>
              {blameForDecline.items &&
                blameForDecline.items.map(item => {
                  return (
                    <img
                      key={item._key}
                      className={cx(styles.image, {
                        [styles.active]: currentValue === item,
                      })}
                      {...srcSetProps(sanityImageUrl(item.image))}
                      alt={item.image?.caption}
                    />
                  )
                })}
              <div className={styles.halfBlue}></div>
            </div>
          </Content>
        </Item>
      </Wrapper>
      <Wrapper classes={styles.wrapper}>
        <Item classes={cx(styles.contentContainer)}>
          <div className={styles.desktop}>
            <div className={styles.desktopNavigation}>
              {blameForDecline.items &&
                blameForDecline.items.map(item => {
                  return (
                    <div
                      key={item._key}
                      className={cx(styles.item, {
                        [styles.active]: currentValue === item,
                      })}
                      onClick={() => setCurrentValue(item)}
                    >
                      {item.title}
                    </div>
                  )
                })}
              <div className={styles.halfBlue}></div>
            </div>
            <div className={styles.desktopContent}>
              <div className={styles.heading}>{currentValue.title}</div>
              <div className={styles.description}>
                {currentValue.paragraphs &&
                  currentValue.paragraphs.map((paragraph, index) => {
                    return (
                      <React.Fragment key={paragraph._key}>
                        <p>{prepareParagraph(paragraph.text)}</p>
                      </React.Fragment>
                    )
                  })}
              </div>
            </div>
          </div>
          <div className={cx(styles.mobile, 'show-mobile')}>
            {blameForDecline.items &&
              blameForDecline.items.map(item => (
                <React.Fragment key={item._key}>
                  <div
                    className={cx(styles.details, {
                      [styles.opened]: item == mobileCurrentItem,
                    })}
                  >
                    <div className={cx(styles.heading, styles.summary)} onClick={() => selectSection(item)}>
                      {item.title}:&nbsp;
                      <span className={styles.headingBlue}>{item.blueTitle}</span>
                    </div>
                    <Content classes={styles.item}>
                      {item.paragraphs &&
                        item.paragraphs.map((paragraph, index) => {
                          return (
                            <React.Fragment key={paragraph._key}>
                              <p>{prepareParagraph(paragraph.text)}</p>
                            </React.Fragment>
                          )
                        })}
                    </Content>
                  </div>
                  <div className={styles.delimiter} />
                </React.Fragment>
              ))}
          </div>
        </Item>
      </Wrapper>
    </Container>
  )
}
