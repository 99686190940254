import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import { sortComponents } from '../utils/helpers'
import Header from '../components/EncloQuizLpPage/Header'
import Questions from '../components/EncloQuizLpPage/Questions'
import Quote from '../components/EncloQuizLpPage/Quote'
import Stats from '../components/EncloQuizLpPage/Stats'
import Benefits from '../components/EncloQuizLpPage/Benefits'
import Steps from '../components/EncloQuizLpPage/Steps'
import Product from '../components/EncloQuizLpPage/Product'
import Reviews from '../components/EncloQuizLpPage/Reviews'
import Protocols from '../components/EncloQuizLpPage/Protocols'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Questions questions={data.questions} />,
      priority: data.questions?.priority || 99
    },
    {
      render: <Quote quote={data.quote} />,
      priority: data.quote?.priority || 99
    },
    {
      render: <Stats stats={data.stats} />,
      priority: data.stats?.priority || 99
    },
    {
      render: <Benefits benefits={data.benefits} />,
      priority: data.benefits?.priority || 99
    },
    {
      render: <Steps steps={data.steps} />,
      priority: data.steps?.priority || 99
    },
    {
      render: <Product product={data.product} />,
      priority: data.product?.priority || 99
    },
    {
      render: <Reviews reviews={data.reviews} />,
      priority: data.reviews?.priority || 99
    },
    {
      render: <Protocols protocols={data.protocols} />,
      priority: data.protocols?.priority || 99
    }
  ].sort(sortComponents)

  return (
    <Layout data={data} signUpUrl={data.header?.buttonUrl} signUpText={data.header?.buttonText}>
      <SEO {...data.seo} />
      <Header header={data.header} />
      {components.map(component => component.render)}
    </Layout>
  )
}
