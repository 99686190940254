import React from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ header }) => {
  if (!header) return null

  return (
    <div className={styles.header}>
      <div className={styles.box}>
        {header.protocol && (
          <div className={styles.item}>
            <div className={styles.top}>
              <div className={styles.image}>
                <img src={sanityImageUrl(header.protocol.imageSquare)} className={styles.imageSrc} alt={header.protocol.imageSquare?.caption} />
              </div>
              <p className={styles.name}>{header.protocol.name}</p>
              <div className={styles.ingredients}>
                {header.protocol.ingredients && header.protocol.ingredients.map(item => (
                  <p className={styles.ingredientsItem} key={item._key}>
                    {item.name}
                  </p>
                ))}
              </div>                  
              <p className={styles.info}>{header.protocol.descriptionShort}</p>
            </div>
            <div className={styles.bottom}>
              <p className={styles.price}>Starting at <span>{header.protocol.price}</span>/mo</p>
              {header.protocol.getStartedButtonText && header.protocol.getStartedButtonUrl && (
                <a href={header.protocol.getStartedButtonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>{header.protocol.getStartedButtonText}</a>
              )}
            </div>
          </div>
        )}
      </div>
      <div className={styles.wrap}>
        <div className={styles.text}>
          <h1 className={styles.title}>{header.title}</h1>
          {header.rating?.url && (
            <Link to={header.rating.url} className={styles.rating}>
              {header.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(header.rating.starsImage)} alt={header.rating?.text}/>}
              {header.rating.text && <span className={styles.ratingText}>{header.rating.text}</span>}
            </Link>
          )}          
        </div>
      </div>
    </div>
  )
}
