import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import { srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ doctors }) => {
  if (!doctors || doctors.isHide) return null
    
  return (
    <div className={styles.doctors}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>{doctors.title}</h2>
          <div className={styles.establishments}>
            {doctors.establishments && doctors.establishments.map(item => (
              <div className={styles.establishmentsItem}>
                <img
                  className={styles.logo}
                  {...srcSetProps(sanityImageUrl(item.logo))}
                  alt={item.logo?.caption}
                  key={item._key}
                />
              </div>
            ))}
          </div>
        </div>

        <div className={styles.items}>
          {doctors.doctors && doctors.doctors.map(item => (
            <div className={styles.item} key={item._key}>
              <img className={styles.image} src={sanityImageUrl(item.image)} alt={item.image?.caption} />
              <div className={styles.doctor}>
                <div className={styles.text}>
                  <p className={styles.name}>{item.name}</p>
                  <p className={styles.position}>{item.position}</p>                    
                </div>
                <a href={item.podcastUrl} target="_blank" rel="noreferrer" className={styles.link}>Watch Interview</a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
