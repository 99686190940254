import React from 'react'
import { srcSetProps, sanityImageUrl, prepareParagraph, titleToId } from '../../../../utils/helpers'
import styles from './style.module.sass'

export default ({ results }) => {
  return (
    <div className={styles.results}>
      <div className={styles.anchor} id={titleToId(results.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{results.title}</h2>
      <div className={styles.content}>
        {results.items &&
          results.items.map(item => (
            <div className={styles.block}>
              <div className={styles.anchor} id={titleToId(item.title)} data-js="scroll-section" />
              <p className={styles.header}>{item.title}</p>
              <div className={styles.box}>
                <div className={styles.left}>
                  <p className={styles.text}>{prepareParagraph(item.text)}</p>
                </div>
                <div className={styles.right}>
                  <p className={styles.imageTitle} dangerouslySetInnerHTML={{ __html: item.imageTitle }} />
                  {item.imageText && (
                    <p className={styles.imageText} dangerouslySetInnerHTML={{ __html: item.imageText }} />
                  )}
                  <img
                    {...srcSetProps(sanityImageUrl(item.image))}
                    alt={item.image?.caption}
                    className={styles.image}
                  />
                  <p className={styles.imageReference}>{item.imageReference}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
