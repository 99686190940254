import React from 'react'
import { prepareParagraph, sanityImageUrl, titleToId } from '/src/utils/helpers'
import styles from './style.module.sass'

export default ({ appendix }) => {
  return (
    <div className={styles.appendix}>
      <div className={styles.anchor} id={titleToId(appendix.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{appendix.title}</h2>
      <div className={styles.content}>
        {appendix.Content &&
          appendix.Content.map(block => (
            <div className={styles.paragraph}>
              <p className={styles.headline}>{prepareParagraph(block.title)}</p>
              {block.body &&
                block.body.map(item => (
                  <div className={styles.item}>
                    {item._type === 'imageFull' && (
                      <div className={styles.imageFull}>
                        <p className={styles.caption}>{prepareParagraph(item.title)}</p>
                        <img src={sanityImageUrl(item.image)} alt={item.image?.caption} />
                        {item.bottom && <p className={styles.bottom}>{prepareParagraph(item.bottom)}</p>}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  )
}
