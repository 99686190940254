import React from 'react'
import { urlWithSearchParamsHandler, prepareParagraph, srcSetProps, sanityImageUrl, clickTrack } from '../../../utils/helpers'
import { PopupButton } from '@typeform/embed-react'
import styles from './style.module.sass'

export default ({ info }) => {
  if (!info || info.isHide) return null

  return (
    <div className={styles.info}>
      <div className={styles.wrap}>
        <div className={styles.text}>
          <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: info.title }} />
          <ul className={styles.list}>
            {info.benefits &&
              info.benefits.map((item, index) => (
                <li className={styles.listItem} key={index}>
                  {prepareParagraph(item)}
                </li>
              ))}
          </ul>
          <div className={styles.buttonWrap}>
            {info.buttonUrl?.includes('https://quiz/') ? (
              <PopupButton
                id={buttonUrl.replace('https://quiz/', '')}
                as="a"
                onReady={() => clickTrack(info.buttonText)}
                transitiveSearchParams={true}
                className={styles.button}
              >
                {info.buttonText}
              </PopupButton>
            ) : (
              <a href={info.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>
                {info.buttonText}
              </a>
            )}
          </div>
          <p className={styles.off}>{prepareParagraph(info.offLabels)}</p>
        </div>
        <div className={styles.customer}>
          <img src={sanityImageUrl(info.image)} alt={info.image?.caption} className={styles.image} />
          <div className={styles.box}>
            <div className={styles.boxColumn}>
              <p className={styles.boxTitle}>{info.beforeTitle}</p>
              <p className={styles.boxValue}>{info.totalTestosteroneBeforeValue}</p>
              <p className={styles.boxValue}>{info.freeTestosteroneBeforeValue}</p>
            </div>
            <div className={styles.boxColumn}>
              <p className={styles.boxTitle}>{info.afterTitle}</p>
              <p className={styles.boxValue}>{info.totalTestosteroneAfterValue}</p>
              <p className={styles.boxValue}>{info.freeTestosteroneAfterValue}</p>
            </div>
            <div className={styles.boxDescription}>
              <div className={styles.boxRow}>
                <div className={styles.boxLine} />
                <p className={styles.boxText}>{info.totalTestosterone}</p>
                <div className={styles.boxLine} />
              </div>
              <div className={styles.boxRow}>
                <div className={styles.boxLine} />
                <p className={styles.boxText}>{info.freeTestosterone}</p>
                <div className={styles.boxLine} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
