import React from 'react'
import { prepareParagraph, sanityImageUrl } from '../../../utils/helpers'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ stamp }) => {
  if (!stamp || stamp.isHide) return null

  return (
    <div className={styles.stamp}>
      <div className={styles.wrap}>
        <div className={styles.column}>
          <h2 className={styles.title}>{stamp.title}</h2>
          <div className={styles.info}>
            <p className={styles.description}>{stamp.info}</p>
            <p className={styles.price}>{stamp.price}</p>
            <p className={styles.available}>{stamp.available}</p>
            {stamp.stamp && (
              <img className={styles.product} src={sanityImageUrl(stamp.stamp)} alt={stamp.stamp?.caption} />
            )}
          </div>
          <div className={cx(styles.info, styles.infoMobile)}>
            <p className={styles.description} dangerouslySetInnerHTML={{ __html: stamp.infoMobile }} />
            <p className={styles.price}>{stamp.price}</p>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.faq}>
            {stamp.faq &&
              stamp.faq.map(faq => (
                <div key={faq._key}>
                  <p className={styles.faqQuestion}>{faq.question}</p>
                  <p className={styles.faqAnswer}>{prepareParagraph(faq.answer)}</p>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.column}>
          <img className={styles.image} src={sanityImageUrl(stamp.image)} alt={stamp.image?.caption} />
        </div>
      </div>
    </div>
  )
}
