import React from 'react'
import { Link } from 'gatsby'
import { sanityImageUrl, sanityFileUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ item, setSelectedReview, hidePreview, videoPreview }) => (
  <div className={styles.item}>
    <div className={styles.info} onClick={() => setSelectedReview(item._key)}>
      <img
        src={sanityImageUrl(item.image)}
        alt={item.image?.caption}
        className={styles.image}
        data-id={item._key}
      />
      {videoPreview && (
        <video
          className={styles.video}
          preload="auto"
          muted
          loop
          playsInline
          autoPlay
          onLoadedData={e => hidePreview(e, item._key)}
        >
          <source src={sanityFileUrl(item.videoPreview)} type="video/mp4" />
        </video>
      )}
      <div className={styles.name}>{item.name}</div>
    </div>
    <div className={styles.protocol}>
      <img
        src={sanityImageUrl(item.protocolImage)}
        alt={item.protocolImage?.caption}
        className={styles.protocolImage}
      />
      <Link to={item.protocolUrl} className={styles.protocolInfo}>
        <p className={styles.protocolName} dangerouslySetInnerHTML={{ __html: item.protocolName }} />
        <p className={styles.protocolMotto} dangerouslySetInnerHTML={{ __html: item.protocolMotto }} />
      </Link>
    </div>
  </div>
)