import React from 'react'
import cx from 'classnames'
import { srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ explain }) => {
  if (!explain || explain.isHide) return null

  return (
    <div className={styles.explain}>
      <img src={sanityImageUrl(explain.image)} alt={explain.image?.caption} className={styles.image}/>
      <div className={styles.wrap}>
        <a href={explain.buttonUrl} target="_blanK" className={styles.button}/>
        <p className={styles.title}>{explain.title}</p>
        <a href={explain.buttonUrl} target="_blanK" className={styles.link}>{explain.buttonText}</a>
      </div>
    </div>
  )
}