import React from 'react'
import Layout from '../../utils/layout'
import SEO from '../../utils/seo'
import Header from '../../components/WhitePaper/OralTrt/Header'
import Navigation from '../../components/WhitePaper/Nav/Navigation'
import NavigationWrap from '../../components/WhitePaper/Nav/NavigationWrap'
import NavigationContent from '../../components/WhitePaper/Nav/NavigationContent'
import Info from '../../components/WhitePaper/OralTrt/Info'
import Abstract from '../../components/WhitePaper/OralTrt/Abstract'
import Background from '../../components/WhitePaper/OralTrt/Background'
import Treatment from '../../components/WhitePaper/OralTrt/Treatment'
import Analysis from '../../components/WhitePaper/OralTrt/Analysis'
import Results from '../../components/WhitePaper/OralTrt/Results'
import Discussion from '../../components/WhitePaper/OralTrt/Discussion'
import References from '../../components/WhitePaper/OralTrt/References'
import Material from '../../components/WhitePaper/OralTrt/Material'
import Distribution from '../../components/WhitePaper/OralTrt/Distribution'
import { titleToId, buildLink } from '../../utils/helpers'

export default ({ pageContext: { data } }) => {
  const links = [
    buildLink(data.abstract.title),
    buildLink(data.background.title),
    buildLink(data.treatment.title),
    buildLink(data.analysis.title),
    buildLink(data.results.title),
    buildLink(data.discussion.title),
    buildLink(data.references.title),
    buildLink(data.material.title, [
      ...data.material.content
        .filter(i => i._type === 'section')
        .map(s => s.items.filter(i => i._type === 'title')[0].title),
      buildLink(data.distribution.title, [
        ...data.distribution.contentLhFsh.filter(i => i._type === 'title').map(i => i.title),
        ...data.distribution.contentBaselines.map(i => i.title),
      ]),
    ]),
  ]

  return (
    <Layout data={data}>
      <SEO {...data.seo} />
      <Header links={links} header={data.header} />
      <NavigationWrap>
        <Navigation links={links} bold={true} />
        <NavigationContent>
          <Info info={data.info} />
          <Abstract abstract={data.abstract} />
          <Background background={data.background} />
          <Treatment treatment={data.treatment} />
          <Analysis analysis={data.analysis} />
          <Results results={data.results} />
          <Discussion discussion={data.discussion} />
          <References references={data.references} />
          <Material material={data.material} />
          <Distribution distribution={data.distribution} />
        </NavigationContent>
      </NavigationWrap>
    </Layout>
  )
}
