import React, { useState } from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl, prepareParagraph } from '../../../utils/helpers'
import SwiperObj, { FreeMode, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Link } from 'gatsby'
import cx from 'classnames'
import styles from './style.module.sass'

const MainImage = ({ top, activeImage, changeImage, sliderSettings }) => {
  if (top.images.length === 1) {
    return (
      <img
        className={styles.imageSrc}
        {...srcSetProps(sanityImageUrl(activeImage.image))}
        alt={activeImage.image?.caption}
      />
    )
  }

  return (
    <div>
      <Swiper {...sliderSettings}>
        {top.images &&
          top.images.map(image => (
            <SwiperSlide className={styles.sliderItem}>
              <img
                className={styles.sliderItemImg}
                {...srcSetProps(sanityImageUrl(image.image))}
                alt={image.image?.caption}
              />
            </SwiperSlide>
          ))}
      </Swiper>

      <div className={styles.imageWrap}>
        <div className={styles.images}>
          {top.images &&
            top.images.map(image => (
              <div
                className={cx(styles.imagesItem, {
                  [styles.imagesItemActive]: activeImage === image,
                })}
                key={image._key}
                onClick={() => changeImage(image)}
              >
                <img
                  className={styles.imagesItemSrc}
                  {...srcSetProps(sanityImageUrl(image.image))}
                  alt={image.image?.caption}
                />
              </div>
            ))}
        </div>
        <div className={styles.imageBox}>
          <img
            className={styles.imageSrc}
            {...srcSetProps(sanityImageUrl(activeImage.image))}
            alt={activeImage.image?.caption}
          />
        </div>
      </div>
    </div>
  )
}

export default ({ top }) => {
  if (!top || top.isHide) return null

  SwiperObj.use([FreeMode, Mousewheel])

  const sliderSettings = {
    spaceBetween: 16,
    slidesPerView: 1.3,
    freeMode: {
      enabled: true,
      momentumVelocityRatio: 0.5,
    },
    mousewheel: {
      releaseOnEdges: true,
      sensitivity: 0.5,
      forceToAxis: true,
    },
    className: styles.slider,
  }

  const [activeImage, setActiveImage] = useState(top.images?.[0])
  const changeImage = image => {
    setActiveImage(image)
  }

  return (
    <div
      className={cx(styles.top, {
        [styles.topMultiple]: top.images?.length > 1,
      })}
    >
      <div className={styles.wrap}>
        <div className={styles.image}>
          {activeImage && (
            <MainImage top={top} activeImage={activeImage} changeImage={changeImage} sliderSettings={sliderSettings} />
          )}
        </div>

        <div className={styles.text}>
          <p className={styles.motto}>{top.motto}</p>
          <h1 className={styles.title}>{top.title}</h1>

          <p className={styles.info}>{prepareParagraph(top.info)}</p>

          <ul className={styles.list}>
            {top.benefits &&
              top.benefits.map((item, index) => (
                <li className={styles.listItem} key={index}>
                  {prepareParagraph(item)}
                </li>
              ))}
          </ul>

          {!top.ctaDisabled && (
            <a href={top.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>
              {top.buttonText}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
