import React from 'react'

import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'
import cx from 'classnames'

import Crown from '../../Shared/Icons/Crown'
import Sex from '../../Shared/Icons/Testosterone/Sex'
import Bone from '../../Shared/Icons/Testosterone/Bone'
import Heart from '../../Shared/Icons/Testosterone/Heart'
import Lightning from '../../Shared/Icons/Testosterone/Lightning'
import Sperm from '../../Shared/Icons/Testosterone/Sperm'
import Mood from '../../Shared/Icons/Testosterone/Mood'

import styles from './style.module.sass'

import { prepareParagraph } from '../../../utils/helpers'

export default ({ whatIsTestosterone, blockId = 't_101' }) => {
  return (
    <Container classes={styles.outer}>
      <Wrapper classes={cx(styles.wrapper, styles.shaddow)}>
        <Item classes={styles.contentContainer} id={blockId}>
          <Title>What is Testosterone? Why is it important?</Title>
        </Item>
        <Item classes={styles.contentContainer}>
          <Crown classes={styles.blueCrown} />
          <div className={styles.blueText}>{whatIsTestosterone.whatIsTestosterone.header}</div>
          {whatIsTestosterone.whatIsTestosterone.paragraphs &&
            whatIsTestosterone.whatIsTestosterone.paragraphs.map(paragraph => (
              <div key={paragraph._key} className={styles.grayText}>
                {prepareParagraph(paragraph.text)}
              </div>
            ))}
        </Item>
      </Wrapper>
      <Wrapper classes={styles.wrapper}>
        <Item classes={cx(styles.contentContainer, styles.toEnd)}>
          <Title blueLine={false}>Testosterone helps with:</Title>
          <div className={cx(styles.testosteroneResult, styles.borderTop)}>
            <Sex />
            <div>Sex Drive</div>
          </div>
          <div className={styles.testosteroneResult}>
            <Bone />
            <div>Bone Strength</div>
          </div>
          <div className={styles.testosteroneResult}>
            <Heart />
            <div>Heart Health</div>
          </div>
          <div className={styles.testosteroneResult}>
            <Lightning />
            <div>Energy Levels</div>
          </div>
          <div className={styles.testosteroneResult}>
            <Sperm />
            <div>Sperm Cell Production</div>
          </div>
          <div className={styles.testosteroneResult}>
            <Mood />
            <div>Mood & Memory</div>
          </div>
        </Item>
        <Item classes={styles.contentContainer}>
          <div className={styles.blueText}>{whatIsTestosterone.helpsWith.header}</div>
          {whatIsTestosterone.helpsWith.paragraphs &&
            whatIsTestosterone.helpsWith.paragraphs.map(paragraph => (
              <div key={paragraph._key} className={styles.grayText}>
                {prepareParagraph(paragraph.text)}
              </div>
            ))}
        </Item>
      </Wrapper>
    </Container>
  )
}
