import React, { useEffect } from 'react'
import styles from './style.module.sass'
import { srcSetProps } from '../../../utils/helpers'

export default ({ reviews }) => {
  useEffect(() => {
    const existingScripts = document.querySelectorAll('script[src="https://shoutout.io/static/website/js/embed.js"]')
    existingScripts.forEach(script => script.remove())
    const script = document.createElement('script')
    script.src = 'https://shoutout.io/static/website/js/embed.js'
    script.async = true
    document.head.appendChild(script)
  }, [])

  return (
    <div className={styles.reviews} id="reviews">
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>{reviews.title}</h2>
          <a href={reviews.reviewsButtonUrl} className={styles.link} target="_blank">
            {reviews.reviewsButtonText}
          </a>
        </div>
        <div className="shoutout-embed" data-widgetId={reviews.shoutoutWidgetId} />
      </div>
    </div>
  )
}
