import React from 'react'
import { urlWithSearchParamsHandler, prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ get }) => {
  if (!get || get.isHide) return null

  return (
    <div className={styles.get} id="get">
      <img {...srcSetProps(sanityImageUrl(get.image))} alt={get.image?.caption} className={styles.image} />
      <div className={styles.wrap}>
        <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: get.title }} />
        <ul className={styles.list}>
          {get.benefits &&
            get.benefits.map((item, index) => (
              <li className={styles.listItem} key={index}>
                {prepareParagraph(item)}
              </li>
            ))}
        </ul>
        <a href={get.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>
          {get.buttonText}
        </a>
      </div>
    </div>
  )
}
