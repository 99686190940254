import React from 'react'
import cx from 'classnames'

import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'
import Content from '../../Shared/UI/Content'

import styles from './style.module.sass'

import { prepareParagraph } from '../../../utils/helpers'

export default ({ lifeAtMaximus }) => {
  return (
    <Container classes={styles.outer}>
      <Wrapper classes={styles.wrapper}>
        <Item classes={cx(styles.contentContainer)}>
          <Title>{lifeAtMaximus.title}</Title>
          <Content classes={styles.content} tag="div">
            {prepareParagraph(lifeAtMaximus.paragraph)}
          </Content>
        </Item>
      </Wrapper>
    </Container>
  )
}
