import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import { sortComponents } from '../utils/helpers'
import ProductSchema from '../components/Shared/Schema/Product'
import FaqSchema from '../components/Shared/Schema/Faq'
import Header from '../components/TestosteronePortfolioPage/Header'
import Protocols from '../components/TestosteronePortfolioPage/Protocols'
import Stats from '../components/TestosteronePortfolioPage/Stats'
import Table from '../components/TestosteronePortfolioPage/Table'
import Bb from '../components/TestosteronePortfolioPage/Bb'
import HowItWorks from '../components/TestosteronePortfolioPage/HowItWorks'
import Customers from '../components/TestosteronePortfolioPage/Customers'
import Faq from '../components/TestosteronePortfolioPage/Faq'
import References from '../components/TestosteronePortfolioPage/References'
import FoodDrug from '../components/TestosteronePortfolioPage/FoodDrug'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Protocols protocols={data.protocols} />,
      priority: data.protocols?.priority || 99
    },
    {
      render: <Stats stats={data.stats} />,
      priority: data.stats?.priority || 99
    },
    {
      render: <Table table={data.table} />,
      priority: data.table?.priority || 99
    },
    {
      render: <Bb bb={data.buildingBlocks} />,
      priority: data.buildingBlocks?.priority || 99
    },
    {
      render: <HowItWorks howItWorks={data.howItWorks} />,
      priority: data.howItWorks?.priority || 99
    },
    {
      render: <Customers customers={data.customers} />,
      priority: data.customers?.priority || 99
    },
    {
      render: <Faq faq={data.faq} />,
      priority: data.faq?.priority || 99
    },
    {
      render: <References references={data.references} />,
      priority: data.references?.priority || 99
    },
    {
      render: <FoodDrug foodDrug={data.foodDrug} />,
      priority: data.foodDrug?.priority || 99
    }
  ].sort(sortComponents)

  return (
    <Layout data={data} signUpUrl={data.header?.buttonUrl} signUpText={data.header?.buttonText}>
      <SEO {...data.seo} />
      {data.productSchemas && data.productSchemas.map(schema => <ProductSchema {...schema} />)}
      {(data?.faq?.faqList?.items || data?.faq?.items) && (
        <FaqSchema questions={data?.faq?.faqList?.items || data?.faq?.items} />
      )}
      <Header header={data.header} />
      {components.map(component => component.render)}
    </Layout>
  )
}
