import React from 'react'
import { srcSetProps, sanityImageUrl, urlWithSearchParamsHandler, prepareParagraph } from '../../../utils/helpers'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ price }) => {
  if (!price || price.isHide) return null

  return (
    <div className={styles.price}>
      <div className={styles.wrap}>
        <div className={styles.pricing}>
          <h2 className={styles.title}>{price.title}</h2>
          <p className={styles.description}>{price.description}</p>

          {price.optionsTitle && (
            <div className={styles.table}>
              <p className={styles.tableTitle}>{price.optionsTitle}</p>
              <table className={cx(styles.tableInside, {
                [styles.tableInsideCol2]: !price.options_title_column_1
              })}>
                {(price.options_title_column_1 || price.options_title_column_2) && (
                  <thead>
                    <tr>
                      <td></td>
                      {price.options_title_column_1 && (
                        <td>{price.options_title_column_1}</td>
                      )}
                      <td>{price.options_title_column_2}</td>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {price.optionsList &&
                    price.optionsList.map(row => (
                      <tr key={row._key} className={cx({
                        [styles.col2]: !price.options_title_column_1
                      })}>
                        <td>{row.title}</td>
                        {price.options_title_column_1 && (
                          <td>{row.column_1}</td>
                        )}
                        <td>{row.column_2}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}

          {price.devicesTitle && (
            <div className={styles.table}>
              <p className={styles.tableTitle}>{price.devicesTitle}</p>
              <table className={cx(styles.tableInside, {
                [styles.tableInsideCol2]: !price.devices_title_column_1
              })}>
                {(price.devices_title_column_1 || price.devices_title_column_2) && (
                  <thead>
                    <tr>
                      <td></td>
                      {price.devices_title_column_1 && (
                        <td>{price.devices_title_column_1}</td>
                      )}
                      <td>{price.devices_title_column_2}</td>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {price.devicesList &&
                    price.devicesList.map(row => (
                      <tr key={row._key}>
                        <td>{row.title}</td>
                        {price.devices_title_column_1 && (
                          <td>{row.column_1}</td>
                        )}
                        <td>{row.column_2}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
          
          <p className={styles.text}>{prepareParagraph(price.text)}</p>
          <a href={price.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>
            {price.buttonText}
          </a>
        </div>
        <div className={styles.border} />
        <div className={styles.image}>
          <h2 className={styles.title}>{price.rightTitle}</h2>
          <img
            {...srcSetProps(sanityImageUrl(price.rightImage))}
            alt={price.rightImage?.caption}
            className={styles.imageSrc}
          />
        </div>
      </div>
    </div>
  )
}
