import React from 'react'
import { Link } from 'gatsby'
import { sanityImageUrl, urlWithSearchParamsHandler } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ item }) => (
  <div className={styles.item}>
    <div className={styles.itemTop}>
      <Link to={item.learnMoreButtonUrl} className={styles.imageBox}>
        {item.tag && <span className={styles.tag}>{item.tag}</span>}
        <img src={sanityImageUrl(item.image)} className={styles.image} alt={item.image?.caption} />
      </Link>

      <div className={styles.itemWrap}>
        <p className={styles.name}>{item.title}</p>
        <p className={styles.description}>{item.text}</p>

        {item.totalTestosteroneText && item.freeTestosteroneText && (
          <div className={styles.values}>
            <div className={styles.total}>
              <p className={styles.valuesTitle}>{item.totalTestosteroneText}</p>
              <p className={styles.valuesResult}>
                {item.totalArrow && item.totalArrow === 'up' && <span className={styles.arrowUp} />}
                {item.totalArrow && item.totalArrow === 'down' && <span className={styles.arrowDown} />}
                {item.totalTestosteroneValue}
              </p>
            </div>
            <div className={styles.free}>
              <p className={styles.valuesTitle}>{item.freeTestosteroneText}</p>
              <p className={styles.valuesResult}>
                {item.freeArrow && item.freeArrow === 'up' && <span className={styles.arrowUp} />}
                {item.freeArrow && item.freeArrow === 'down' && <span className={styles.arrowDown} />}
                {item.freeTestosteroneValue}
              </p>
            </div>
          </div>
        )}

        <div className={styles.benefits}>
          {item.benefits && item.benefits.map((item, index) => (
            <p className={styles.benefitsItem} key={index}>{item}</p>
          ))}
        </div>
      </div>
    </div>

    <div className={styles.itemBottom}>
      <div className={styles.info}>
        <p className={styles.price} dangerouslySetInnerHTML={{ __html: item.price }} />
        <p className={styles.available}>{item.available}</p>
      </div>
      <div className={styles.buttons}>
        <Link className={styles.buttonsMore} to={item.learnMoreButtonUrl}>
          {item.learnMoreButtonText}
        </Link>
        <a
          href={item.getStartedButtonUrl}
          onClick={urlWithSearchParamsHandler}
          className={styles.buttonsStarted}
        >
          {item.getStartedButtonText}
        </a>
      </div>
      {item.states && <p className={styles.states}>{item.states}</p>}
    </div>
  </div>
)