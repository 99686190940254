import React from 'react'
import { srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ testosterone }) => {
  if (!testosterone || testosterone.isHide) return null

  return (
    <div className={styles.testosterone}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{testosterone.title}</h2>

        <div className={styles.container}>
          <img
            {...srcSetProps(sanityImageUrl(testosterone.image))}
            alt={testosterone.image?.caption}
            className={styles.image}
          />
          <img
            {...srcSetProps(sanityImageUrl(testosterone.imageMobile))}
            alt={testosterone.imageMobile?.caption}
            className={styles.imageMobile}
          />

          <div className={cx(styles.text, styles.text1)}>
            <p className={styles.textTitle}>{testosterone.testosteroneTitle}</p>
            <p className={styles.textProtocol}>{testosterone.testosteroneProtocol}</p>
            <p className={styles.textDescription}>{testosterone.testosteroneDescription}</p>
          </div>
          <div className={cx(styles.text, styles.text2)}>
            <p className={styles.textTitle}>{testosterone.buildingBlocksTitle}</p>
            <p className={styles.textProtocol}>{testosterone.buildingBlocksProtocol}</p>
            <p className={styles.textDescription}>{testosterone.buildingBlocksDescription}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
