import React from 'react'
import { urlWithSearchParamsHandler, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ header }) => {
  if (!header) return null

  return (
    <div className={styles.header}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <p className={styles.titleSmall}>{header.smallTitle}</p>
          <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: header.title }} />
          <a href={header.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>
            {header.buttonText}
          </a>
        </div>
        <div className={styles.columns}>
          <div className={styles.column}>
            <p className={styles.text} dangerouslySetInnerHTML={{ __html: header.benefits1 }} />
          </div>
          <div className={styles.line} />
          <div className={styles.column2}>
            <p className={styles.text}>{header.expertsFrom?.title}</p>
            <div className={styles.list}>
              {header.expertsFrom?.items &&
                header.expertsFrom.items.map(item => (
                  <img
                    key={item._key}
                    src={sanityImageUrl(item.image)}
                    className={styles.image}
                    alt={item.image?.caption}
                  />
                ))}
            </div>
          </div>
          <div className={styles.line} />
          <div className={styles.column2}>
            <p className={styles.text}>{header.seenIn?.title}</p>
            <div className={styles.list}>
              {header.seenIn?.items &&
                header.seenIn.items.map(item => (
                  <img
                    key={item._key}
                    src={sanityImageUrl(item.image)}
                    className={styles.image}
                    alt={item.image?.caption}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
