import React, { useState } from 'react'
import { sanityImageUrl, urlWithSearchParamsHandler } from '../../../utils/helpers'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ reviews }) => {
  if (!reviews || reviews.isHide) return null

  const [show, setShow] = useState(true)

  return (
    <div className={cx(styles.reviews, {
      [styles.reviewsShow]: show
    })}>
      <div className={styles.wrap}>
      <span className={styles.anchor} id="reviews"/>
        <div className={styles.top}>
          <h2 className={styles.title}>{reviews.title}</h2>
          {reviews.buttonText && reviews.buttonUrl && (
            <a href={reviews.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>
              {reviews.buttonText}
            </a>
          )}
        </div>

        <div className={styles.view} onClick={() => setShow(!show)}>
          <p className={styles.viewText}>{show ? reviews.hideButtonText : reviews.viewButtonText}</p>
          <div className={styles.viewButton}/>
        </div>

        <div className={styles.list}>
          {reviews.items && reviews.items.map((item, index) => (
            <div className={styles.item} key={item._key}>
              <img className={styles.itemSrc} src={sanityImageUrl(item.image)} alt={item.image?.caption} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
