import React from 'react'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../../utils/helpers'
import styles from './style.module.sass'

export default ({ blocks }) => {
  return (
    <div className={styles.blocks}>
      <div className={styles.wrap}>
        {blocks.items &&
          blocks.items.map(block => (
            <div key={block._key}>
              <div className={styles.top}>
                <h2 className={styles.title}>{block.title}</h2>
                {block.linkText && block.linkUrl && (
                  <a href={block.linkUrl} target="_blank" className={styles.link}>
                    {block.linkText}
                  </a>
                )}
              </div>
              <div className={styles.content}>
                <div className={styles.left}>
                  <p className={styles.header}>{block.infoTitle}</p>
                  <p className={styles.text}>{prepareParagraph(block.infoText)}</p>
                </div>
                <div className={styles.right}>
                  <img
                    {...srcSetProps(sanityImageUrl(block.image))}
                    alt={block.image?.caption}
                    className={styles.image}
                  />
                  <p className={styles.reference}>{prepareParagraph(block.reference)}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
