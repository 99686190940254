import React from 'react'
import { sanityImageUrl } from '../../../utils/helpers'
import { Link } from 'gatsby'
import Protocol from './protocol'
import SwiperObj, { Pagination, FreeMode, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './style.module.sass'

export default ({ protocols }) => {
  if (!protocols || protocols.isHide) return null

  SwiperObj.use([Pagination, FreeMode, Mousewheel])

  const sliderSettings = {
    spaceBetween: 32,
    slidesPerView: 3.2,
    freeMode: {
      enabled: true,
      momentumVelocityRatio: 0.5,
    },
    mousewheel: {
      releaseOnEdges: true,
      sensitivity: 0.5,
      forceToAxis: true,
    },
    pagination: {
      el: `.${styles.dots}`,
      clickable: true
    },
    className: styles.slider,
    breakpoints: {
      0: {
        slidesPerView: 1.2,
        spaceBetween: 16
      },
      801: {
        slidesPerView: 2.2,
        spaceBetween: 16
      },
      1200: {
        slidesPerView: 3.2,
        spaceBetween: 32
      }
    },
  }

  return (
    <div className={styles.protocols}>
      <div className={styles.anchor} id="testosterone-protocols" />
      <div className={styles.wrap}>
        <h2 className={styles.title}>{protocols.title}</h2>
        {protocols.rating?.url && (
          <Link to={protocols.rating.url} className={styles.rating}>
            {protocols.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(protocols.rating.starsImage)} alt={protocols.rating?.text}/>}
            {protocols.rating.text && <span className={styles.ratingText}>{protocols.rating.text}</span>}
          </Link>
        )}
        <div className={styles.lines}>
          {protocols.lines && protocols.lines.map(line => (
            <div className={styles.linesItem} key={line._key}>
              <Swiper {...sliderSettings}>
                <SwiperSlide className={styles.sliderItem}>
                  <div className={styles.banner}>
                    <img src={sanityImageUrl(line.image)} className={styles.bannerImage} alt={line.image?.caption} />
                    <div className={styles.bannerBottom}>
                      <p className={styles.bannerTitle}>{line.title}</p>
                      <p className={styles.bannerText}>{line.text}</p>
                      {line.buttonText && <Link to={line.buttonUrl} className={styles.bannerLink}>{line.buttonText}</Link>}
                    </div>
                  </div>
                </SwiperSlide>              
                {line.items && line.items.map(protocol => (
                  <SwiperSlide key={protocol._key} className={styles.sliderItem}>
                    <Protocol protocol={protocol}/>
                  </SwiperSlide>
                ))}
                <div className={styles.dots} />
              </Swiper>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
