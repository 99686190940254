import React from 'react'
import Layout from '../../utils/layout'
import SEO from '../../utils/seo'
import Navigation from '../../components/Science/Nav/Navigation'
import NavigationWrap from '../../components/Science/Nav/NavigationWrap'
import NavigationContent from '../../components/Science/Nav/NavigationContent'
import HowItWorks from '../../components/Science/CognitiveEnhancement/HowItWorks'
import Ingredients from '../../components/Science/CognitiveEnhancement/Ingredients'
import Results from '../../components/Science/CognitiveEnhancement/Results'
import DrugFacts from '../../components/Science/CognitiveEnhancement/DrugFacts'
import { titleToId, buildLink } from '../../utils/helpers'

export default ({ pageContext: { data } }) => {
  const links = [
    buildLink(data.howItWorks.title),
    buildLink(data.ingredients.titleNav),
    buildLink(
      data.results.title,
      data.results.items.map(i => i.title),
    ),
    buildLink(data.drugFacts.title),
  ]

  return (
    <Layout
      data={data}
      signUpUrl="https://app.maximustribe.com/registration?product=lover"
      signUpText="Start online visit"
      signInHide={true}
    >
      <SEO {...data.seo} />
      <NavigationWrap>
        <Navigation links={links} />
        <NavigationContent>
          <HowItWorks howItWorks={data.howItWorks} links={links} />
          <Ingredients ingredients={data.ingredients} />
          <Results results={data.results} />
          <DrugFacts drugFacts={data.drugFacts} />
        </NavigationContent>
      </NavigationWrap>
    </Layout>
  )
}
