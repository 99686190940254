import React from 'react'
import { prepareParagraph, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ benefits }) => {
  if (!benefits || benefits.isHide) return null

  return (
    <div className={styles.benefits}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{benefits.title}</h2>
        <div className={styles.items}>
          <div className={styles.one}>
            <img src={sanityImageUrl(benefits.image)} className={styles.image} alt={benefits.image?.caption} />
          </div>
          <div className={styles.many}>
            {benefits.items &&
              benefits.items.map(item => (
                <div className={styles.item} key={item._key}>
                  <img src={sanityImageUrl(item.icon)} className={styles.itemImage} alt={item.icon?.caption} />
                  <p className={styles.itemTitle}>{item.title}</p>
                  <p className={styles.itemText}>{prepareParagraph(item.text)}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}