import React, { useEffect } from 'react'
import { sanityImageUrl, urlWithSearchParamsHandler, prepareParagraph } from '../../../utils/helpers'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ products }) => {
  if (!products || products.isHide) return null

  const chunkArray = (n, xs, y = []) => (xs.length === 0 ? y : chunkArray(n, xs.slice(n), y.concat([xs.slice(0, n)])))
  const getMaxHeight = elements => {
    return Math.max.apply(
      Math,
      elements.map(el => el.clientHeight),
    )
  }

  const sameHeight = () => {
    const rowSize = window.innerWidth > 1100 ? 3 : 2
    const names = chunkArray(rowSize, [...document.getElementsByClassName(styles.name)])
    const benefits = chunkArray(rowSize, [...document.getElementsByClassName(styles.included)])
    const descriptions = chunkArray(rowSize, [...document.getElementsByClassName(styles.description)])

    names.forEach(elements => {
      elements.forEach(element => element.removeAttribute('style'))
      const height = getMaxHeight(elements)
      elements.forEach(element => element.setAttribute('style', `height: ${height}px`))
    })

    benefits.forEach(elements => {
      elements.forEach(element => element.removeAttribute('style'))
      const height = getMaxHeight(elements)
      elements.forEach(element => element.setAttribute('style', `height: ${height}px`))
    })

    descriptions.forEach(elements => {
      elements.forEach(element => element.removeAttribute('style'))
      const height = getMaxHeight(elements)
      elements.forEach(element => element.setAttribute('style', `height: ${height}px`))
    })
  }

  useEffect(() => {
    sameHeight()
    window.addEventListener('resize', sameHeight)
    return () => {
      window.removeEventListener('resize', sameHeight)
    }
  }, [])

  return (
    <div className={styles.products}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{products.title}</h2>
        <div className={styles.items}>
          {products.list &&
            products.list.map(product => (
              <div className={styles.item} key={product._key}>
                <img
                  src={sanityImageUrl(product.imageSquare)}
                  className={styles.image}
                  alt={product.imageSquare?.caption}
                />
                {products.uses && products.uses.find(e => e.name === product.name) && (
                  <span className={styles.usesThis}>{products.usesTag}</span>
                )}
                {product.isPrescription && <p className={styles.tag}>Prescription</p>}
                {product.rating?.url && (
                  <Link className={styles.rating} to={product.rating.url}>
                    {product.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(product.rating.starsImage)} alt={product.rating?.text}/>}
                    {product.rating.text && <p>{product.rating.text}</p>}
                  </Link>
                )}                
                <p className={styles.name} dangerouslySetInnerHTML={{ __html: product.nameNav }} />
                <div className={styles.included}>
                  {product.ingredients &&
                    product.ingredients.map(item => (
                      <p className={styles.includedItem} key={item._key}>
                        {item.name}
                      </p>
                    ))}
                </div>
                <p className={styles.description}>{prepareParagraph(product.descriptionShort)}</p>
                <p className={styles.price}>
                  Starting at <b>{product.price}</b>/mo
                </p>
                <div className={styles.buttons}>
                  <a
                    href={product.getStartedButtonUrl}
                    onClick={urlWithSearchParamsHandler}
                    className={styles.buttonsStarted}
                  >
                    {product.getStartedButtonText}
                  </a>
                  <Link className={styles.buttonsMore} to={product.learnMoreButtonUrl}>
                    {product.learnMoreButtonText}
                  </Link>
                </div>
                <div className={styles.benefits}>
                  {product.benefits &&
                    product.benefits.map(benefit => (
                      <p className={styles.benefitsItem} key={benefit._key}>
                        {benefit.title}
                      </p>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
