import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import { sortComponents } from '../utils/helpers'
import ProductSchema from '../components/Shared/Schema/Product'
import FaqSchema from '../components/Shared/Schema/Faq'
import Header from '../components/BfVsViagraPage/Header'
import Navigation from '../components/BfVsViagraPage/Navigation'
import Comparison from '../components/BfVsViagraPage/Comparison'
import Table from '../components/BfVsViagraPage/Table'
import Sex from '../components/BfVsViagraPage/Sex'
import Benefits from '../components/BfVsViagraPage/Benefits'
import Ingredients from '../components/BfVsViagraPage/Ingredients'
import Explain from '../components/BfVsViagraPage/Explain'
import Faq from '../components/BfVsViagraPage/Faq'
import FoodAndDrugAdministration from '../components/BfVsViagraPage/FoodAndDrugAdministration'
import Get from '../components/BfVsViagraPage/Get'
import Protocols from '../components/BfVsViagraPage/Protocols'
import References from '../components/BfVsViagraPage/References'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Navigation navigation={data.navigation} />,
      priority: data.navigation?.priority || 99,
    },
    {
      render: <Comparison comparison={data.comparison} />,
      priority: data.comparison?.priority || 99,
    },
    {
      render: <Table table={data.table} />,
      priority: data.table?.priority || 99,
    },
    {
      render: <Sex sex={data.sex} />,
      priority: data.sex?.priority || 99,
    },
    {
      render: <Benefits benefits={data.benefits} />,
      priority: data.benefits?.priority || 99,
    },
    {
      render: <Ingredients ingredients={data.ingredients} />,
      priority: data.ingredients?.priority || 99,
    },
    {
      render: <Explain explain={data.explain} />,
      priority: data.explain?.priority || 99,
    },
    {
      render: <Faq faq={data.faq} />,
      priority: data.faq?.priority || 99,
    },
    {
      render: <FoodAndDrugAdministration foodAndDrugAdministration={data.foodAndDrugAdministration} />,
      priority: data.foodAndDrugAdministration?.priority || 99,
    },
    {
      render: <Get get={data.get} />,
      priority: data.get?.priority || 99,
    },
    {
      render: <Protocols protocols={data.protocols} />,
      priority: data.protocols?.priority || 99,
    },
    {
      render: <References references={data.references} />,
      priority: data.references?.priority || 99,
    },
  ].sort(sortComponents)

  return (
    <Layout data={data} signUpUrl={data.header?.buttonUrl} signUpText={data.header?.buttonText}>
      <SEO {...data.seo} />
      <ProductSchema {...data.productSchema} />
      {data.faq?.faqList?.items && <FaqSchema questions={data.faq.faqList.items} />}
      <Header header={data.header} />
      {components.map(component => component.render)}
    </Layout>
  )
}
