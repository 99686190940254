import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { prepareParagraph, sanityImageUrl } from '../../../utils/helpers'
import { TimelineMax, gsap } from 'gsap'
import ScrollMagic from 'ScrollMagic'
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ parallax }) => {
  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TimelineMax)
    gsap.defaults({ ease: 'none', duration: 1 })

    const timeLine1 = new TimelineMax()
    const controller = new ScrollMagic.Controller()
    const vHeight = window.innerHeight
    const vWidth = window.innerWidth
    let sliderWidth = 361
    let sliderValue = 80

    if (vWidth < 901) {
      sliderWidth = 268
    }

    if (vWidth < 701) {
      sliderWidth = 108
      sliderValue = 55
    }

    timeLine1
      .set(`.${styles.scalePoint}`, { x: sliderWidth / 6.3 })
      .to(`.${styles.title1}`, { y: -vHeight })
      .from(`.${styles.title2}`, { y: vHeight }, '-=1')
      .from(`.${styles.cap}`, { y: -vHeight / 2 }, '-=1')
      .from(`.${styles.bottle}`, { y: vHeight }, '-=1')
      .from(`.${styles.bottleBack}`, { y: vHeight }, '-=1')
      .to(`.${styles.title2}`, { y: -vHeight })
      .from(`.${styles.title3}`, { y: vHeight }, '-=1')
      .to(`.${styles.title3}`, { y: -vHeight })
      .from(`.${styles.white}`, { y: vHeight + 50 }, '-=1')
      .from(`.${styles.step1}`, { y: vHeight }, '-=1')
      .from(`.${styles.slider}`, { y: vHeight }, '-=1')
      .to(`.${styles.step1}`, { y: -vHeight })
      .from(`.${styles.step2}`, { y: vHeight }, '-=1')
      .to(`.${styles.scalePoint}`, { x: sliderWidth / 2 }, '-=1')
      .to(`.${styles.step2}`, { y: -vHeight })
      .from(`.${styles.step3}`, { y: vHeight }, '-=1')
      .to(`.${styles.scalePoint}`, { x: sliderWidth / 1.22 }, '-=1')

    new ScrollMagic.Scene({
      duration: vHeight * 4,
      triggerElement: `.${styles.parallax}`,
      triggerHook: 0,
    })
      .setPin(`.${styles.parallax}`)
      .setTween(timeLine1)
      .addTo(controller)
  }, [])

  return (
    <div className={cx(styles.container, styles.bloodFlow)}>
      <div className={styles.parallax}>
        <div className={styles.wrap}>
          <p className={styles.title1}>
            <span>{parallax.title1}</span>
          </p>
          <p className={styles.title2}>
            <span>{parallax.title2}</span>
          </p>
          <p className={styles.title3}>
            <span>{parallax.title3}</span>
          </p>
          <div className={styles.pill} />
          <div className={styles.cap} />
          <div className={styles.bottle} />
          <div className={styles.bottleBack} />
          <div className={styles.white} />
          <div className={styles.step1}>
            <div className={styles.stepWrap}>
              <div className={styles.stepIndex}>1</div>
              <div className={styles.stepTitle}>{parallax.step1Title}</div>
              <div className={styles.stepText}>{parallax.step1text}</div>
            </div>
          </div>
          <div className={styles.step2}>
            <div className={styles.stepWrap}>
              <div className={styles.stepIndex}>2</div>
              <div className={styles.stepTitle}>{parallax.step2Title}</div>
              <div className={styles.stepText}>{parallax.step2text}</div>
            </div>
          </div>
          <div className={styles.step3}>
            <div className={styles.stepWrap}>
              <div className={styles.stepIndex}>3</div>
              <div className={styles.stepTitle}>{parallax.step3Title}</div>
              <div className={styles.stepText}>{parallax.step3text}</div>
            </div>
          </div>
          <div className={styles.slider}>
            <div className={styles.sliderTitle}>Vardenafil</div>
            <div className={styles.scale}>
              <div className={styles.scaleProgress}>
                <div className={styles.scalePoint} />
              </div>
              <div className={styles.scaleLine}>
                <span>2.5mg</span>
                <span>5mg</span>
                <span>10mg</span>
              </div>
            </div>
            <div className={styles.sliderTitle}>Tadalafil</div>
            <div className={styles.scale}>
              <div className={styles.scaleProgress}>
                <div className={styles.scalePoint} />
              </div>
              <div className={styles.scaleLine}>
                <span>2.5mg</span>
                <span>5mg</span>
                <span>10mg</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
