import React from 'react'
import { Link } from 'gatsby'
import { srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ bb }) => {
  if (!bb || bb.isHide) return null

  return (
    <div className={styles.bb}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>{bb.title}</h2>
          <p className={styles.description}>{bb.description}</p>
        </div>

        <div className={styles.container}>
          <img
            {...srcSetProps(sanityImageUrl(bb.image))}
            alt={bb.image?.caption}
            className={styles.image}
          />
          <img
            {...srcSetProps(sanityImageUrl(bb.imageMobile))}
            alt={bb.imageMobile?.caption}
            className={styles.imageMobile}
          />

          <div className={styles.text}>
            <p className={styles.textTop}>{bb.buildingBlocksTop}</p>
            <p className={styles.textProtocol}>{bb.buildingBlocksTitle}</p>
            <p className={styles.textDescription}>{bb.buildingBlocksDescription}</p>

            {bb.buttonText && bb.buttonUrl && (
              <Link to={bb.buttonUrl} className={styles.link}>
                {bb.buttonText}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
