import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import { sortComponents } from '../utils/helpers'
import ProductSchema from '../components/Shared/Schema/Product'
import FaqSchema from '../components/Shared/Schema/Faq'
import Info from '../components/BbMultiplePage/Info'
import Vitamins from '../components/BbMultiplePage/Vitamins'
import Ingredients from '../components/BbMultiplePage/Ingredients'
import Table from '../components/BbMultiplePage/Table'
import Testosterone from '../components/BbMultiplePage/Testosterone'
import Reviews from '../components/BbMultiplePage/Reviews'
import Protocols from '../components/BbMultiplePage/Protocols'
import Faq from '../components/BbMultiplePage/Faq'
import References from '../components/BbMultiplePage/References'
import Wim from '../components/BbMultiplePage/Wim'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Vitamins vitamins={data.vitamins} />,
      priority: data.vitamins?.priority || 99,
    },
    {
      render: <Ingredients ingredients={data.ingredients} />,
      priority: data.ingredients?.priority || 99,
    },
    {
      render: <Table table={data.table} />,
      priority: data.table?.priority || 99,
    },
    {
      render: <Testosterone testosterone={data.testosterone} />,
      priority: data.testosterone?.priority || 99,
    },
    {
      render: <Reviews reviews={data.reviews} />,
      priority: data.reviews?.priority || 99,
    },
    {
      render: <Protocols protocols={data.protocols} />,
      priority: data.protocols?.priority || 99,
    },
    {
      render: <Faq faq={data.faq} />,
      priority: data.faq?.priority || 99,
    },
    {
      render: <References references={data.references} />,
      priority: data.references?.priority || 99,
    },
    {
      render: <Wim wim={data.wim} />,
      priority: data.wim?.priority || 99,
    },
  ].sort(sortComponents)

  return (
    <Layout 
      data={data} 
      signUpUrl={data.info?.buttonUrl} 
      signUpText={data.info?.buttonText} 
      headerDisable={data.custom?.isHideHeader}
    >
      <SEO {...data.seo} />
      <ProductSchema {...data.productSchema} />
      {(data?.faq?.faqList?.items || data?.faq?.items) && (
        <FaqSchema questions={data?.faq?.faqList?.items || data?.faq?.items} />
      )}
      <Info info={data.info} headerDisable={data.custom?.isHideHeader} />
      {components.map(component => component.render)}
    </Layout>
  )
}
