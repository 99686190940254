import React from 'react'

import cx from 'classnames'
import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'
import { prepareParagraph, sanityImageUrl, imageAutoFormat } from '../../../utils/helpers'

import styles from './style.module.sass'

export default ({ worldClassBenefits }) => {
  return (
    <div className={styles.fullBackground}>
      <Container classes={styles.outer}>
        <Wrapper classes={styles.wrapper}>
          <Item classes={cx(styles.contentContainer)}>
            <Title classes={styles.title}>{worldClassBenefits.title}</Title>
          </Item>
          <Item classes={cx(styles.contentContainer, styles.items)}>
            {worldClassBenefits.items &&
              worldClassBenefits.items.map(item => {
                return (
                  <div className={styles.item} key={item._key}>
                    <div className={styles.itemIconWrapper}>
                      <img src={imageAutoFormat(sanityImageUrl(item.Icon))} width="50" height="50" alt={item.title} />
                    </div>
                    <div className={styles.itemWrapper}>
                      <div className={styles.itemWrapper__heading}>{item.title}</div>
                      <div className={styles.itemWrapper__text}>{prepareParagraph(item.description)}</div>
                    </div>
                  </div>
                )
              })}
          </Item>
        </Wrapper>
      </Container>
    </div>
  )
}
