import React from 'react'
import cx from 'classnames'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ benefits }) => (
  <div className={styles.benefits}>
    <div className={styles.wrap}>
      <p className={styles.title}>{benefits.title}</p>

      <div className={styles.items}>
        <img
          {...srcSetProps(sanityImageUrl(benefits.image))}
          className={styles.background}
          alt={benefits.image?.caption}
        />
        <div className={styles.topLeft}>
          <p className={styles.header}>{benefits.topLeftTitle}</p>
          <p className={styles.text}>{prepareParagraph(benefits.topLeftText)}</p>
          <a className={styles.link} href={benefits.topLeftButtonUrl} target="_blank">
            {benefits.topLeftButtonText}
          </a>
        </div>
        <div className={styles.topRight}>
          <p className={styles.header}>{benefits.topRightTitle}</p>
          <p className={styles.text}>{prepareParagraph(benefits.topRightText)}</p>
          <a className={styles.link} href={benefits.topRightButtonUrl} target="_blank">
            {benefits.topRightButtonText}
          </a>
        </div>
        <div className={styles.bottomLeft}>
          <p className={styles.header}>{benefits.bottomLeftTitle}</p>
          <p className={styles.text}>{prepareParagraph(benefits.bottomLeftText)}</p>
          <a className={styles.link} href={benefits.bottomLeftButtonUrl} target="_blank">
            {benefits.bottomLeftButtonText}
          </a>
        </div>
        <div className={styles.bottomRight}>
          <p className={styles.header}>{benefits.bottomRightTitle}</p>
          <p className={styles.text}>{prepareParagraph(benefits.bottomRightText)}</p>
          <a className={styles.link} href={benefits.bottomRightButtonUrl} target="_blank">
            {benefits.bottomRightButtonText}
          </a>
        </div>
      </div>
    </div>
  </div>
)
