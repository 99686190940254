import React from 'react'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../../utils/helpers'
import styles from './style.module.sass'

export default ({ blocks }) => {
  return (
    <div className={styles.blocks}>
      <div className={styles.wrap}>
        {blocks.items &&
          blocks.items.map(block => (
            <div key={block._key} className={styles.block}>
              <img {...srcSetProps(sanityImageUrl(block.image))} alt={block.image?.caption} className={styles.image} />
              <h2 className={styles.title}>{block.title}</h2>
              <p className={styles.text}>{prepareParagraph(block.text)}</p>
            </div>
          ))}
      </div>
    </div>
  )
}
