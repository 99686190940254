import React from 'react'
import { sanityImageUrl, prepareParagraph, titleToId } from '/src/utils/helpers'
import styles from './style.module.sass'

export default ({ analysis }) => {
  return (
    <div className={styles.analysis}>
      <div className={styles.anchor} id={titleToId(analysis.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{analysis.title}</h2>

      <div className={styles.top}>
        <img
          src={sanityImageUrl(analysis.descriptionIcon)}
          className={styles.topImage}
          alt={analysis.descriptionIcon?.caption}
        />
        <p className={styles.topText}>{analysis.description}</p>
      </div>

      <div className={styles.content}>
        {analysis.content &&
          analysis.content.map((item, index) => (
            <div className={styles.item} key={index}>
              <p className={styles.paragraph}>{prepareParagraph(item)}</p>
            </div>
          ))}
      </div>
    </div>
  )
}
