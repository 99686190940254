import React from 'react'
import cx from 'classnames'
import { srcSetProps, prepareParagraph, titleToId, sanityImageUrl } from '../../../../utils/helpers'
import styles from './style.module.sass'

export default ({ enclomiphene }) => {
  return (
    <div className={styles.enclomiphene}>
      <div className={styles.anchor} id={titleToId(enclomiphene.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{enclomiphene.title}</h2>
      <div className={styles.content}>
        {enclomiphene.items &&
          enclomiphene.items.map(item => (
            <div className={styles.block}>
              <p className={styles.header}>{item.title}</p>
              <p className={styles.text}>{prepareParagraph(item.text)}</p>

              <div className={styles.mobile}>
                <div className={styles.mobileLeft}>
                  <p className={styles.imageTitle}>{item.imageTitle}</p>
                  <p className={styles.imageInfo}>{item.imageInfo}</p>
                  <p className={styles.imageReference}>{item.imageReference}</p>
                </div>
                <div className={styles.mobileRight}>
                  <img
                    {...srcSetProps(sanityImageUrl(item.image))}
                    alt={item.image?.caption}
                    className={styles.image}
                  />
                  <p className={styles.imageReferenceMobile}>{item.imageReference}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className={cx(styles.content, styles.contentDesktop)}>
        {enclomiphene.items &&
          enclomiphene.items.map(item => (
            <div className={styles.block}>
              <p className={styles.imageTitle}>{item.imageTitle}</p>
              <p className={styles.imageInfo}>{item.imageInfo}</p>
              <img {...srcSetProps(sanityImageUrl(item.image))} alt={item.image?.caption} className={styles.image} />
              <p className={styles.imageReference}>{item.imageReference}</p>
            </div>
          ))}
      </div>
    </div>
  )
}
