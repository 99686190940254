import React, { useState } from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import { sanityImageUrl } from '/src/utils/helpers'
import styles from './style.module.sass'

export default ({ header, links }) => {
  const [contentOpen, setContentOpen] = useState(false)

  const handlerClick = () => {
    setContentOpen(!contentOpen)
  }

  return (
    <>
      <div
        className={cx(styles.nav, {
          [styles.navOpen]: contentOpen,
        })}
        onClick={handlerClick}
      >
        <div className={styles.navWrap}>
          <div className={styles.handler}></div>
          <ul className={styles.navList}>
            {links.map((item, index) => (
              <li className={styles.navItem} key={index} data-js-id={item.id}>
                <a className={styles.navLink} href={item.link}>
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
          <div className={styles.navTitle}>Table of Contents</div>
        </div>
      </div>

      <ul className={styles.breadcrumbs}>
        {header.breadcrumbs &&
          header.breadcrumbs.map((item, index) => (
            <li key={item._key}>
              <Link className={styles.breadcrumbsLink} to={item.url}>
                {item.text}
              </Link>
            </li>
          ))}
      </ul>
    </>
  )
}
