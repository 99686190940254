import React from 'react'
import { sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ reviews }) => {
  if (!reviews || reviews.isHide) return null

  return (
    <div className={styles.reviews}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={styles.top}>
            <h2 className={styles.title}>{reviews.title}</h2>
            {reviews.rating?.url && (
              <a href={reviews.rating.url} target="_blank" className={styles.rating}>
                {reviews.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(reviews.rating.starsImage)} alt={reviews.rating?.text}/>}
                {reviews.rating.text && <span className={styles.ratingText}>{reviews.rating.text}</span>}
              </a>
            )}
          </div>
          <div className={styles.list}>
            {reviews.items && reviews.items.map(item => (
              <div className={styles.item} key={item._key}>
                <div>
                  <p className={styles.name}>{item.name}</p>
                  <p className={styles.verified}>Verified buyer</p>
                  <p className={styles.body}>{item.review}</p>
                </div>
                <p className={styles.protocol} dangerouslySetInnerHTML={{ __html: item.protocol }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
