import React, { useState } from 'react'
import { prepareParagraph } from '../../../utils/helpers'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ ingredients }) => {
  if (!ingredients || ingredients.isHide) return null

  const [sections, setSections] = useState([])
  const [showAll, setShowAll] = useState(false)

  const clickHandler = id => {
    setSections(sections.includes(id) ? sections.filter(el => el !== id) : [...sections, id])
  }

  return (
    <div className={styles.ingredients}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{ingredients.title}</h2>
        <div
          className={cx(styles.list, {
            [styles.listAll]: showAll,
          })}
        >
          {ingredients.items &&
            ingredients.items.map((item, index) => (
              <div
                className={cx(styles.item, {
                  [styles.itemOpen]: sections.includes(item._key),
                })}
                key={item._key}
              >
                <div className={styles.name} onClick={() => clickHandler(item._key)}>
                  {item.name}
                </div>
                <div className={styles.inside}>
                  <div className={styles.top}>
                    <span className={styles.topName}>{item.name}</span>
                    <span className={styles.topDosage}>{item.title}</span>
                  </div>
                  <p className={styles.description}>{prepareParagraph(item.Description)}</p>
                  {item.benefits && (
                    <div className={styles.benefits}>
                      <p className={styles.benefitsTitle}>Benefits:</p>
                      <ul className={styles.benefitsList}>
                        {item.benefits && item.benefits.map((item, index) => <li key={index}>{item}</li>)}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
        {ingredients.items && ingredients.items.length > 5 && (
          <div className={styles.view} onClick={() => setShowAll(!showAll)}>
            {showAll ? 'Hide' : 'View'} All Ingredients
          </div>
        )}
        <p className={styles.offLabels}>{prepareParagraph(ingredients.offLabels)}</p>
      </div>
    </div>
  )
}
