import React, { useState, useEffect, useMemo } from 'react'
import { urlWithSearchParamsHandler, srcSetProps, prepareParagraph, sanityImageUrl } from '../../../utils/helpers'
import SwiperObj, { Pagination, FreeMode, Mousewheel } from 'swiper'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Link } from 'gatsby'
import cx from 'classnames'
import styles from './style.module.sass'

const MainImage = ({ top, sliderSettings }) => (
  <div>
    <Swiper {...sliderSettings}>
      {top.images && top.images.map(image => (
        <SwiperSlide className={styles.sliderItem}>
          <img
            className={styles.sliderItemImg}
            {...srcSetProps(sanityImageUrl(image.image))}
            alt={image.image?.caption}
          />
        </SwiperSlide>
      ))}
      <div className={styles.sliderDots} />
    </Swiper>
    <div className={styles.imageWrap}>
      <div className={styles.imageBox}>
        {top.images && top.images.map(image => (
          <img
            key={image._key}
            className={styles.imageSrc}
            {...srcSetProps(sanityImageUrl(image.image))}
            alt={image.image?.caption}
          />
        ))}
      </div>
    </div>
  </div>
)

export default ({ top, headerDisable }) => {
  if (!top) return null

  SwiperObj.use([Pagination, FreeMode, Mousewheel])

  const sliderSettings = {
    spaceBetween: 16,
    slidesPerView: 1.1,
    freeMode: {
      enabled: true,
      momentumVelocityRatio: 0.5,
    },
    mousewheel: {
      releaseOnEdges: true,
      sensitivity: 0.5,
      forceToAxis: true,
    },
    pagination: {
      el: `.${styles.sliderDots}`,
      clickable: true
    },
    className: styles.slider,
  }

  const [subscriptionActive, setSubscriptionActive] = useState(top.prices[0])
  const [safetyModalShow, setSafetyModalShow] = useState(false)

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin)
  }, [])

  const openSafetyModalHandler = () => {
    setSafetyModalShow(true)
  }

  const closeSafetyModalHandler = () => {
    setSafetyModalShow(false)
  }

  const anotherSubscription = useMemo(() => {
    return top.prices.filter(price => price !== subscriptionActive)
  }, [subscriptionActive])

  return (
    <>
      <div className={styles.top}>
        <ul className={styles.breadcrumbs}>
          {top.breadcrumbs &&
            top.breadcrumbs.map((item, index) => (
              <li key={item._key}>
                <Link className={styles.breadcrumbsLink} to={item.url}>
                  {item.text}
                </Link>
              </li>
            ))}
        </ul>

        <div className={styles.wrap}>
          <div className={styles.image}>
            <MainImage top={top} sliderSettings={sliderSettings} />
          </div>

          <div className={styles.content}>
            <div className={styles.contentTop}>
              <h1 className={styles.title}>{top.title}</h1>
              {top.rating?.url && (
                <Link to={top.rating.url} className={styles.rating}>
                  {top.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(top.rating.starsImage)} alt={top.rating?.text}/>}
                  {top.rating.text && <p className={styles.ratingText}>{top.rating.text}</p>}
                </Link>
              )}
            </div>

            <p className={styles.description}>{top.description}</p>

            <ul className={styles.prices}>
              {top.prices && top.prices.map((price, index) => (
                <li className={cx(styles.pricesItem, {
                  [styles.pricesItemActive]: price === subscriptionActive
                })} key={price._key} onClick={() => setSubscriptionActive(price)}>
                  <p className={styles.pricesLeft}>
                    <span className={styles.pricesPlan} dangerouslySetInnerHTML={{ __html: price.plan }} />
                    {price.information && (
                      <span className={styles.pricesInformation} dangerouslySetInnerHTML={{ __html: price.information }} />
                    )}
                  </p>
                  <p className={styles.pricesRight} dangerouslySetInnerHTML={{ __html: price.price }} />
                </li>
              ))}
            </ul>

            {top.needHelp?.title && (
              <div className={styles.help}>
                <p className={styles.helpTitle}>{top.needHelp.title}</p>
                <p className={styles.helpText}>{prepareParagraph(top.needHelp.text)}</p>
              </div>
            )}

            {top.importantSafetyModal?.modalText && (
              <div className={styles.safetyModalLink} onClick={openSafetyModalHandler}>ⓘ <span>{top.importantSafetyModal.modalText}</span></div>
            )}

            <a 
              href={subscriptionActive.buttonUrl} 
              onClick={urlWithSearchParamsHandler} 
              className={styles.link}
            >{subscriptionActive.buttonText}
            </a>

            <ul className={styles.benefits}>
              {top.benefits &&
                top.benefits.map((benefit, index) => (
                  <li key={benefit._key}>
                    <img src={sanityImageUrl(benefit.image)} alt={benefit.image?.caption} />
                    <span>{benefit.text}</span>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        {safetyModalShow && (
          <div className={styles.safetyModal}>
            <div className={styles.safetyModalBg} onClick={closeSafetyModalHandler} />
            <div className={styles.safetyModalWrap}>
              <div className={styles.safetyModalBox}>
                <div className={styles.safetyModalClose} onClick={closeSafetyModalHandler} />
                <p className={styles.safetyModalTitle}>{top.importantSafetyModal.modalTitle}</p>
                <div className={styles.safetyModalContent}>{prepareParagraph(top.importantSafetyModal.modalContent)}</div>
              </div>
            </div>
          </div>
        )}      
      </div>
      <div className={styles.nav}>
        <div className={styles.wrap}>
          <p className={styles.navTitle}>{top.title}</p>
          <div className={styles.navInfo}>
            <a 
              href={subscriptionActive.buttonUrl} 
              onClick={urlWithSearchParamsHandler} 
              className={styles.navButton}
            >{subscriptionActive.buttonText}</a>
            <div className={styles.navPrice}>
              <p className={styles.navPriceText} dangerouslySetInnerHTML={{ __html: subscriptionActive.price }} />
              {anotherSubscription[0] && (
                <p className={styles.navPriceToggler} onClick={() => setSubscriptionActive(anotherSubscription[0])}>Change to {anotherSubscription[0].plan}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
