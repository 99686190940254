import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import { sortComponents } from '../utils/helpers'
import Header from '../components/CareersPage/Header'
import Positions from '../components/CareersPage/Positions'
import Life from '../components/CareersPage/Life'
import Recruitment from '../components/CareersPage/Recruitment'
import Benefits from '../components/CareersPage/Benefits'
import Values from '../components/CareersPage/Values'
import Wim from '../components/CareersPage/Wim'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Positions positions={data.positions} />,
      priority: data.positions?.priority || 99,
    },
    {
      render: <Life life={data.life} />,
      priority: data.life?.priority || 99,
    },
    {
      render: <Recruitment recruitment={data.recruitment} />,
      priority: data.recruitment?.priority || 99,
    },
    {
      render: <Benefits benefits={data.benefits} />,
      priority: data.benefits?.priority || 99,
    },
    {
      render: <Values values={data.values} />,
      priority: data.values?.priority || 99,
    },
    {
      render: <Wim wim={data.wim} />,
      priority: data.wim?.priority || 99,
    }
  ].sort(sortComponents)

  return (
    <Layout data={data} signUpUrl={data.header?.buttonUrl} signUpText={data.header?.buttonText}>
      <SEO {...data.seo} />
      <Header header={data.header} />
      {components.map(component => component.render)}
    </Layout>
  )
}