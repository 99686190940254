import React from 'react'
import cx from 'classnames'
import { prepareParagraph, srcSetProps, urlWithSearchParamsHandler, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ howItWorks, availabilityText }) => {
  if (howItWorks.isHide) return null

  return (
    <div className={styles.howItWorks}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>{howItWorks.title}</h2>
          <p className={styles.description}>{howItWorks.description}</p>
        </div>
        {howItWorks.items &&
          howItWorks.items.map((item, index) => (
            <div className={styles.item}>
              <div className={styles.itemInfo}>
                <div className={styles.itemTop}>
                  <p className={styles.index}>{index + 1}</p>
                  <p className={styles.step}>{item.title}</p>
                  <p className={styles.text}>{prepareParagraph(item.text)}</p>
                </div>
                {index === 0 && (
                  <>
                    {availabilityText?.toLowerCase() === 'out of stock' ? (
                      <a className={cx(styles.link, styles.itemLink, styles.linkDisabled)}>{howItWorks.linkText}</a>
                    ) : (
                      <a
                        href={howItWorks.linkUrl}
                        onClick={urlWithSearchParamsHandler}
                        className={cx(styles.link, styles.itemLink)}
                      >
                        {howItWorks.linkText}
                      </a>
                    )}
                  </>
                )}
              </div>
              <div className={styles.itemImage}>
                <img
                  {...srcSetProps(sanityImageUrl(item.image))}
                  className={styles.itemImageSrc}
                  alt={item.image?.caption}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
