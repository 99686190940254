import React from 'react'
import cx from 'classnames'
import { prepareParagraph } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ table }) => {
  if (!table || table.isHide) return null

  const getIconByValue = value => {
    switch (value) {
      case 'plus':
        return <i className={styles.iconPlus} />
      case 'minus':
        return <i className={styles.iconMinus} />
    }
  }

  return (
    <div className={styles.table}>
      <div className={styles.wrap}>
        <div className={styles.box}>
          <div className={styles.row}>
            <div className={cx(styles.cell, styles.cellCorner)}></div>
            <div
              className={cx(styles.cell, styles.cellAlign)}
              dangerouslySetInnerHTML={{ __html: table.title_column_1 }}
            />
            <div
              className={cx(styles.cell, styles.cellAlign)}
              dangerouslySetInnerHTML={{ __html: table.title_column_2 }}
            />
          </div>

          {table.tableRows &&
            table.tableRows.map(row => (
              <div className={cx(styles.row, styles.rowLine)} key={row._key}>
                <div className={styles.cell}>
                  {row.title}
                  <br />
                  <span className={styles.cellSmall}>{row.info}</span>
                </div>
                <div className={cx(styles.cell, styles.cellIcon)}>{getIconByValue(row.column_1)}</div>
                <div className={cx(styles.cell, styles.cellIcon)}>{getIconByValue(row.column_2)}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
