import React from 'react'
import { sanityImageUrl } from '../../../utils/helpers'
import SwiperObj, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './style.module.sass'

export default ({ howItWorks }) => {
  if (!howItWorks || howItWorks.isHide) return null

  SwiperObj.use([Pagination])

  const sliderSettings = {
    spaceBetween: 64,
    slidesPerView: 3,
    pagination: {
      el: `.${styles.dots}`,
      clickable: true
    },
    className: styles.slider,
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 16
      },
      701: {
        slidesPerView: 3,
        spaceBetween: 32
      },
      1101: {
        slidesPerView: 3,
        spaceBetween: 64
      }
    },
  }

  return (
    <div className={styles.howItWorks}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{howItWorks.title}</h2>
        <img className={styles.phone} src={sanityImageUrl(howItWorks.imagePhone)} alt={howItWorks.imagePhone?.caption} />
        <Swiper {...sliderSettings}>
          {howItWorks.items && howItWorks.items.map((item, index) => (
            <>
              <SwiperSlide className={styles.item} key={item._key}>
                <img className={styles.image} src={sanityImageUrl(item.image)} alt={item.image?.caption} />
                <div className={styles.box}>
                  <div className={styles.top}>
                    <p className={styles.index}>{index + 1}</p>
                    <p className={styles.name}>{item.title}</p>
                    {item.benefits && (
                      <>
                        {item.benefits.length === 1 ? (
                          <p className={styles.benefit}>{item.benefits[0]}</p>
                        ) : (
                          <ul className={styles.benefits}>
                            {item.benefits.map((b, index) => (
                              <li className={styles.benefitsItem} key={index}>{b}</li>
                            ))}
                          </ul>
                        )}
                      </> 
                    )}
                  </div>
                  <img className={styles.attachment} src={sanityImageUrl(item.attachment)} alt={item.attachment?.caption} />
                </div>
              </SwiperSlide>
            </>
          ))}
          <div className={styles.dots} />
        </Swiper>
      </div>
    </div>
  )
}
