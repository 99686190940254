import React from 'react'
import { prepareParagraph, titleToId } from '/src/utils/helpers'
import styles from './style.module.sass'

export default ({ data }) => {
  return (
    <div className={styles.data}>
      <div className={styles.anchor} id={titleToId(data.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{data.title}</h2>
      <p className={styles.description}>{data.description}</p>
      <div className={styles.content}>
        {data.Content &&
          data.Content.map(block => (
            <div className={styles.paragraph}>
              <p className={styles.headline}>{prepareParagraph(block.title)}</p>
              <div className={styles.anchor} id={titleToId(block.title)} data-js="scroll-section" />

              {block.body &&
                block.body.map(item => (
                  <div className={styles.item}>
                    {item._type === 'info' && <p className={styles.info}>{prepareParagraph(item.info)}</p>}

                    {item._type === 'table2Columns' && (
                      <div className={styles.table2}>
                        <div className={styles.table}>
                          <p className={styles.tableTitle} dangerouslySetInnerHTML={{ __html: item.title1 }} />
                          <ul className={styles.tableList}>
                            {item.items1 &&
                              item.items1.map((item, index) => (
                                <li key={index} className={styles.tableItem}>
                                  {item}
                                </li>
                              ))}
                          </ul>
                        </div>

                        <div className={styles.table}>
                          <p className={styles.tableTitle} dangerouslySetInnerHTML={{ __html: item.title2 }} />
                          <ul className={styles.tableList}>
                            {item.items2 &&
                              item.items2.map((item, index) => (
                                <li key={index} className={styles.tableItem}>
                                  {item}
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    )}

                    {item._type === 'table1Column' && (
                      <div className={styles.table1}>
                        <p className={styles.tableTitle} dangerouslySetInnerHTML={{ __html: item.title }} />
                        <ul className={styles.tableList}>
                          {item.items &&
                            item.items.map((item, index) => (
                              <li key={index} className={styles.tableItem}>
                                {item}
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}

                    {item._type === 'divider' && <div className={styles.divider} />}
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  )
}
