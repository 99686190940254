import React from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import { Link } from 'gatsby'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ process }) => {
  return (
    <div className={styles.process}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{process.title}</h2>
        <a href={process.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>
          {process.buttonText}
        </a>

        <div className={styles.steps}>
          {process.steps &&
            process.steps.map((step, index) => (
              <div key={step._key} className={styles.step}>
                <div className={styles.stepLeft}>
                  <img
                    className={styles.stepImage}
                    {...srcSetProps(sanityImageUrl(step?.image))}
                    alt={step.image?.caption}
                  />
                </div>
                <div className={styles.stepRight}>
                  <p className={styles.stepTitle}>{step.title}</p>
                  <p className={styles.stepInfo}>
                    {step.information && step.information.length == 1 && <span>{step.information[0]}</span>}

                    {step.information && step.information.length > 1 && (
                      <ol>
                        {step.information.map((step, index) => (
                          <li key={index}>{step}</li>
                        ))}
                      </ol>
                    )}
                  </p>
                </div>
                <div className={styles.stepIndex}>{index + 1}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
