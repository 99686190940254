import React from 'react'
import { sanityImageUrl, urlWithSearchParamsHandler } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ price }) => {
  if (!price || price.isHide) return null

  return (
    <div className={styles.price}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{price.title}</h2>
        <div className={styles.list}>
          {price.items && price.items.map((item, index) => (
            <div className={styles.item} key={item._key}>
              <img className={styles.image} src={sanityImageUrl(item.image)} alt={item.image?.caption} />
              <p className={styles.product}>{item.title}</p>
              <p className={styles.text}>{item.description}</p>
              <p className={styles.subscription}>{item.subscriptionText}</p>
              <table className={styles.table}>
                <tbody>
                  <tr>
                    <td></td>
                    <td>{item.title_column_1}</td>
                    <td>{item.title_column_2}</td>
                  </tr>
                  {item.tableRows && item.tableRows.map((row, index) => (
                    <tr key={index}>
                      <td dangerouslySetInnerHTML={{ __html: row.title }} />
                      <td>{row.column_1}</td>
                      <td>{row.column_2}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <a href={item.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>
                {item.buttonText}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
