import React from 'react'
import { prepareParagraph, urlWithSearchParamsHandler, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ steps }) => {
  if (!steps || steps.isHide) return null

  return (
    <div className={styles.steps}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{steps.title}</h2>
        <p className={styles.description}>{prepareParagraph(steps.description)}</p>
        <div className={styles.list}>
          {steps.items &&
            steps.items.map(step => (
              <div className={styles.listItem} key={step._key}>
                <img src={sanityImageUrl(step.image)} className={styles.listImage} alt={step.image?.caption} />
                <p className={styles.listText}>{step.title}</p>
                <p className={styles.listInfo}>{step.description}</p>
              </div>
            ))}
        </div>
        <p className={styles.ready}>
          <span className={styles.readyText}>{steps.readyTitle}</span>
          <a href={steps.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.readyLink}>
            {steps.buttonText}
          </a>
        </p>
      </div>
    </div>
  )
}
