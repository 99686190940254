import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { urlWithSearchParamsHandler } from '../../../utils/helpers'
import cx from 'classnames'
import styles from './style.module.sass'

const SmartLink = ({ text, url }) => {
  if (url.includes('app.maximustribe.com')) {
    return (
      <a href={url} onClick={urlWithSearchParamsHandler} className={styles.link}>{text}</a>
    )
  }

  return <Link to={url} className={styles.link}>{text}</Link>
}

export default ({ info }) => {
  if (!info) return null

  useEffect(() => {
    document.documentElement.classList.add('h100')
  }, [])

  return (
    <div className={cx(styles.info, {
      [styles.infoDark]: info.isDark
    })}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{info.title}</h2>
        <div className={styles.links}>
          {info.buttons && info.buttons.map(button => <SmartLink text={button.buttonText} url={button.buttonUrl} />)}
        </div>
      </div>
    </div>
  )
}
