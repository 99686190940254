import React from 'react'
import { Link } from 'gatsby'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ hairGrowth }) => {
  if (!hairGrowth || hairGrowth.isHide) return null

  return (
    <div className={styles.hairGrowth}>
      <img
        {...srcSetProps(sanityImageUrl(hairGrowth.image))}
        className={styles.image}
        alt={hairGrowth.image?.caption}
      />
      <img
        {...srcSetProps(sanityImageUrl(hairGrowth.mobileImage))}
        className={styles.imageMobile}
        alt={hairGrowth.mobileImage?.caption}
      />
      <div className={styles.wrap}>
        <div className={styles.content}>
          <p className={styles.titleSmall}>{hairGrowth.smallTitle}</p>
          <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: hairGrowth.title }} />
          <p className={styles.price} dangerouslySetInnerHTML={{ __html: hairGrowth.price }} />
          <div className={styles.buttons}>
            <a
              href={hairGrowth.getStartedButtonUrl}
              onClick={urlWithSearchParamsHandler}
              className={styles.buttonsStarted}
            >
              {hairGrowth.getStartedButtonText}
            </a>
            <Link className={styles.buttonsMore} to={hairGrowth.learnMoreButtonUrl}>
              {hairGrowth.learnMoreButtonText}
            </Link>
          </div>
          <div className={styles.info}>
            <div className={styles.infoColumn}>
              <p className={styles.infoTitle}>{hairGrowth.ingredientsTitle}</p>
              <ul className={styles.infoList}>
                {hairGrowth.ingredientsItems &&
                  hairGrowth.ingredientsItems.map(item => (
                    <li className={styles.infoItem} key={item._key}>
                      {item.ingredient}
                    </li>
                  ))}
              </ul>
            </div>
            <div className={cx(styles.infoColumn, styles.infoColumnBenefits)}>
              <p className={styles.infoTitle}>{hairGrowth.benefitsTitle}</p>
              <ul className={cx(styles.infoList, styles.infoListPoints)}>
                {hairGrowth.benefitsItems &&
                  hairGrowth.benefitsItems.map(item => (
                    <li className={styles.infoItem} key={item._key}>
                      {item.benefit}
                    </li>
                  ))}
              </ul>
            </div>
            <div className={styles.infoColumn}>
              <p className={styles.infoTitle}>{hairGrowth.productsTitle}</p>
              <ul className={styles.infoList}>
                {hairGrowth.productsItems &&
                  hairGrowth.productsItems.map(item => (
                    <li className={styles.infoItem} key={item._key}>
                      <Link className={styles.infoLink} to={item.productUrl}>
                        {item.productText}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
