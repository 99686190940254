import React from 'react'
import { prepareParagraph, sanityImageUrl, titleToId } from '/src/utils/helpers'
import styles from './style.module.sass'

export default ({ options }) => {
  return (
    <div className={styles.options}>
      <div className={styles.anchor} id={titleToId(options.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{options.title}</h2>
      <div className={styles.content}>
        {options.content &&
          options.content.map(block => (
            <div className={styles.paragraph}>
              <img className={styles.icon} src={sanityImageUrl(block.image)} alt={block.image?.caption} />
              <p className={styles.headline}>{prepareParagraph(block.title)}</p>

              {block.body &&
                block.body.map(item => (
                  <div className={styles.item}>
                    {item._type === 'info' && <p className={styles.info}>{prepareParagraph(item.info)}</p>}

                    {item._type === 'warning' && <p className={styles.warning}>{prepareParagraph(item.warning)}</p>}

                    {item._type === 'infoWithTable' && (
                      <div className={styles.infoTable}>
                        <div className={styles.left}>
                          <p className={styles.info}>{prepareParagraph(item.info)}</p>
                        </div>
                        <div className={styles.right}>
                          <div className={styles.infoTableBox}>
                            <p className={styles.infoTableTitle}>{prepareParagraph(item.title1)}</p>
                            <ul className={styles.list}>
                              {item.items1 &&
                                item.items1.map((listItem, index) => (
                                  <li key={index} className={styles.listItem}>
                                    {listItem}
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div className={styles.infoTableBox}>
                            <p className={styles.infoTableTitle}>{prepareParagraph(item.title2)}</p>
                            <ul className={styles.list}>
                              {item.items2 &&
                                item.items2.map((listItem, index) => (
                                  <li key={index} className={styles.listItem}>
                                    {listItem}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}

                    {item._type === 'fullTable4' && (
                      <div className={styles.fullTable}>
                        <div className={styles.col}>
                          <p className={styles.fullTableTitle}>{prepareParagraph(item.title1)}</p>
                          <p className={styles.fullTableBody}>{prepareParagraph(item.body1)}</p>
                        </div>
                        <div className={styles.col}>
                          <p className={styles.fullTableTitle}>{prepareParagraph(item.title2)}</p>
                          <p className={styles.fullTableBody}>{prepareParagraph(item.body2)}</p>
                        </div>
                        <div className={styles.col}>
                          <p className={styles.fullTableTitle}>{prepareParagraph(item.title3)}</p>
                          <p className={styles.fullTableBody}>{prepareParagraph(item.body3)}</p>
                        </div>
                        <div className={styles.col}>
                          <p className={styles.fullTableTitle}>{prepareParagraph(item.title4)}</p>
                          <p className={styles.fullTableBody}>{prepareParagraph(item.body4)}</p>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  )
}
