import React from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ header }) => {
  return (
    <>
      {header.fixedNavigationTitle && (
        <div className={styles.nav}>
          <div className={styles.wrap}>
            <p className={styles.navTitle}>{header.fixedNavigationTitle}</p>
            <a 
              href={header.fixedNavigationButtonUrl} 
              onClick={urlWithSearchParamsHandler} 
              className={styles.navButton}
            >{header.fixedNavigationButtonText}</a>
          </div>
        </div>
      )}

      <div className={styles.header}>
        <img
          {...srcSetProps(sanityImageUrl(header.image))}
          alt={header.image?.caption}
          className={styles.image}
        />

        <img
          {...srcSetProps(sanityImageUrl(header.imageMobile))}
          alt={header.image?.caption}
          className={styles.imageMobile}
        />

        <div className={styles.wrap}>
          <h1 className={styles.title}>{header.title}</h1>
          <div className={styles.list}>
            <p className={styles.listTitle}>{header.topListTitle}</p>
            <ul className={styles.listItems}>
              {header.topListItems && header.topListItems.map((item, index) => (
                <li className={styles.listItem} key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
