import React from 'react'

import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'

import cx from 'classnames'
import styles from './style.module.sass'

import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/helpers'

export default ({ howMade, blockId = 'how_it_s_made' }) => {
  return (
    <Container classes={styles.outer}>
      <Wrapper classes={styles.wrapper}>
        <Item classes={styles.contentContainer} id={blockId}>
          <Title>How is testosterone made?</Title>
        </Item>
        <Item classes={styles.contentContainer}>
          <div className={styles.blueText}>{howMade.blueHeader}</div>
          {howMade.paragraphs &&
            howMade.paragraphs.map(paragraph => (
              <div key={paragraph._key} className={styles.grayText}>
                {prepareParagraph(paragraph.text)}
              </div>
            ))}
        </Item>
      </Wrapper>
      <Wrapper classes={styles.wrapper}>
        <Item classes={styles.contentContainer}>
          <img
            className={cx(styles.image, styles.hideMobile)}
            {...srcSetProps(sanityImageUrl(howMade.horizontalImage?.image))}
            alt={howMade.horizontalImage?.image?.caption}
          />
          <img
            className={cx(styles.image, styles.showMobile)}
            {...srcSetProps(sanityImageUrl(howMade.verticalImage?.image))}
            alt={howMade.verticalImage?.image?.caption}
          />
        </Item>
      </Wrapper>
    </Container>
  )
}
