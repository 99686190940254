import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import { sortComponents } from '../utils/helpers'
import Header from '../components/SocialProofPage/Header'
import Reviews from '../components/SocialProofPage/Reviews'
import HowItWorks from '../components/SocialProofPage/HowItWorks'
import Price from '../components/SocialProofPage/Price'
import BuildingBlocks from '../components/SocialProofPage/BuildingBlocks'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Reviews reviews={data.reviews} />,
      priority: data.reviews?.priority || 99,
    },
    {
      render: <HowItWorks howItWorks={data.howItWorks} />,
      priority: data.howItWorks?.priority || 99,
    },
    {
      render: <Price price={data.price} />,
      priority: data.price?.priority || 99,
    },
    {
      render: <BuildingBlocks buildingBlocks={data.buildingBlocks} />,
      priority: data.buildingBlocks?.priority || 99,
    }
  ].sort(sortComponents)

  return (
    <Layout data={data} signUpUrl={data.header?.buttonUrl} signUpText={data.header?.buttonText}>
      <SEO {...data.seo} />
      <Header header={data.header} />
      {components.map(component => component.render)}
    </Layout>
  )
}
