import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import { sortComponents } from '../utils/helpers'
import Top from '../components/HomePageV6/Top'
import Product from '../components/HomePageV6/Product'
import HowItWorks from '../components/HomePageV6/HowItWorks'
import Reviews from '../components/HomePageV6/Reviews'
import Faq from '../components/HomePageV6/Faq'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Product product={data.product} />,
      priority: data.product?.priority || 99,
    },
    {
      render: <HowItWorks howItWorks={data.howItWorks} />,
      priority: data.howItWorks?.priority || 99,
    },
    {
      render: <Reviews reviews={data.reviews} />,
      priority: data.reviews?.priority || 99,
    },
    {
      render: <Faq faq={data.faq} />,
      priority: data.faq?.priority || 99,
    }
  ].sort(sortComponents)

  return (
    <Layout data={data}>
      <SEO {...data.seo} />
      <Top top={data.Top} />
      {components.map(component => component.render)}
    </Layout>
  )
}
