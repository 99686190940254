import React from 'react'
import { srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ reviews }) => {
  return (
    <div className={styles.reviews}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{reviews.title}</h2>
        <div className={styles.items}>
          {reviews.items &&
            reviews.items.map(review => (
              <div className={styles.item} key={reviews._key}>
                <img
                  {...srcSetProps(sanityImageUrl(review.imageLogo))}
                  className={styles.logo}
                  alt={review.imageLogo?.caption}
                />
                <a
                  className={styles.link}
                  href={review.linkUrl}
                  target="_blank"
                  dangerouslySetInnerHTML={{ __html: review.linkText }}
                />
                <img
                  {...srcSetProps(sanityImageUrl(review.imageContent))}
                  className={styles.content}
                  alt={review.imageContent?.caption}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
