import React from 'react'
import { srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import Slider from 'react-slick'
import styles from './style.module.sass'

export default ({ doctors }) => {
  const sliderSettings = {
    dots: false,
    autoplay: false,
    slidesToShow: 1.6,
    infinite: false,
    responsive: [
      {
        breakpoint: 700,
      },
      {
        breakpoint: 10000,
        settings: 'unslick',
      },
    ],
  }

  return (
    <div className={styles.doctors}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{doctors.title}</h2>
        <Slider {...sliderSettings}>
          {doctors.items &&
            doctors.items.map(item => (
              <div className={styles.item} key={item._key}>
                <div className={styles.top}>
                  <img {...srcSetProps(sanityImageUrl(item.image))} className={styles.image} />
                  <p className={styles.name}>{item.name}</p>
                  <p className={styles.position}>{item.position}</p>
                </div>
                <div className={styles.bottom}>
                  <a href={item.podcastUrl} className={styles.link} target="_blank" rel="noreferrer">
                    Maximus Podcast
                  </a>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </div>
  )
}
