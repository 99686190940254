import React from 'react'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../../utils/helpers'
import styles from './style.module.sass'

export default ({ author }) => {
  return (
    <div className={styles.author}>
      <div className={styles.wrap}>
        <div className={styles.left}>
          <h2 className={styles.title}>{author.title}</h2>
          <p className={styles.description}>{prepareParagraph(author.description)}</p>
        </div>
        <div className={styles.right}>
          <img {...srcSetProps(sanityImageUrl(author.image))} alt={author.image?.caption} className={styles.image} />
          <p className={styles.placeholder}>{author.placeholder}</p>
        </div>
      </div>
    </div>
  )
}
