import React, { useEffect } from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl, clickTrack } from '../../../utils/helpers'
import { Link } from 'gatsby'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ product }) => {
  if (!product || product.isHide) return null

  return (
    <div className={styles.product}>
      <div className={styles.image}>
        <img className={styles.imageSrc} {...srcSetProps(sanityImageUrl(product.image))} alt={product.image?.caption} />
      </div>
      <div className={styles.wrap}>
        <div className={styles.text}>
          <p className={styles.titleTop}>{product.titleTop}</p>
          <h2 className={styles.title}>{product.title}</h2>
          <div className={styles.group}>
            <p className={styles.titleBottom}>{product.titleBottom}</p>
            <div className={styles.btns}>
              {product.buttonText1 && (
                <a href={product.buttonUrl1} onClick={urlWithSearchParamsHandler} className={styles.btnsStarted}>
                  {product.buttonText1}
                </a>
              )}
              {product.buttonText2 && (
                <Link to={product.buttonUrl2} className={styles.btnsMore}>{product.buttonText2}</Link>
              )}
            </div>
            <ul className={styles.list}>
              {product.benefits && product.benefits.map((item, index) => <li key={index}>✓ {item}</li>)}
            </ul> 
          </div>                  
        </div>
        <div className={styles.mobile}>
          <img className={styles.mobileImage} {...srcSetProps(sanityImageUrl(product.imageMobile))} alt={product.imageMobile?.caption} />
        </div>
      </div>    
    </div>
  )
}
