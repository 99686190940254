import React from 'react'
import { urlWithSearchParamsHandler, prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import { PopupButton } from '@typeform/embed-react'
import styles from './style.module.sass'

export default ({ header }) => {
  if (!header) return null

  return (
    <div className={styles.header}>
      <div className={styles.wrap}>
        <div className={styles.text}>
          <p className={styles.join}>{prepareParagraph(header.join)}</p>
          <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: header.title }} />
          <ul className={styles.list}>
            {header.benefits &&
              header.benefits.map((benefit, index) => (
                <li className={styles.listItem} key={index}>
                  {prepareParagraph(benefit)}
                </li>
              ))}
          </ul>
          <a href={header.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>
            {header.buttonText}
          </a>
          <p className={styles.consultation}>{header.consultation}</p>
        </div>
        <div className={styles.image}>
          <img src={sanityImageUrl(header.image)} alt={header.image?.caption} className={styles.imageSrc} />
        </div>
      </div>
    </div>
  )
}
