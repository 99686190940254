import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import { sortComponents } from '../utils/helpers'
import ProductSchema from '../components/Shared/Schema/Product'
import FaqSchema from '../components/Shared/Schema/Faq'
import Info from '../components/KingV2MultiplePage/Info'
import Science from '../components/KingV2MultiplePage/Science'
import Table from '../components/KingV2MultiplePage/Table'
import Testimonials from '../components/KingV2MultiplePage/Testimonials'
import HowItWorks from '../components/KingV2MultiplePage/HowItWorks'
import Benefits from '../components/KingV2MultiplePage/Benefits'
import BuildingBlocks from '../components/KingV2MultiplePage/BuildingBlocks'
import Reviews from '../components/KingV2MultiplePage/Reviews'
import Faq from '../components/KingV2MultiplePage/Faq'
import References from '../components/KingV2MultiplePage/References'
import Wim from '../components/KingV2MultiplePage/Wim'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Science science={data.science} />,
      priority: data.science?.priority || 99,
    },
    {
      render: <Table table={data.table} />,
      priority: data.table?.priority || 99,
    },
    {
      render: <Testimonials testimonials={data.testimonials} />,
      priority: data.testimonials?.priority || 99,
    },
    {
      render: <HowItWorks howItWorks={data.howItWorks} />,
      priority: data.howItWorks?.priority || 99,
    },
    {
      render: <Benefits benefits={data.benefits} />,
      priority: data.benefits?.priority || 99,
    },
    {
      render: <BuildingBlocks buildingBlocks={data.bb} />,
      priority: data.bb?.priority || 99,
    },
    {
      render: <Reviews reviews={data.reviews} />,
      priority: data.reviews?.priority || 99,
    },
    {
      render: <Faq faq={data.faq} />,
      priority: data.faq?.priority || 99,
    },
    {
      render: <References references={data.references} />,
      priority: data.references?.priority || 99,
    },
    {
      render: <Wim wim={data.wim} />,
      priority: data.wim?.priority || 99,
    },
  ].sort(sortComponents)

  return (
    <Layout data={data} signUpUrl="https://app.maximustribe.com/registration" signUpText="Get Started">
      <SEO {...data.seo} />
      <ProductSchema {...data.productSchema} />
      {data?.faq?.items && <FaqSchema questions={data.faq.items} />}
      <Info info={data.info} />
      {components.map(component => component.render)}
    </Layout>
  )
}
