import React from 'react'
import { sanityImageUrl } from '../../../utils/helpers'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ articles }) => {
  if (!articles || articles.isHide) return null

  return (
    <div className={styles.articles}>
      {articles.items && articles.items.map(article => (
        <>
          {article.url ? (
            <div href={article.url} target="_blank" className={styles.block} key={articles._key}>
              <img className={styles.blockBg} src={sanityImageUrl(article.image)} alt={article.image?.caption} />
              <a href={article.url} target="_blank" className={cx(styles.content, styles.contentLink)}>
                <img className={styles.logo} src={sanityImageUrl(article.logo)} alt={article.logo?.caption} />
                <p className={styles.text}>{article.text}</p>
                <p className={styles.date}>{article.date}</p>
              </a>
            </div>
          ) : (
            <div className={styles.block} key={articles._key}>
              <img className={styles.blockBg} src={sanityImageUrl(article.image)} alt={article.image?.caption} />
              <div className={styles.content}>
                <img className={styles.logo} src={sanityImageUrl(article.logo)} alt={article.logo?.caption} />
                <p className={styles.text}>{article.text}</p>
                <p className={styles.date}>{article.date}</p>
              </div>
            </div>
          )}
        </>
      ))}
    </div>
  )

  return (
    <div className={styles.articles}>
      {articles.items && articles.items.map(article => (    
        <div className={styles.block} key={articles._key}>
          <img className={styles.blockBg} src={sanityImageUrl(article.image)} alt={article.image?.caption} />
          {article.url ? (
            <a href={article.url} target="_blank" className={cx(styles.content, styles.contentLink)}>
              <img className={styles.logo} src={sanityImageUrl(article.logo)} alt={article.logo?.caption} />
              <p className={styles.text}>{article.text}</p>
              <p className={styles.date}>{article.date}</p>
            </a>                
          ) : (
            <div className={styles.content}>
              <img className={styles.logo} src={sanityImageUrl(article.logo)} alt={article.logo?.caption} />
              <p className={styles.text}>{article.text}</p>
              <p className={styles.date}>{article.date}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}