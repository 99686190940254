import React from 'react'
import { Link } from 'gatsby'
import { prepareParagraph } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ masculinity }) => {
  if (!masculinity || masculinity.isHide) return null

  return (
    <div className={styles.masculinity}>
      <div className={styles.wrap}>
        <div className={styles.text}>
          <h2 className={styles.title}>{masculinity.title}</h2>
          <p className={styles.description}>{prepareParagraph(masculinity.text)}</p>
          <Link className={styles.button} to={masculinity.button1Url}>{masculinity.button1Title}</Link>
        </div>
      </div>
    </div>
  )
}
