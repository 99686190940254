import React from 'react'
import { Link } from 'gatsby'
import { titleToId, prepareParagraph } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ studies }) => {
  if (!studies || studies.isHide) return null

  return (
    <div className={styles.studies}>
      <span className={styles.anchor} id={titleToId(studies.title)}/>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{studies.title}</h2>
        <p className={styles.description}>{studies.description}</p>

        {studies.tables && studies.tables.map(table => (
          <div className={styles.table} key={table._key}>
            <p className={styles.name}>{table.title}</p>
            <div className={styles.header}>
              <div className={styles.cell}>{table.title_column_1}</div>
              <div className={styles.cell}>{table.title_column_2}</div>
              <div className={styles.cell}>{table.title_column_3}</div>
            </div>

            {table.rows && table.rows.map(row => (
              <div className={styles.row} key={row._key}>
                <div className={styles.cell}>{row.column_1}</div>
                <div className={styles.cell}>{row.column_2}</div>
                <div className={styles.cell}>{prepareParagraph(row.column_3)}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
