import React from 'react'
import { urlWithSearchParamsHandler, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ benefits2 }) => {
  if (!benefits2 || benefits2.isHide) return null

  return (
    <div className={styles.benefits}>
      <div className={styles.wrap}>
        <div className={styles.image}>
          <img className={styles.imageSrc} src={sanityImageUrl(benefits2.image)} alt={benefits2.image?.caption} />
        </div>
        <div className={styles.text}>
          <h2 className={styles.title}>{benefits2.title}</h2>
          <ul className={styles.list}>
            {benefits2.benefits && benefits2.benefits.map((item, index) => <li key={index}>{item}</li>)}
          </ul>
          <a href={benefits2.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>{benefits2.buttonText}</a>          
        </div>
      </div>
    </div>
  )
}