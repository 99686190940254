import React from 'react'
import { srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ header }) => {
  if (!header) return null

  return (
    <div className={styles.header}>
      <div className={styles.wrap}>
        <div className={styles.text}>
          <h1 className={styles.title}>{header.title}</h1>
          <p className={styles.description}>{header.description}</p>
        </div>
      </div>
      <img className={styles.image} {...srcSetProps(sanityImageUrl(header.image))} alt={header.image?.caption} />
      <img className={styles.imageMobile} {...srcSetProps(sanityImageUrl(header.imageMobile))} alt={header.imageMobile?.caption} />
    </div>
  )
}
