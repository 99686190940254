import React from 'react'
import { prepareParagraph, sanityImageUrl, sanityFileUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ difference }) => {
  if (!difference || difference.isHide) return null

  return (
    <div className={styles.difference}>
      <div className={styles.wrap}>
        <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: difference.title }} />
        <div className={styles.box}>
          <div className={styles.boxItem}>
            <img src={sanityImageUrl(difference.image1)} alt={difference.image1?.caption} className={styles.image} />
            <div className={styles.text}>
              <div className={styles.textLeft}>
                <p className={styles.textTitle}>{difference.title1}</p>
                <p className={styles.textInfo}>{prepareParagraph(difference.description1)}</p>
              </div>
              <div className={styles.textRight}>
                <img
                  src={sanityImageUrl(difference.imageTitle1)}
                  alt={difference.imageTitle1?.caption}
                  className={styles.textImage}
                />
              </div>
            </div>
          </div>
          <div className={styles.boxItem}>
            <img src={sanityImageUrl(difference.image2)} alt={difference.image2?.caption} className={styles.image} />
            <div className={styles.text}>
              <div className={styles.textLeft}>
                <p className={styles.textTitle}>{difference.title2}</p>
                <p className={styles.textInfo}>{prepareParagraph(difference.description2)}</p>
              </div>
              <div className={styles.textRight}>
                <img
                  src={sanityImageUrl(difference.imageTitle2)}
                  alt={difference.imageTitle2?.caption}
                  className={styles.textImage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
