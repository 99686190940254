import React, { useState, useEffect } from 'react'
import { urlWithSearchParamsHandler, sanityImageUrl, prepareParagraph } from '../../../utils/helpers'
import { Link } from 'gatsby'
import { gsap } from 'gsap'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ ingredients }) => {
  if (!ingredients || ingredients.isHide) return null

  const [activeIndex, setActiveIndex] = useState(false)

  const clickHandler = index => {
    const oldEl = document.querySelector(`.${styles.content}[data-index="${activeIndex}"]`)
    const newEl = document.querySelector(`.${styles.content}[data-index="${index}"]`)

    if (activeIndex === index) {
      gsap.to(oldEl, { height: '0', duration: 0.3 })
      setActiveIndex(false)
    } else {
      gsap.to(oldEl, { height: '0', duration: 0.3 })
      gsap.to(newEl, { height: 'auto', duration: 0.3 })
      setActiveIndex(index)
    }
  }

  useEffect(() => {
    clickHandler(0)
  }, [])

  return (
    <div className={styles.ingredients}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>{ingredients.title}</h2>
          {ingredients.buttonText && ingredients.buttonUrl && (
            <a href={ingredients.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.btn}>{ingredients.buttonText}</a>
          )}
        </div>

        {ingredients.description && <p className={styles.description}>{ingredients.description}</p>}

        <div className={styles.items}>
          {ingredients.items &&
            ingredients.items.map((ingredient, index) => (
              <div className={cx(styles.item, {
                [styles.itemShow]: activeIndex === index
              })} key={ingredient._key}>
                <p className={cx(styles.name, styles.nameMobile)} onClick={() => clickHandler(index)}>{ingredient.title}</p>

                <div className={styles.content} data-index={index}>
                  {ingredient.image && (
                    <img src={sanityImageUrl(ingredient.image)} className={styles.image} alt={ingredient.image?.caption} />
                  )}

                  <p className={styles.name}>{ingredient.title}</p>
                  {ingredient.info && <p className={styles.info}>{ingredient.info}</p>}

                  {ingredient.faq && (
                    <div className={styles.faq}>
                      {ingredient.faq.map(faq => (
                        <div key={faq._key}>
                          <p className={styles.faqQuestion}>{prepareParagraph(faq.question)}</p>
                          <p className={styles.faqAnswer}>{prepareParagraph(faq.answer)}</p>
                        </div>
                      ))}
                    </div>
                  )}

                  {ingredient.buttonText && ingredient.buttonUrl && (
                    <div className={styles.link}>
                      <Link to={ingredient.buttonUrl} className={styles.linkSrc}>
                        {ingredient.buttonText}
                      </Link>                    
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
