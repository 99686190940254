import React, { useEffect, useState } from 'react'
import { timeSince } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ positions }) => {
  if (!positions || positions.isHide) return null

  const [jobs, setJobs] = useState([])

  useEffect(() => {
    fetch('https://api.ashbyhq.com/posting-api/job-board/maximustribe')
      .then(function (response) {
        if (response.ok) {
          return response.json()
        }
        return Promise.reject(response)
      })
      .then(data => setJobs(data.jobs))
      .catch(error => console.warn(error))    
  }, [])

  return (
    <div className={styles.positions}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{positions.title}</h2>
        <div className={styles.items}>
          {jobs && jobs.map(item => (
            <div className={styles.item} key={item.id}>
              <div className={styles.top}>
                <p className={styles.name}>{item.title}</p>
                <p className={styles.location}>{item.location}</p>
              </div>
              <div className={styles.bottom}>
                {item.publishedAt && (
                  <p className={styles.date}>{timeSince(new Date(item.publishedAt))}</p>
                )}
                <a href={`${item.jobUrl}?utm_source=6v9eonv2q1`} className={styles.link} target="_blank"/>
              </div>
            </div>
          ))}
        </div>        
      </div>
    </div>
  )
}