import React, { useEffect } from 'react'
import cx from 'classnames'
import { prepareParagraph } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ references }) => {
  if (references.isHide) return null

  useEffect(() => {
    const autorefLinks = document.getElementsByClassName('autoref')
    Array.from(autorefLinks).forEach((item, index) => {
      item.href = item.href.replace('[AUTOREF_COUNT]', index + 1)
      item.innerText = index + 1
    })
  }, [])

  return (
    <div className={styles.references}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{references.title}</h2>

        <div className={styles.table}>
          {references.items &&
            references.items.map((reference, index) => (
              <div className={styles.row} key={reference._key} id={`reference_${index + 1}`}>
                <div className={styles.c1}>{index + 1}</div>
                <div className={styles.c2}>{reference.text}</div>
                <div className={styles.c3}>
                  <ul>
                    {reference.sources &&
                      reference.sources.map(source => (
                        <li key={source._key}>
                          <a href={source.url} target="_blank">
                            {source.title}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            ))}
        </div>

        <p className={styles.foodAndDrug}>{prepareParagraph(references.foodAndDrug)}</p>
      </div>
    </div>
  )
}
