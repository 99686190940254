import React, { useState, useMemo } from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import { formAccessHandler, sanityImageUrl, urlWithSearchParamsHandler, prepareParagraph } from '../../../utils/helpers'
import { states, statesAvailable, statesNotServe } from './states'
import { isValidForEp02, isValidForOralTrtPlus, isValidForOralTrt, getRecommendation } from './functions'
import styles from './style.module.sass'

const STEP_2 = [
  { value: 'boost', text: 'Boost your body\'s natural testosterone production' },
  { value: 'exogenous', text: 'Add exogenous (external bio-identical) testosterone as a supplement' },
  { value: 'combination', text: 'Combination treatment' }
]

const STEP_3 = [
  { value: 'yes', text: 'Preserve fertility' }, { value: 'no', text: 'Fertility not important' }
]

export default ({ custom, products }) => {
  if (!products || products.isHide || !custom?.filterVersion) return null

  const [selectActive1, setSelectActive1] = useState(false)
  const [selectActive2, setSelectActive2] = useState(false)
  const [selectActive3, setSelectActive3] = useState(false)
  const [question1, setQuestion1] = useState(undefined)
  const [question2, setQuestion2] = useState(undefined)
  const [question3, setQuestion3] = useState(undefined)
  const [submit, setSubmit] = useState(false)
  const [loading, setLoading] = useState(false)

  const selectHandler = (e, func, value) => {
    e.stopPropagation()
    selectsClose()
    func(!value)
  }

  const selectsClose = () => {
    setSelectActive1(false)
    setSelectActive2(false)
    setSelectActive3(false)
  }

  const selectOptionHandler = (e, func, selected, value) => {
    e.stopPropagation()

    if (selected === value) {
      func(undefined)
    } else {
      func(value)
    }
  }

  const validForEp02 = useMemo(() => {
    return isValidForEp02(question1, question2, question3)
  }, [question1, question2, question3])

  const validForOralTrtPlus = useMemo(() => {
    return isValidForOralTrtPlus(question1, question2, question3)
  }, [question1, question2, question3])

  const validForOralTrt = useMemo(() => {
    return isValidForOralTrt(question1, question2, question3)
  }, [question1, question2, question3])

  return (
    <div className={styles.quiz} onClick={selectsClose}>
      <div className={styles.wrap}>
        <h1 className={styles.title} dangerouslySetInnerHTML={{__html: custom.filterTitle}}/>
        <p className={styles.info}>Select Filters:</p>
        <div className={styles.down} />
        <div className={styles.selects}>
          <div className={cx(styles.select, {
            [styles.selectActive]: selectActive1
          })} onClick={(e) => selectHandler(e, setSelectActive1, selectActive1)}>
            <p className={styles.selectTitle}>{!selectActive1 && question1 ? question1 : 'Select State'} <span /></p>
            <ul className={styles.selectList}>
              {states.map((item, index) => (
                <li 
                  className={cx(styles.selectItem, {
                    [styles.selectItemActive]: item === question1
                  })}
                  key={index}
                  onClick={(e) => selectOptionHandler(e, setQuestion1, question1, item)}
                >{item}</li>
              ))}
            </ul>
          </div>

          <div className={cx(styles.select, {
            [styles.selectActive]: selectActive2
          })} onClick={(e) => selectHandler(e, setSelectActive2, selectActive2)}>
            <p className={styles.selectTitle}>{!selectActive2 && question2 ? STEP_2.find(i => i.value === question2).text : 'Select Testosterone Type'} <span /></p>
            <ul className={styles.selectList}>
              {STEP_2.map((item, index) => (
                <li 
                  className={cx(styles.selectItem, {
                    [styles.selectItemActive]: item.value === question2
                  })}
                  key={index}
                  onClick={(e) => selectOptionHandler(e, setQuestion2, question2, item.value)}
                >{item.text}</li>
              ))}
            </ul>
          </div>

          <div className={cx(styles.select, {
            [styles.selectActive]: selectActive3
          })} onClick={(e) => selectHandler(e, setSelectActive3, selectActive3)}>
            <p className={styles.selectTitle}>{!selectActive3 && question3 ? STEP_3.find(i => i.value === question3).text : 'Select Fertility Consideration'} <span /></p>
            <ul className={styles.selectList}>
              {STEP_3.map((item, index) => (
                <li 
                  className={cx(styles.selectItem, {
                    [styles.selectItemActive]: item.value === question3
                  })}
                  key={index}
                  onClick={(e) => selectOptionHandler(e, setQuestion3, question3, item.value)}
                >{item.text}</li>
              ))}
            </ul>
          </div>                    
        </div>

        <div className={styles.finish}>
          {validForEp02 || validForOralTrtPlus || validForOralTrt ? (
            <>
              <h2 className={styles.finishTitle}>Your Recommended Product(s)</h2>
              <p className={styles.finishDesc}>{getRecommendation(validForEp02, validForOralTrtPlus, validForOralTrt, question1, question2, question3)}</p>

              <div className={cx(styles.products, {
                [styles.productsEp02]: validForEp02,
                [styles.productsOralTrtPlus]: validForOralTrtPlus,
                [styles.productsOralTrt]: validForOralTrt
              })}>
                <div className={styles.desktop}>
                  <div className={styles.items}>
                    {products.items &&
                      products.items.map(product => (
                        <div className={styles.item} key={product._key}>
                          <Link to={product.rating.url} className={styles.imageBox}>
                            {product.top && <p className={styles.top}>{product.top}</p>}
                            <img src={sanityImageUrl(product.image)} className={styles.image} alt={product.image?.caption} />
                            {product.isPrescription && <p className={styles.tag}>Prescription</p>}
                          </Link>
                          {product.rating?.url && (
                            <Link className={styles.rating} to={product.rating.url}>
                              {product.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(product.rating.starsImage)} alt={product.rating?.text}/>}
                              {product.rating.text && <p>{product.rating.text}</p>}
                            </Link>
                          )}
                          <p className={styles.name}>{product.title}</p>
                        </div>
                      ))}
                  </div>

                  <div className={styles.items}>
                    {products.items &&
                      products.items.map(product => (
                        <div className={styles.item} key={product._key}>
                          <div className={styles.included}>
                            {product.included &&
                              product.included.map((item, index) => (
                                <p className={styles.includedItem} key={index}>
                                  {item}
                                </p>
                              ))}
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className={styles.items}>
                    {products.items &&
                      products.items.map(product => (
                        <div className={styles.item} key={product._key}>
                          <p className={styles.description}>{product.Description}</p>
                        </div>
                      ))}
                  </div>

                  <div className={styles.items}>
                    {products.items &&
                      products.items.map(product => (
                        <div className={styles.item} key={product._key}>
                          <div className={cx(styles.values, {
                            [styles.valuesOne]: !product.totalTestosteroneText || !product.freeTestosteroneText
                          })}>
                            {product.totalTestosteroneText && (
                              <div className={styles.total}>
                                <p className={styles.valuesTitle}>{product.totalTestosteroneText}</p>
                                <p className={styles.valuesResult}>
                                  {product.totalArrow && product.totalArrow === 'up' && <span className={styles.arrowUp} />}
                                  {product.totalArrow && product.totalArrow === 'down' && <span className={styles.arrowDown} />}
                                  {product.totalTestosteroneValue}
                                </p>
                              </div>
                            )}
                            {product.freeTestosteroneText && (
                              <div className={styles.free}>
                                <p className={styles.valuesTitle}>{product.freeTestosteroneText}</p>
                                <p className={styles.valuesResult}>
                                  {product.freeArrow && product.freeArrow === 'up' && <span className={styles.arrowUp} />}
                                  {product.freeArrow && product.freeArrow === 'down' && <span className={styles.arrowDown} />}
                                  {product.freeTestosteroneValue}
                                </p>
                              </div>
                            )}
                          </div>
                          <p className={styles.price} dangerouslySetInnerHTML={{ __html: product.price }} />
                          <div className={styles.buttons}>
                            <a
                              href={product.getStartedButtonUrl}
                              onClick={urlWithSearchParamsHandler}
                              className={styles.buttonsStarted}
                            >
                              {product.getStartedButtonText}
                            </a>
                            <Link className={styles.buttonsMore} to={product.learnMoreButtonUrl}>
                              {product.learnMoreButtonText}
                            </Link>
                          </div>
                          <div className={styles.benefits}>
                            {product.benefits &&
                              product.benefits.map((benefit, index) => (
                                <p className={styles.benefitsItem} key={index}>
                                  {prepareParagraph(benefit)}
                                </p>
                              ))}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className={styles.mobile}>
                  <div className={styles.items}>
                    {products.items &&
                      products.items.map(product => (
                        <div className={styles.item} key={product._key}>
                          <Link to={product.rating.url} className={styles.imageBox}>
                            {product.top && <p className={styles.top}>{product.top}</p>}
                            <img src={sanityImageUrl(product.image)} className={styles.image} alt={product.image?.caption} />
                            {product.isPrescription && <p className={styles.tag}>Prescription</p>}
                          </Link>
                          {product.rating?.url && (
                            <Link className={styles.rating} to={product.rating.url}>
                              {product.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(product.rating.starsImage)} alt={product.rating?.text}/>}
                              {product.rating.text && <p>{product.rating.text}</p>}
                            </Link>
                          )}                 
                          <p className={styles.name}>{product.title}</p>
                          <div className={styles.included}>
                            {product.included &&
                              product.included.map((item, index) => (
                                <p className={styles.includedItem} key={index}>
                                  {item}
                                </p>
                              ))}
                          </div>
                          <p className={styles.description}>{product.Description}</p>
                          <div className={cx(styles.values, {
                            [styles.valuesOne]: !product.totalTestosteroneText || !product.freeTestosteroneText
                          })}>
                            {product.totalTestosteroneText && (
                              <div className={styles.total}>
                                <p className={styles.valuesTitle}>{product.totalTestosteroneText}</p>
                                <p className={styles.valuesResult}>
                                  {product.totalArrow && product.totalArrow === 'up' && <span className={styles.arrowUp} />}
                                  {product.totalArrow && product.totalArrow === 'down' && <span className={styles.arrowDown} />}
                                  {product.totalTestosteroneValue}
                                </p>
                              </div>
                            )}
                            {product.freeTestosteroneText && (
                              <div className={styles.free}>
                                <p className={styles.valuesTitle}>{product.freeTestosteroneText}</p>
                                <p className={styles.valuesResult}>
                                  {product.freeArrow && product.freeArrow === 'up' && <span className={styles.arrowUp} />}
                                  {product.freeArrow && product.freeArrow === 'down' && <span className={styles.arrowDown} />}
                                  {product.freeTestosteroneValue}
                                </p>
                              </div>
                            )}
                          </div>
                          <p className={styles.price} dangerouslySetInnerHTML={{ __html: product.price }} />
                          <div className={styles.buttons}>
                            <a
                              href={product.getStartedButtonUrl}
                              onClick={urlWithSearchParamsHandler}
                              className={styles.buttonsStarted}
                            >
                              {product.getStartedButtonText}
                            </a>
                            <Link className={styles.buttonsMore} to={product.learnMoreButtonUrl}>
                              {product.learnMoreButtonText}
                            </Link>
                          </div>
                          <div className={styles.benefits}>
                            {product.benefits &&
                              product.benefits.map((benefit, index) => (
                                <p className={styles.benefitsItem} key={index}>
                                  {prepareParagraph(benefit)}
                                </p>
                              ))}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <h2 className={styles.finishTitle}>Unfortunately Maximus is not yet available in your area.</h2>
              <p className={styles.finishDesc}> Please enter your email address if you'd like to be informed when we expand to serve additional states</p>
              {submit ? (
                <p className={styles.submitted}>Thanks! We'll be in touch</p>
              ) : (
                <form 
                  className={cx(styles.form, {
                    [styles.formLoading]: loading,
                  })} 
                  onSubmit={(e) => formAccessHandler(e, loading, setLoading, setSubmit)}
                >
                  <input type="emails" name="email" className={styles.formEmail} placeholder="Enter your email"/>
                  <input type="submit" value="Submit" className={styles.formSubmit}/>
                </form>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}