import React from 'react'
import cx from 'classnames'
import { prepareParagraph, titleToId } from '/src/utils/helpers'
import styles from './style.module.sass'

export default ({ references }) => {
  return (
    <div className={styles.references}>
      <div className={styles.anchor} id={titleToId(references.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{references.title}</h2>
      <div className={styles.content}>
        {references.items &&
          references.items.map((reference, index) => (
            <div className={styles.row} key={reference._key} id={`reference_${index + 1}`}>
              <div className={styles.c1}>[{index + 1}]</div>
              <div className={styles.c2}>
                {reference.title}{' '}
                <a href={reference.url} target="_blank">
                  {reference.url}
                </a>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
