import React, { useEffect } from 'react'
import { gsap } from 'gsap'
import { titleToId } from '../../../utils/helpers'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import styles from './style.module.sass'

export default ({ menu }) => {
  if (!menu || menu.isHide) return null

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin)
  }, [])

  const scrollTo = id => {
    gsap.to(window, {
      duration: 2,
      scrollTo: document.getElementById(id)
    })
  }

  return (
    <div className={styles.menu}>
      <div className={styles.wrap}>
        {menu.items && menu.items.map(item => (
          <div className={styles.item} key={item._key}>
            <p className={styles.title}>{item.title}</p>
            <ul className={styles.list}>
              {item.benefits && item.benefits.map((benefit, index) => (
                <li className={styles.benefit} key={index} onClick={() => scrollTo(titleToId(benefit))}>{benefit}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}
