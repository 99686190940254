import React, { useState, useMemo } from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import imageEp02 from '../../../images/bg-quiz-ep-02.jpg'
import imageOralTrtPlus from '../../../images/bg-quiz-oral-trt-plus.jpg'
import imageOralTrt from '../../../images/bg-quiz-oral-trt.jpg'
import { formAccessHandler } from '../../../utils/helpers'
import { states, statesAvailable, statesNotServe } from './states'
import { isValidForEp02, isValidForOralTrtPlus, isValidForOralTrt, getRecommendation } from './functions'
import styles from './style.module.sass'

const STEP_2 = [
  { value: 'both', text: 'Open to both' },
  { value: 'exogenous', text: 'Only interested in exogenous' },
  { value: 'natural', text: 'Only interested in boosting natural production' },
  { value: 'not_sure', text: 'Not sure' }
]

const STEP_3 = [
  { value: 'yes', text: 'Yes' }, { value: 'no', text: 'No' }
]

export default () => {
  const [step, setStep] = useState(1)
  const [results, setResults] = useState(false)
  const [selectActive, setSelectActive] = useState(false)
  const [question1, setQuestion1] = useState(undefined)
  const [question2, setQuestion2] = useState(undefined)
  const [question3, setQuestion3] = useState(undefined)
  const [submit, setSubmit] = useState(false)
  const [loading, setLoading] = useState(false)

  const nextStep2 = (value) => {
    setQuestion1(value)
    setStep(2)
  }

  const nextStep3 = (value) => {
    setQuestion2(value)
    setStep(3)
  }

  const showResults = (value) => {
    setQuestion3(value)
    setResults(true)
  }

  const startAgain = () => {
    setQuestion1(undefined)
    setQuestion2(undefined)
    setQuestion3(undefined)
    setSelectActive(false)
    setResults(false)
    setSubmit(false)
    setStep(1)
  }

  const validForEp02 = useMemo(() => {
    return isValidForEp02(question1, question2, question3)
  }, [question1, question2, question3])

  const validForOralTrtPlus = useMemo(() => {
    return isValidForOralTrtPlus(question1, question2, question3)
  }, [question1, question2, question3])

  const validForOralTrt = useMemo(() => {
    return isValidForOralTrt(question1, question2, question3)
  }, [question1, question2, question3])

  return (
    <div className={styles.quiz}>
      <div className={styles.wrap}>
        <h1 className={styles.title}>Product Finder</h1>

        {!results ? (
          <div className={styles.number}>{step}/3</div>
        ) : (
          <div className={styles.restart} onClick={startAgain}>Start Over <span /></div>
        )}

        <div className={styles.lines}>
          <div className={cx(styles.linesItem, {
            [styles.linesItemActive]: step === 1
          })}/>
          <div className={cx(styles.linesItem, {
            [styles.linesItemActive]: step === 2
          })}/>
          <div className={cx(styles.linesItem, {
            [styles.linesItemActive]: step === 3
          })}/>
        </div>

        {!results ? (
          <div className={styles.contents}>
            <div className={cx(styles.contentsItem, {
              [styles.contentsItemActive]: step === 1
            })}>
              <h2 className={styles.contentsTitle}>Please select your state from the dropdown menu.</h2>
              <div className={cx(styles.select, {
                [styles.selectActive]: selectActive
              })} onClick={() => setSelectActive(true)}>
                <p className={styles.selectTitle}>Select State <span /></p>
                <ul className={styles.selectList}>
                  {states.map((item, index) => (
                    <li 
                      className={cx(styles.selectItem, {
                        [styles.selectItemActive]: item === question1
                      })}
                      key={index}
                      onClick={() => nextStep2(item)}
                    >{item}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className={cx(styles.contentsItem, {
              [styles.contentsItemActive]: step === 2
            })}>
              <h2 className={cx(styles.contentsTitle, styles.contentsTitleMax)}>Would you prefer to boost your body's natural testosterone production or are you open to supplementing with exogenous bio-identical testosterone?</h2>
              <div className={styles.radio}>
                {STEP_2.map((item, index) => (
                  <div 
                    className={styles.radioItem} 
                    key={index} 
                    onClick={() => nextStep3(item.value)}
                  >{item.text}</div>
                ))}
              </div>
            </div>
            <div className={cx(styles.contentsItem, {
              [styles.contentsItemActive]: step === 3
            })}>
              <h2 className={styles.contentsTitle}>Are you concerned with maintaining fertility?</h2>
              <div className={styles.radio}>
                {STEP_3.map((item, index) => (
                  <div 
                    className={styles.radioItem} 
                    key={index} 
                    onClick={() => showResults(item.value)}
                  >{item.text}</div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.finish}>
            {validForEp02 || validForOralTrtPlus || validForOralTrt ? (
              <>
                <h2 className={styles.finishTitle}>Your Recommended Product(s)</h2>
                <p className={styles.finishDesc}>{getRecommendation(validForEp02, validForOralTrtPlus, validForOralTrt, question1, question2, question3)}</p>

                <div className={cx(styles.products, {
                  [styles.productsEp02]: validForEp02,
                  [styles.productsOralTrtPlus]: validForOralTrtPlus,
                  [styles.productsOralTrt]: validForOralTrt
                })}>
                  <div className={styles.desktop}>
                    <div className={styles.row}>
                      <div className={styles.product}>
                        <img src={imageEp02} alt="EP-02" className={styles.productImage}/>
                      </div>
                      <div className={styles.product}>
                        <img src={imageOralTrtPlus} alt="Oral TRT+" className={styles.productImage}/>
                      </div>
                      <div className={styles.product}>
                        <img src={imageOralTrt} alt="Oral TRT" className={styles.productImage}/>
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.product}>
                        <p className={styles.productName}>EP-02</p>
                      </div>
                      <div className={styles.product}>
                        <p className={styles.productName}>Oral TRT+</p>
                      </div>
                      <div className={styles.product}>
                        <p className={styles.productName}>Oral TRT</p>
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.product}>
                        <p className={styles.productInfo}>The best place to start with testosterone optimization</p>
                      </div>
                      <div className={styles.product}>
                        <p className={styles.productInfo}>The powerhouse of testosterone replacement therapy</p>
                      </div>
                      <div className={styles.product}>
                        <p className={styles.productInfo}>The best alternative to traditional testosterone replacement therapy</p>
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.product}>
                        <ul className={styles.productBenefits}>
                          <li>Enclomiphene</li>
                          <li>Pregnenolone</li>
                        </ul>
                      </div>
                      <div className={styles.product}>
                        <ul className={styles.productBenefits}>
                          <li>Native Testosterone</li>
                          <li>Enclomiphene</li>
                          <li>Pregnenolone</li>
                        </ul>
                      </div>
                      <div className={styles.product}>
                        <ul className={styles.productBenefits}>
                          <li>Native Testosterone</li>
                        </ul>
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.product}>
                        <p className={styles.productText}>Improve testosterone levels without the use of exogenous testosterone. EP-02 is an easy and reliable way to boost your own testicular function. Pregnenolone elevates mood and increases energy levels.</p>
                      </div>
                      <div className={styles.product}>
                        <p className={styles.productText}>Significantly increase your testosterone levels by combining oral testosterone and enclomiphene. Enclomiphene allows for the maintenance of fertility markers and testicular function, making this the perfect option for those seeking maximal testosterone levels.</p>
                      </div>
                      <div className={styles.product}>
                        <p className={styles.productText}>Native testosterone is enveloped in a lipid matrix to allow for lymphatic absorption, bypassing the liver’s first-pass metabolism. Oral TRT sets the gold standard for a convenient, effective, and liver-safe way to take TRT.</p>
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.product}>
                        <Link to={'/testosterone/enclomiphene'} className={styles.productButton}>Learn More</Link>
                      </div>
                      <div className={styles.product}>
                        <Link to={'/testosterone/testosterone-replacement-therapy'} className={styles.productButton}>Learn More</Link>
                      </div>
                      <div className={styles.product}>
                        <Link to={'/testosterone/oral-trt'} className={styles.productButton}>Learn More</Link>
                      </div>
                    </div>                    
                  </div>
                  <div className={styles.mobile}>
                    <div className={styles.product}>
                      <img src={imageEp02} alt="EP-02" className={styles.productImage}/>
                      <p className={styles.productName}>EP-02</p>
                      <p className={styles.productInfo}>The best place to start with testosterone optimization</p>
                      <ul className={styles.productBenefits}>
                        <li>Enclomiphene</li>
                        <li>Pregnenolone</li>
                      </ul>
                      <p className={styles.productText}>Improve testosterone levels without the use of exogenous testosterone. EP-02 is an easy and reliable way to boost your own testicular function. Pregnenolone elevates mood and increases energy levels.</p>
                      <Link to={'/testosterone/enclomiphene'} className={styles.productButton}>Learn More</Link>
                    </div>
                    <div className={styles.product}>
                      <img src={imageOralTrtPlus} alt="Oral TRT+" className={styles.productImage}/>
                      <p className={styles.productName}>Oral TRT+</p>
                      <p className={styles.productInfo}>The powerhouse of testosterone replacement therapy</p>
                      <ul className={styles.productBenefits}>
                        <li>Native Testosterone</li>
                        <li>Enclomiphene</li>
                        <li>Pregnenolone</li>
                      </ul>
                      <p className={styles.productText}>Significantly increase your testosterone levels by combining oral testosterone and enclomiphene. Enclomiphene allows for the maintenance of fertility markers and testicular function, making this the perfect option for those seeking maximal testosterone levels.</p>
                      <Link to={'/testosterone/testosterone-replacement-therapy'} className={styles.productButton}>Learn More</Link>
                    </div>
                    <div className={styles.product}>
                      <img src={imageOralTrt} alt="Oral TRT" className={styles.productImage}/>
                      <p className={styles.productName}>Oral TRT</p>
                      <p className={styles.productInfo}>The best alternative to traditional testosterone replacement therapy</p>
                      <ul className={styles.productBenefits}>
                        <li>Native Testosterone</li>
                      </ul>
                      <p className={styles.productText}>Native testosterone is enveloped in a lipid matrix to allow for lymphatic absorption, bypassing the liver’s first-pass metabolism. Oral TRT sets the gold standard for a convenient, effective, and liver-safe way to take TRT.</p>
                      <Link to={'/testosterone/oral-trt'} className={styles.productButton}>Learn More</Link>
                    </div>                    
                  </div>
                </div>
              </>
            ) : (
              <>
                <h2 className={styles.finishTitle}>Unfortunately Maximus is not yet available in your area.</h2>
                <p className={styles.finishDesc}> Please enter your email address if you'd like to be informed when we expand to serve additional states</p>
                {submit ? (
                  <p className={styles.submitted}>Thanks! We'll be in touch</p>
                ) : (
                  <form 
                    className={cx(styles.form, {
                      [styles.formLoading]: loading,
                    })} 
                    onSubmit={(e) => formAccessHandler(e, loading, setLoading, setSubmit)}
                  >
                    <input type="emails" name="email" className={styles.formEmail} placeholder="Enter your email"/>
                    <input type="submit" value="Submit" className={styles.formSubmit}/>
                  </form>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}