import React from 'react'
import { sanityImageUrl, prepareParagraph, titleToId } from '/src/utils/helpers'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ distribution }) => {
  return (
    <div className={styles.distribution}>
      <div className={styles.anchor} id={titleToId(distribution.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{distribution.title}</h2>

      <div className={styles.content}>
        {distribution.contentLhFsh &&
          distribution.contentLhFsh.map(item => (
            <div className={styles.item} key={item._key}>
              {item._type === 'title' && (
                <div className={styles.info}>
                  <div className={styles.anchor} id={titleToId(item.title)} data-js="scroll-section" />
                  <img src={sanityImageUrl(item.icon)} className={styles.infoImage} alt={item.icon?.caption} />
                  <p className={styles.infoText}>{prepareParagraph(item.title)}</p>
                </div>
              )}

              {item._type === 'paragraph' && (
                <div className={styles.paragraph}>
                  <p className={styles.paragraphTitle}>{prepareParagraph(item.title)}</p>
                  <p className={styles.paragraphText}>{prepareParagraph(item.text)}</p>
                </div>
              )}

              {item._type === 'chart' && (
                <div className={styles.table}>
                  <div className={styles.anchor} id={item.title.replace(' ', '_').toLowerCase()} />
                  <p className={styles.tableTitle}>{item.title}</p>
                  <img src={sanityImageUrl(item.image)} className={styles.tableImage} alt={item.image?.caption} />
                  {item.info && <p className={styles.tableInfo}>{prepareParagraph(item.info)}</p>}
                </div>
              )}
            </div>
          ))}

        {distribution.contentBaselines &&
          distribution.contentBaselines.map(item => (
            <div className={styles.item} key={item._key}>
              {item._type === 'section' && (
                <div className={styles.section}>
                  <div className={styles.anchor} id={titleToId(item.title)} data-js="scroll-section" />
                  <p className={cx(styles.title, styles.titleDistribution)}>{item.title}</p>
                  {item.items &&
                    item.items.map(section => (
                      <div className={styles.sectionItem} key={section._key}>
                        <p className={styles.sectionTitleText}>{prepareParagraph(section.title)}</p>
                        <img
                          src={sanityImageUrl(section.image)}
                          className={styles.sectionTitleImage}
                          alt={section.image?.caption}
                        />
                      </div>
                    ))}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}
