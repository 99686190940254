import React from 'react'
import { sanityImageUrl, prepareParagraph, titleToId } from '/src/utils/helpers'
import styles from './style.module.sass'

export default ({ material }) => {
  return (
    <div className={styles.material}>
      <div className={styles.anchor} id={titleToId(material.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{material.title}</h2>

      <div className={styles.content}>
        {material.content &&
          material.content.map(item => (
            <div className={styles.item} key={item._key}>
              {item._type === 'paragraph' && <p className={styles.paragraph}>{prepareParagraph(item.paragraph)}</p>}

              {item._type === 'section' && (
                <div className={styles.section}>
                  {item.items &&
                    item.items.map(section => (
                      <div className={styles.sectionItem} key={section._key}>
                        {section._type === 'title' && (
                          <div className={styles.sectionTitle}>
                            <div className={styles.anchor} id={titleToId(section.title)} data-js="scroll-section" />
                            <img
                              src={sanityImageUrl(section.icon)}
                              className={styles.sectionTitleImage}
                              alt={section.icon?.caption}
                            />
                            <p className={styles.sectionTitleText}>{prepareParagraph(section.title)}</p>
                          </div>
                        )}

                        {section._type === 'paragraph' && (
                          <p className={styles.sectionParagraph}>{prepareParagraph(section.paragraph)}</p>
                        )}

                        {section._type === 'list' && (
                          <ul className={styles.sectionList}>
                            {section.list &&
                              section.list.map((list, index) => <li key={index}>{prepareParagraph(list)}</li>)}
                          </ul>
                        )}
                      </div>
                    ))}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}
