import React from 'react'
import { titleToId } from '../../../../utils/helpers'
import styles from './style.module.sass'

export default ({ measures }) => {
  return (
    <div className={styles.measures}>
      <div className={styles.anchor} id={titleToId(measures.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{measures.title}</h2>
      <div className={styles.content}>
        <div className={styles.left}>
          <p className={styles.info}>{measures.info}</p>
          <p className={styles.description}>{measures.description}</p>
        </div>
        <div className={styles.right}>
          <p className={styles.test}>{measures.test}</p>
          <div className={styles.table}>
            {measures.items &&
              measures.items.map(item => (
                <div className={styles.line}>
                  <div className={styles.column}>{item.left}</div>
                  <div className={styles.column}>{item.right}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
