import React from 'react'

import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'

import styles from './style.module.sass'

import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/helpers'

export default ({ latestPodcasts }) => {
  return (
    <Container classes={styles.outer}>
      <Wrapper classes={styles.wrapper}>
        <Item classes={styles.contentContainer}>
          <Title>{latestPodcasts.title}</Title>
          <div className={styles.podcasts}>
            {latestPodcasts.podcasts &&
              latestPodcasts.podcasts.map(podcast => (
                <div className={styles.podcast}>
                  <a key={podcast._key} href={podcast.podcastUrl} target="_blank" className={styles.podcast_link}>
                    <img
                      className={styles.podcast_image}
                      {...srcSetProps(sanityImageUrl(podcast.image))}
                      alt={podcast.image?.caption}
                    />
                    <div className={styles.podcast_title}>{podcast.title}</div>
                    <div className={styles.podcast_description}>{prepareParagraph(podcast.description)}</div>
                    <div className={styles.podcast_info}>{podcast.info}</div>
                  </a>
                </div>
              ))}
          </div>
        </Item>
      </Wrapper>
    </Container>
  )
}
