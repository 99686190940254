import React from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ programs }) => {
  if (!programs || programs.isHide) return null

  return (
    <div className={styles.programs}>
      <div className={styles.wrap}>
        <p className={styles.description}>{programs.description}</p>
        <h2 className={styles.title}>{programs.title}</h2>
        <div className={styles.box}>
          <div className={styles.left}>
            <p className={styles.boxTitle}>{programs.generalTitle}</p>
            <p className={styles.boxDescription}>{programs.generalDescription}</p>
            <ul className={styles.boxList}>
              {programs.generalBenefits &&
                programs.generalBenefits.map((item, index) => (
                  <li className={styles.boxListItem} key={index}>
                    {item}
                  </li>
                ))}
            </ul>
          </div>
          <div className={styles.right}>
            <p className={styles.boxTitle}>{programs.partnerTitle}</p>
            <p className={styles.boxDescription}>{programs.partnerDescription}</p>
            <ul className={styles.boxList}>
              {programs.partnerBenefits &&
                programs.partnerBenefits.map((item, index) => (
                  <li className={styles.boxListItem} key={index}>
                    {item}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
