import React, { useState } from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import { sanityImageUrl } from '/src/utils/helpers'
import styles from './style.module.sass'

export default ({ header, links }) => {
  const [contentOpen, setContentOpen] = useState(false)

  const handlerClick = () => {
    setContentOpen(!contentOpen)
  }

  return (
    <>
      <div
        className={cx(styles.nav, {
          [styles.navOpen]: contentOpen,
        })}
        onClick={handlerClick}
      >
        <div className={styles.navWrap}>
          <div className={styles.handler}></div>
          <ul className={styles.navList}>
            {links.map((item, index) => (
              <li className={styles.navItem} key={index} data-js-id={item.id}>
                <a className={styles.navLink} href={item.link}>
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
          <div className={styles.navTitle}>Table of Contents</div>
        </div>
      </div>

      <div className={styles.header}>
        <div className={styles.wrap}>
          <div className={styles.text}>
            <Link to="/" className={styles.logo} role="navigation" aria-label="Home page" />
            <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: header.title }} />
            <p className={styles.info}>{header.info}</p>
          </div>

          <div className={styles.image}>
            <img src={sanityImageUrl(header.image)} alt={header.image?.caption} />
          </div>
        </div>
      </div>
    </>
  )
}
