import React from 'react'
import cx from 'classnames'
import { prepareParagraph } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ table }) => {
  if (!table || table.isHide) return null

  const getIconByValue = column => {
    if (!column) return null

    if (column.text) {
      return <span className={styles.colText}>{column.text}</span>
    }

    switch (column.mark) {
      case 'plus':
        return <i className={styles.iconPlus} />
      case 'minus':
        return <i className={styles.iconMinus} />
    }
  }

  return (
    <div className={styles.table}>
      <div className={styles.wrap}>
        <h2 className={cx(styles.title, styles.titleMobile)}>{table.title}</h2>
        <table className={styles.tableInside}>
          <thead className={styles.row}>
            <tr>
              <td className={styles.cell}>
                <h2 className={cx(styles.title, styles.titleDesktop)}>{table.title}</h2>
              </td>
              {table.tableCols &&
                table.tableCols.map(col => (
                  <td className={cx(styles.cell, styles.cellAlign)}>
                    {col.isMaximus && <div className={styles.colMaximus} />}
                    <div dangerouslySetInnerHTML={{ __html: col.title }} />
                  </td>
                ))}
            </tr>
          </thead>
          <tbody>
            {table.tableMultiRows &&
              table.tableMultiRows.map(row => (
                <tr className={cx(styles.row, styles.rowLine)} key={row._key}>
                  <td className={styles.cell}>
                    {row.title}
                    <br />
                    <span className={styles.cellSmall}>{prepareParagraph(row.info)}</span>
                  </td>

                  {table.tableCols &&
                    table.tableCols.map((col, index) => (
                      <td className={cx(styles.cell, styles.cellIcon)}>{getIconByValue(row.tableCols[index])}</td>
                    ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
