import React, { useState, useRef, useEffect, useCallback } from 'react'
import cx from 'classnames'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

const InfiniteLooper = ({ speed, direction, children }) => {
  const [looperInstances, setLooperInstances] = useState(3)
  const outerRef = useRef(null)
  const innerRef = useRef(null)

  const resetAnimation = () => {
    if (innerRef?.current) {
      innerRef.current.setAttribute('data-animate', 'false')
      setTimeout(() => {
        if (innerRef?.current) {
          innerRef.current.setAttribute('data-animate', 'true')
        }
      }, 10)
    }
  }

  const setupInstances = useCallback(() => {
    if (!innerRef?.current || !outerRef?.current) return
    const { width } = innerRef.current.getBoundingClientRect()
    const { width: parentWidth } = outerRef.current.getBoundingClientRect()
    const widthDeficit = parentWidth - width
    const instanceWidth = width / innerRef.current.children.length
    if (widthDeficit) {
      setLooperInstances(looperInstances + Math.ceil(widthDeficit / instanceWidth) + 1)
    }
    resetAnimation()
  }, [looperInstances])

  useEffect(() => {
    setupInstances()
  }, [setupInstances])

  useEffect(() => {
    window.addEventListener('resize', setupInstances)
    return () => window.removeEventListener('resize', setupInstances)
  }, [looperInstances, setupInstances])

  return (
    <div className={styles.looper} ref={outerRef}>
      <div className={styles.looperInnerList} ref={innerRef} data-animate="true">
        {[...Array(looperInstances)].map((_, index) => (
          <div
            key={index}
            className={styles.looperListInstance}
            style={{
              animationDuration: `${speed}s`,
              animationDirection: direction === 'right' ? 'reverse' : 'normal',
            }}
          >
            {children}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ({ benefits }) => {
  if (!benefits || benefits.isHide) return null

  return (
    <div className={styles.benefits}>
      <img
        {...srcSetProps(sanityImageUrl(benefits.image))}
        className={styles.background}
        alt={benefits.image?.caption}
      />
      <div className={styles.list}>
        <InfiniteLooper speed="10" direction="left">
          {benefits.items &&
            benefits.items.map((item, index) => (
              <div className={styles.item}>
                <img {...srcSetProps(sanityImageUrl(item.image))} alt={item.image?.caption} />
                <span>{item.text}</span>
              </div>
            ))}
        </InfiniteLooper>
      </div>
      <div className={styles.wrap}>
        <div className={styles.box}>
          <p className={styles.boxTitle}>{benefits.title}</p>
        </div>
      </div>
    </div>
  )
}
