import React, { useEffect, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { Link } from 'gatsby'
import { titleToId, prepareParagraph } from '../../../utils/helpers'
import ScrollTrigger from 'react-scroll-trigger';
import { CountUp } from 'use-count-up'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ products }) => {
  if (!products || products.isHide) return null

  const [animationStarted, setAnimationStarted] = useState([])

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin)
  }, [])

  const scrollTo = id => {
    gsap.to(window, {
      duration: 2,
      scrollTo: document.getElementById(id)
    })
  }

  const enter = (e, id) => {
    setAnimationStarted([...animationStarted, id])
  }

  return (
    <div className={styles.products}>
      <div className={styles.wrap}>
        {products.items && products.items.map(product => (
          <div className={styles.product} key={product._key}>
            <div className={styles.left}>
              <h2 className={styles.title}>{product.title}</h2>
              <p className={styles.info}>{prepareParagraph(product.description)}</p>
              <div className={styles.nav}>
                {products.items && products.items.map(i => (
                  <div className={cx(styles.navItem, {
                    [styles.navItemActive]: i._key === product._key
                  })} key={`nav_${i._key}`} onClick={() => scrollTo(titleToId(i.title))}>{i.title}</div>
                ))}
              </div>
            </div>
            <div className={styles.right}>
              <span className={styles.anchor} id={titleToId(product.title)}/>
              <div className={styles.sections}>
                {product.sections && product.sections.map(section => (
                  <div className={styles.section} key={section._key}>
                    <p className={styles.name}>{section.title}</p>
                    {section.lines && section.lines.map(line => (
                      <div className={styles.line} key={line._key}>
                          <p className={styles.top}>
                            {line.arrow && line.arrow === 'up' && (
                              <span className={styles.arrowUp}></span>
                            )}
                            {line.arrow && line.arrow === 'down' && (
                              <span className={styles.arrowDown} />
                            )}
                            {line.values && line.values.map(value => (
                              <>
                                {value.withAnimation ? (
                                  <ScrollTrigger onEnter={(e) => enter(e, value._key)}>
                                    <CountUp isCounting={animationStarted.includes(value._key)} end={parseFloat(value.text)} duration={1.5} />
                                  </ScrollTrigger>                              
                                ) : value.text}
                              </>
                            ))}
                        </p>
                        <p className={styles.bottom}>{prepareParagraph(line.description)}</p>
                      </div>
                    ))}                  
                  </div>
                ))}
              </div>
              <Link to={product.buttonUrl} className={styles.link}>{product.buttonText}</Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
