import React from 'react'
import { sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ wim }) => {
  if (!wim || wim.isHide) return null

  return (
    <div className={styles.wim}>
      <div className={styles.wrap}>
        <div className={styles.image}>
          <img className={styles.imageSrc} src={sanityImageUrl(wim.image)} alt={wim.image?.caption} />
        </div>
        <div className={styles.info}>
          <h2 className={styles.title}>{wim.title}</h2>
          <p className={styles.desc}>{wim.description}</p>
          <p className={styles.include}>{wim.includeTitle}</p>
          <ul className={styles.steps}>
            {wim.itemsIcons && wim.itemsIcons.length > 0 ? (
              <>
                {wim.itemsIcons && wim.itemsIcons.map(item => (
                  <li className={styles.stepsItem} key={item._key}>
                    <img className={styles.stepsIcon} src={sanityImageUrl(item.icon)} alt={item.icon?.caption} /> {item.title}
                  </li>
                ))}
              </>
            ) : (
              <>
                {wim.items && wim.items.map((item, index) => (
                  <li className={styles.stepsItem} key={index}>
                    {item}
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}
