import React from 'react'
import { Link } from 'gatsby'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ testosterone }) => {
  if (!testosterone || testosterone.isHide) return null

  return (
    <div className={styles.testosterone}>
      <img
        {...srcSetProps(sanityImageUrl(testosterone.image))}
        className={styles.image}
        alt={testosterone.image?.caption}
      />
      <img
        {...srcSetProps(sanityImageUrl(testosterone.mobileImage))}
        className={styles.imageMobile}
        alt={testosterone.mobileImage?.caption}
      />
      <div className={styles.wrap}>
        <div className={styles.content}>
          <p className={styles.titleSmall}>{testosterone.smallTitle}</p>
          <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: testosterone.title }} />
          <p className={styles.price} dangerouslySetInnerHTML={{ __html: testosterone.price }} />
          <div className={styles.buttons}>
            <a
              href={testosterone.getStartedButtonUrl}
              onClick={urlWithSearchParamsHandler}
              className={styles.buttonsStarted}
            >
              {testosterone.getStartedButtonText}
            </a>
            <Link className={styles.buttonsMore} to={testosterone.learnMoreButtonUrl}>
              {testosterone.learnMoreButtonText}
            </Link>
          </div>
          <div className={styles.info}>
            <div className={styles.infoColumn}>
              <p className={styles.infoTitle}>{testosterone.ingredientsTitle}</p>
              <ul className={styles.infoList}>
                {testosterone.ingredientsItems &&
                  testosterone.ingredientsItems.map(item => (
                    <li className={styles.infoItem} key={item._key}>
                      {item.ingredient}
                    </li>
                  ))}
              </ul>
            </div>
            <div className={cx(styles.infoColumn, styles.infoColumnBenefits)}>
              <p className={styles.infoTitle}>{testosterone.benefitsTitle}</p>
              <ul className={cx(styles.infoList, styles.infoListPoints)}>
                {testosterone.benefitsItems &&
                  testosterone.benefitsItems.map(item => (
                    <li className={styles.infoItem} key={item._key}>
                      {item.benefit}
                    </li>
                  ))}
              </ul>
            </div>
            <div className={styles.infoColumn}>
              <p className={styles.infoTitle}>{testosterone.productsTitle}</p>
              <ul className={styles.infoList}>
                {testosterone.productsItems &&
                  testosterone.productsItems.map(item => (
                    <li className={styles.infoItem} key={item._key}>
                      <Link className={styles.infoLink} to={item.productUrl}>
                        {item.productText}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
