import React from 'react'
import { Link } from 'gatsby'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ weightLoss }) => {
  if (!weightLoss || weightLoss.isHide) return null

  return (
    <div className={styles.weightLoss}>
      <img
        {...srcSetProps(sanityImageUrl(weightLoss.image))}
        className={styles.image}
        alt={weightLoss.image?.caption}
      />
      <img
        {...srcSetProps(sanityImageUrl(weightLoss.mobileImage))}
        className={styles.imageMobile}
        alt={weightLoss.mobileImage?.caption}
      />
      <div className={styles.wrap}>
        <div className={styles.content}>
          <p className={styles.titleSmall}>{weightLoss.smallTitle}</p>
          <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: weightLoss.title }} />
          <p className={styles.price} dangerouslySetInnerHTML={{ __html: weightLoss.price }} />
          <div className={styles.buttons}>
            <a
              href={weightLoss.getStartedButtonUrl}
              onClick={urlWithSearchParamsHandler}
              className={styles.buttonsStarted}
            >
              {weightLoss.getStartedButtonText}
            </a>
            <Link className={styles.buttonsMore} to={weightLoss.learnMoreButtonUrl}>
              {weightLoss.learnMoreButtonText}
            </Link>
          </div>
          <div className={styles.info}>
            <div className={styles.infoColumn}>
              <p className={styles.infoTitle}>{weightLoss.ingredientsTitle}</p>
              <ul className={styles.infoList}>
                {weightLoss.ingredientsItems &&
                  weightLoss.ingredientsItems.map(item => (
                    <li className={styles.infoItem} key={item._key}>
                      {item.ingredient}
                    </li>
                  ))}
              </ul>
            </div>
            <div className={cx(styles.infoColumn, styles.infoColumnBenefits)}>
              <p className={styles.infoTitle}>{weightLoss.benefitsTitle}</p>
              <ul className={cx(styles.infoList, styles.infoListPoints)}>
                {weightLoss.benefitsItems &&
                  weightLoss.benefitsItems.map(item => (
                    <li className={styles.infoItem} key={item._key}>
                      {item.benefit}
                    </li>
                  ))}
              </ul>
            </div>
            <div className={styles.infoColumn}>
              <p className={styles.infoTitle}>{weightLoss.productsTitle}</p>
              <ul className={styles.infoList}>
                {weightLoss.productsItems &&
                  weightLoss.productsItems.map(item => (
                    <li className={styles.infoItem} key={item._key}>
                      <Link className={styles.infoLink} to={item.productUrl}>
                        {item.productText}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
