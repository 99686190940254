import React from 'react'
import { urlWithSearchParamsHandler, srcSetProps, prepareParagraph, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ buildingBlocks }) => (
  <div className={styles.buildingBlocks}>
    <div className={styles.wrap}>
      <div className={styles.col1}>
        <p className={styles.top}>{buildingBlocks.smallTitle}</p>
        <p className={styles.title}>{buildingBlocks.bigTitle}</p>
        <p className={styles.price}>{buildingBlocks.price}</p>
        <a className={styles.link} href={buildingBlocks.buttonUrl} onClick={urlWithSearchParamsHandler}>
          {buildingBlocks.buttonText}
        </a>
      </div>
      <div className={styles.col2}>
        <img {...srcSetProps(sanityImageUrl(buildingBlocks.image))} className={styles.image} />
      </div>
      <div className={styles.col3}>
        <p className={styles.title1}>{buildingBlocks.title1}</p>
        <p className={styles.text1}>{prepareParagraph(buildingBlocks.text1)}</p>
        <p className={styles.title2}>{buildingBlocks.title2}</p>
        <p className={styles.text2}>{prepareParagraph(buildingBlocks.text2)}</p>
        <p className={styles.disclaimer}>{buildingBlocks.disclaimer}</p>
      </div>
    </div>
  </div>
)
