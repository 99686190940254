import React from 'react'
import { prepareParagraph, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ info }) => {
  if (!info || info.isHide) return null

  return (
    <div className={styles.info}>
      <div className={styles.wrap}>
        <div className={styles.seen}>
          <p className={styles.seenTitle}>{info.asSeenTitle}</p>
          {info.asSeenItems &&
            info.asSeenItems.map(item => (
              <img
                src={sanityImageUrl(item.image)}
                className={styles.seenImage}
                alt={item.image?.caption}
                key={item._key}
              />
            ))}
        </div>
        <div className={styles.results}>
          <div className={styles.one}>
            <img src={sanityImageUrl(info.image)} className={styles.image} alt={info.image?.caption} />
          </div>
          <div className={styles.many}>
            {info.results &&
              info.results.map(item => (
                <div className={styles.item} key={item._key}>
                  <img src={sanityImageUrl(item.image)} className={styles.itemImage} alt={item.image?.caption} />
                  <p className={styles.itemTitle}>{item.title}</p>
                  <p className={styles.itemText}>{prepareParagraph(item.text)}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
