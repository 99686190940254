import React from 'react'
import { sanityImageUrl, titleToId } from '/src/utils/helpers'
import styles from './style.module.sass'

export default ({ abstract }) => {
  return (
    <div className={styles.abstract}>
      <div className={styles.anchor} id={titleToId(abstract.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{abstract.title}</h2>
      <div className={styles.content}>
        <div className={styles.text}>
          <p className={styles.description}>{abstract.description}</p>
          <div className={styles.findings}>
            <p className={styles.headline}>{abstract.findingsTitle}</p>
            <ul className={styles.list}>
              {abstract.findingsList &&
                abstract.findingsList.map((item, index) => (
                  <li key={index} className={styles.item}>
                    {item}
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className={styles.image}>
          <img src={sanityImageUrl(abstract.image)} alt={abstract.image?.caption} />
        </div>
      </div>
    </div>
  )
}
