import React from 'react'
import { sanityImageUrl } from '../../../utils/helpers'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ levels }) => {
  if (!levels || levels.isHide) return null

  return (
    <div className={styles.levels}>
      <div className={styles.wrap}>
        <div className={styles.column}>
          <img src={sanityImageUrl(levels.image)} className={styles.image} alt={levels.image?.caption} />
        </div>
        <div className={styles.column}>
          <h2 className={styles.title}>{levels.title}</h2>
          <img src={sanityImageUrl(levels.imageMobile)} className={styles.imageMobile} alt={levels.imageMobile?.caption} />
          <p className={styles.description}>{levels.description}</p>
          <Link to={levels.buttonUrl} className={styles.link}>{levels.buttonText}</Link>
        </div>
      </div>
    </div>
  )
}