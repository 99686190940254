import React from 'react'
import cx from 'classnames'
import { prepareParagraph, titleToId } from '../../../../utils/helpers'
import styles from './style.module.sass'

export default ({ drugFacts }) => {
  return (
    <div className={styles.drugFacts}>
      <div className={styles.anchor} id={titleToId(drugFacts.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{drugFacts.title}</h2>
      <div className={styles.info}>
        <div className={styles.infoList}>
          {drugFacts.ingredients &&
            drugFacts.ingredients.map((ingredient, index) => (
              <div className={styles.ingredient} key={ingredient._key}>
                <div className={styles.col1}>
                  {ingredient.title1 && <p className={styles.colTitle}>{ingredient.title1}</p>}
                  <p className={styles.key}>{ingredient.key}</p>
                </div>
                <div className={styles.col2}>
                  {ingredient.title2 && <p className={styles.colTitle}>{ingredient.title2}</p>}
                  <p className={styles.description}>{prepareParagraph(ingredient.howItWorks)}</p>
                </div>
                <div className={styles.col3}>
                  {ingredient.title3 && <p className={styles.colTitle}>{ingredient.title3}</p>}
                  <p className={styles.description}>{prepareParagraph(ingredient.description)}</p>
                </div>
              </div>
            ))}
        </div>
        <div className={styles.infoDrugs}>
          <div className={styles.infoBox}>
            <p className={styles.infoDrugsTitle}>{drugFacts.informationTitle}</p>
            {drugFacts.items &&
              drugFacts.items.map(row => (
                <div className={styles.supplement} key={row._key}>
                  <p className={styles.supplementTitle}>{row.title}</p>
                  <p className={styles.supplementValue}>{row.value}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
