import React from 'react'
import cx from 'classnames'
import { ResponsiveContainer, AreaChart, Area, YAxis } from 'recharts'
import { srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import Slider from 'react-slick'
import styles from './style.module.sass'

export default ({ testimonials }) => {
  const sliderSettings = {
    arrows: false,
    dots: true,
    autoplay: false,
    slidesToShow: 1,
    infinite: false,
    dotsClass: styles.dots,
  }

  return (
    <div className={styles.testimonials}>
      <div className={styles.wrap}>
        <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: testimonials.title }} />

        <div className={styles.slider}>
          <Slider {...sliderSettings}>
            {testimonials.items &&
              testimonials.items.map((item, index) => (
                <div
                  className={cx(styles.sliderItem, { [styles.sliderItemActive]: index === 0 })}
                  key={item._key}
                  data-index={index}
                >
                  <div className={styles.inside}>
                    <div className={styles.left}>
                      <div className={styles.top}>
                        <div className={styles.image}>
                          <img {...srcSetProps(sanityImageUrl(item.image), [[200, 1980]])} alt={item.name} />
                        </div>
                        <div className={styles.about}>
                          <p className={styles.name}>
                            {item.name}
                            <span>{item.time}</span>
                          </p>
                          <p className={styles.multiplier} dangerouslySetInnerHTML={{ __html: item.multiplier }} />
                          <p className={styles.results}>
                            <span className={styles.resultsBefore}>
                              before <b>{item.resultBefore}</b> ng/dL
                            </span>
                            <span className={styles.resultsMiddle} />
                            <span className={styles.resultsAfter}>
                              after <b>{item.resultAfter}</b> ng/dL
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className={styles.bottom}>
                        <p className={styles.review} dangerouslySetInnerHTML={{ __html: item.review }} />
                      </div>
                    </div>
                    <div className={styles.right}>
                      <div className={styles.legend}>
                        <span>1000 ng/dL</span>
                        <span>800 ng/dL</span>
                        <span>600 ng/dL</span>
                        <span>400 ng/dL</span>
                        <span>200 ng/dL</span>
                        <span>0 ng/dL</span>
                      </div>
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          isAnimationActive={false}
                          width="100%"
                          data={[
                            { x: item.resultBefore },
                            { x: item.resultBefore + 50 },
                            { x: (item.resultBefore + item.resultAfter) / 2 },
                            { x: item.resultAfter - 50 },
                            { x: item.resultAfter },
                          ]}
                          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                        >
                          <YAxis type="number" domain={[0, 1000]} hide />
                          <Area type="monotone" dataKey="x" stroke="none" fill="#0D3E6A" fillOpacity="0.2" />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}
