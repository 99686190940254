import React from 'react'
import cx from 'classnames'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ benefits }) => {
  if (!benefits || benefits.isHide) return null

  return (
    <div className={styles.benefits}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{benefits.title}</h2>

        <div className={styles.box}>
          <img
            {...srcSetProps(sanityImageUrl(benefits.image))}
            alt={benefits.image?.caption}
            className={styles.image}
          />

          <div className={styles.line1} />
          <div className={styles.line2} />
          <div className={styles.line3} />
          <div className={styles.line4} />

          <div className={styles.left}>
            {benefits.items &&
              benefits.items.map((item, index) => (
                <>
                  {index < 2 && (
                    <div className={cx(styles.text, styles[`text${index}`])} key={item._key}>
                      <span className={styles.index}>{index + 1}</span>
                      <p className={styles.key}>{item.title}</p>
                      <p className={styles.info}>{prepareParagraph(item.description)}</p>
                    </div>
                  )}
                </>
              ))}
          </div>

          <div className={styles.right}>
            {benefits.items &&
              benefits.items.map((item, index) => (
                <>
                  {index > 1 && (
                    <div className={cx(styles.text, styles[`text${index}`])} key={item._key}>
                      <span className={styles.index}>{index + 1}</span>
                      <p className={styles.key}>{item.title}</p>
                      <p className={styles.info}>{prepareParagraph(item.description)}</p>
                    </div>
                  )}
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
