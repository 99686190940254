import React from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ header }) => {
  return (
    <div className={styles.header}>
      <img className={styles.bg} src={sanityImageUrl(header.imageBackground)} alt={header.imageBackground?.caption} />
      <img
        className={styles.bgElements}
        src={sanityImageUrl(header.imageElements)}
        alt={header.imageElements?.caption}
      />
      <img
        className={styles.bgElementsMobile}
        src={sanityImageUrl(header.imageElementsMobile)}
        alt={header.imageElementsMobile?.caption}
      />
      <div className={styles.wrap}>
        <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: header.title }} />
        <h1 className={styles.titleMob} dangerouslySetInnerHTML={{ __html: header.titleMobile }} />
        <ul className={styles.benefits}>
          {header.benefits &&
            header.benefits.map((item, index) => (
              <li className={styles.benefit} key={index}>
                {item}
              </li>
            ))}
        </ul>
        <a href={header.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>
          {header.buttonText}
        </a>
      </div>
    </div>
  )
}
