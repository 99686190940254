import React from 'react'
import { sanityImageUrl, urlWithSearchParamsHandler } from '../../../utils/helpers'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ protocol }) => (
  <div className={styles.item}>
    <div className={styles.itemTop}>
      {protocol.tag && <span className={styles.tag}>{protocol.tag}</span>}
      <img src={sanityImageUrl(protocol.imageVertical)} className={styles.image} alt={protocol.imageVertical?.caption} />
      <div className={styles.itemWrap}>
        <p className={styles.name}>{protocol.name}</p>
        <div className={styles.ingredients}>
          {protocol.ingredients && protocol.ingredients.map(item => (
            <p className={styles.ingredientsItem} key={item._key}>
              {item.name}
            </p>
          ))}
        </div>        
        <p className={styles.description}>{protocol.descriptionShort}</p>
      </div>
    </div>

    <div className={styles.itemBottom}>
      <div className={styles.buttons}>
        <a
          href={protocol.getStartedButtonUrl}
          onClick={urlWithSearchParamsHandler}
          className={styles.buttonsStarted}
        >Start Treatment</a>
      </div>
    </div>
  </div>
)