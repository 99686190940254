import React from 'react'
import { srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ vitamins }) => {
  if (!vitamins || vitamins.isHide) return null

  return (
    <div className={styles.vitamins}>
      <div className={styles.wrap}>
        <div className={styles.column}>
          <h2 className={styles.title}>{vitamins.title}</h2>
          <p className={styles.description}>{vitamins.description}</p>
        </div>
        <div className={styles.column}>
          <img
            {...srcSetProps(sanityImageUrl(vitamins.image))}
            className={styles.image}
            alt={vitamins.image?.caption}
          />
        </div>
      </div>
    </div>
  )
}
