import React from 'react'
import { prepareParagraph } from '../../../utils/helpers'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ reviews }) => {
  if (!reviews || reviews.isHide) return null

  return (
    <div className={styles.reviews}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{reviews.title}</h2>
        <p className={styles.order}>{reviews.reviewsOrder}</p>
        <div className={styles.list}>
          {reviews.items &&
            reviews.items.map((item, index) => (
              <div className={styles.item} key={item._key}>
                <div className={styles.user}>
                  <div className={styles.userInfo}>
                    <p className={styles.name}>{item.name}</p>
                    <p className={styles.verified}>Verified buyer</p>
                  </div>
                </div>
                <p className={styles.protocol}>
                  Reviewing: <span>{item.protocol}</span>
                </p>
                <p className={styles.header}>{item.title}</p>
                <p className={styles.body}>{prepareParagraph(item.review)}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
