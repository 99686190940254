import React from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ product }) => {
  if (!product || product.isHide) return null

  return (
    <div className={styles.product}>
      <div className={styles.wrap}>
        <div className={styles.image}>
          <img
            {...srcSetProps(sanityImageUrl(product.image))}
            alt={product.image?.caption}
            className={styles.imageSrc}
          />
        </div>
        <div className={styles.box}>
          <div className={styles.boxWrap}>
            <h2 className={styles.title}>{product.title}</h2>
            <p className={styles.blue}>{product.includedTitle}</p>
            <div className={styles.included}>
              {product.included &&
                product.included.map((product, index) => (
                  <p className={styles.includedItem} key={index}>
                    {product}
                  </p>
                ))}
            </div>
            <a href={product.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>
              {product.buttonText}
            </a>
            <p className={styles.insurance}>{product.insurance}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
