import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import { sortComponents } from '../utils/helpers'
import Header from '../components/CompanyPage/Header'
import About from '../components/CompanyPage/About'
import Values from '../components/CompanyPage/Values'
import Masculinity from '../components/CompanyPage/Masculinity'
import Doctors from '../components/CompanyPage/Doctors'
import Wim from '../components/CompanyPage/Wim'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <About about={data.about} />,
      priority: data.about?.priority || 99,
    },
    {
      render: <Values values={data.values} />,
      priority: data.values?.priority || 99,
    },
    {
      render: <Masculinity masculinity={data.masculinity} />,
      priority: data.masculinity?.priority || 99,
    },
    {
      render: <Doctors doctors={data.doctors} />,
      priority: data.doctors?.priority || 99,
    },
    {
      render: <Wim wim={data.wim} />,
      priority: data.wim?.priority || 99,
    }
  ].sort(sortComponents)

  return (
    <Layout data={data} signUpUrl={data.header?.buttonUrl} signUpText={data.header?.buttonText}>
      <SEO {...data.seo} />
      <Header header={data.header} />
      {components.map(component => component.render)}
    </Layout>
  )
}
