import React from 'react'
import { prepareParagraph, titleToId } from '/src/utils/helpers'
import styles from './style.module.sass'

export default ({ findings }) => {
  return (
    <>
      <div className={styles.discussion}>
        <div className={styles.anchor} id={titleToId(findings.titleSection)} data-js="scroll-section" />
        <h2 className={styles.title}>{findings.titleSection}</h2>
      </div>

      <div className={styles.findings}>
        <div className={styles.anchor} id={titleToId(findings.title)} data-js="scroll-section" />
        <h2 className={styles.title}>{findings.title}</h2>

        <div className={styles.content}>
          {findings.Content &&
            findings.Content.map(block => (
              <div className={styles.paragraph}>
                <p className={styles.headline}>{prepareParagraph(block.title)}</p>

                {block.body &&
                  block.body.map(item => (
                    <div className={styles.item}>
                      {item._type === 'info' && <p className={styles.info}>{prepareParagraph(item.info)}</p>}

                      {item._type === 'warning' && <p className={styles.warning}>{prepareParagraph(item.warning)}</p>}
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </div>
    </>
  )
}
