import React, { useState } from 'react'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import cx from 'classnames'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ info, protocols }) => {
  if (!info || info.isHide) return null

  const [activeTab, setActiveTab] = useState(0)

  return (
    <>
      <div className={styles.info}>
        <div className={styles.wrap}>
          <h2 className={styles.title}>{info.titleSection1}</h2>
        </div>
        <div className={styles.tabs}>
          {protocols &&
            protocols.map((product, index) => (
              <div
                className={cx(styles.tabsItem, {
                  [styles.tabsItemActive]: activeTab === index,
                })}
                key={product._key}
                onClick={() => setActiveTab(index)}
                dangerouslySetInnerHTML={{ __html: product.nameNav }}
              />
            ))}
        </div>
        <div className={styles.wrap}>
          <div className={styles.content}>
            {protocols &&
              protocols.map((product, index) => (
                <div
                  className={cx(styles.contentItem, {
                    [styles.contentItemActive]: activeTab === index,
                  })}
                  key={product._key}
                >
                  <div className={styles.top}>
                    <img
                      className={styles.topImage}
                      {...srcSetProps(sanityImageUrl(product.imageSquare))}
                      alt={product.imageSquare?.caption}
                    />
                    <ul className={styles.topList}>
                      {product.benefits &&
                        product.benefits.map(benefit => (
                          <li className={styles.topListItem} key={benefit._key}>
                            {benefit.title}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className={styles.ingredients}>
        <div className={styles.wrap}>
          <h2 className={styles.title}>{info.titleSection2}</h2>
          <p className={styles.description}>{info.descriptionSection2}</p>
          {protocols &&
            protocols.map((product, index) => (
              <div
                className={cx(styles.items, {
                  [styles.itemsActive]: activeTab === index,
                })}
                key={product._key}
              >
                {product.ingredients &&
                  product.ingredients.map(ingredient => (
                    <div className={styles.item} key={ingredient._key}>
                      <img
                        src={sanityImageUrl(ingredient.image)}
                        className={styles.image}
                        alt={ingredient.image?.caption}
                      />
                      <p className={styles.name}>{ingredient.name}</p>
                      <p className={styles.desc}>{ingredient.info}</p>

                      <div className={styles.faq}>
                        {ingredient.faq &&
                          ingredient.faq.map(faq => (
                            <div key={faq._key}>
                              <p className={styles.faqQuestion}>{faq.question}</p>
                              <p className={styles.faqanswer}>{prepareParagraph(faq.answer)}</p>
                            </div>
                          ))}
                      </div>

                      {ingredient.buttonText && ingredient.buttonUrl && (
                        <Link to={ingredient.buttonUrl} className={styles.link}>
                          {ingredient.buttonText}
                        </Link>
                      )}
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </div>
    </>
  )
}
