import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import Logo from '../Icons/Logo'
import Facebook from '../Icons/Social/Facebook'
import YouTube from '../Icons/Social/YouTube'
import Linkedin from '../Icons/Social/Linkedin'
import Skype from '../Icons/Social/Skype'
import GooglePlus from '../Icons/Social/GooglePlus'
import Discord from '../Icons/Social/Discord'
import Instagram from '../Icons/Social/Instagram'
import SafePharmacySrc from '../../../images/nabp.png'
import { formAccessHandler, prepareParagraph } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ footer, size }) => {
  const [submit, setSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [debug, setDebug] = useState(false)

  useEffect(() => {
    const params = Object.fromEntries(new URLSearchParams(location.search))
    if (params.debug === 'true') {
      setDebug(true)
    }
  }, [])

  return (
    <div className={styles.footer}>
      <div className={styles.top}>
        <div className={styles.wrap}>
          <div className={cx(styles.column, styles.columnFirst)}>
            <div className={cx(styles.columnTitle)}>{footer.emailbox.title}</div>
            {!submit && (
              <form
                onSubmit={(e) => formAccessHandler(e, loading, setLoading, setSubmit)}
                className={cx(styles.form, {
                  [styles.loading]: loading,
                })}
              >
                <div className={styles.formRow}>
                  <label for="email" className={styles.formLabel}>
                    {footer.emailbox?.placeholder}
                  </label>
                  <input
                    type="email"
                    placeholder={footer.emailbox?.placeholder}
                    name="email"
                    id="email"
                    className={cx(styles.formInput)}
                  />
                  <input type="submit" value="" className={cx(styles.formSubmit)} />
                </div>
                <p className={styles.formAgree}>{footer.emailbox?.description}</p>
              </form>
            )}
            {submit && (
              <div className={cx(styles.formDescription)}>
                We will send you an email shortly. Please check your mailbox.
              </div>
            )}
          </div>

          {footer.newlinks &&
            footer.newlinks.map(navTitle => (
              <div className={cx(styles.column, styles.columnNav)} key={navTitle._key}>
                <div className={cx(styles.columnTitle)}>{navTitle.title}</div>
                <div className={cx(styles.columnLinks)}>
                  {navTitle.items.map(navLink => (
                    <>
                      {navLink.newTab ? (
                        <a
                          href={navLink.path || navLink.href}
                          key={navLink._key}
                          className={styles.columnLink}
                          target="_blank"
                          title={navLink.metaTitle}
                        >
                          {navLink.title}
                        </a>
                      ) : (
                        <Link
                          to={navLink.path || navLink.href}
                          key={navLink._key}
                          className={styles.columnLink}
                          title={navLink.metaTitle}
                        >
                          {navLink.title}
                        </Link>
                      )}
                    </>
                  ))}
                </div>
              </div>
            ))}

          <div className={cx(styles.column, styles.columnLast)}>
            <div className={cx(styles.columnTitle)}>{footer.contact}</div>
            {footer.emails && (
              <div className={cx(styles.emails)}>
                {footer.emails.map(item => (
                  <div className={cx(styles.emailsItem)} key={item._key}>
                    {item.title}:<br />
                    <a href={`mailto:${item.email}`}>{item.email}</a>
                  </div>
                ))}
              </div>
            )}

            <div className={cx(styles.socials)}>
              {footer.facebook && (
                <a className={styles.socialsIcon} href={footer.facebook} target="_blank">
                  <Facebook />
                </a>
              )}
              {footer.youtube && (
                <a className={styles.socialsIcon} href={footer.youtube} target="_blank">
                  <YouTube />
                </a>
              )}
              {footer.linkedin && (
                <a className={styles.socialsIcon} href={footer.linkedin} target="_blank">
                  <Linkedin />
                </a>
              )}
              {footer.googlePlus && (
                <a className={styles.socialsIcon} href={footer.googlePlus} target="_blank">
                  <GooglePlus />
                </a>
              )}
              {footer.skype && (
                <a className={styles.socialsIcon} href={footer.skype} target="_blank">
                  <Skype />
                </a>
              )}
              {footer.instagram && (
                <a className={styles.socialsIcon} href={footer.instagram} target="_blank">
                  <Instagram />
                </a>
              )}
              {footer.discord && (
                <a className={styles.socialsIcon} href={footer.discord} target="_blank">
                  <Discord />
                </a>
              )}
            </div>
            <div className={styles.badges}>
              <div className={styles.badgesRow}>
                <a
                  href="https://www.producthunt.com/posts/maximus-2?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-maximus&#0045;2"
                  target="_blank"
                  className={styles.badgesProductHunt}
                >
                  <img
                    src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=346690&theme=dark&period=daily"
                    alt="Maximus - Optimal&#0032;health&#0032;for&#0032;men&#0032;via&#0032;optimal&#0032;hormone&#0032;levels | Product Hunt"
                  />
                </a>
                <a
                  href="https://www.legitscript.com/websites/?checker_keywords=maximustribe.com"
                  target="_blank"
                  title="Verify LegitScript Approval"
                  className={styles.badgesLegitScript}
                >
                  <img src="https://static.legitscript.com/seals/9646940.png" alt="LegitScript approved" />
                </a>
              </div>
              <div className={styles.badgesRow}>
                <a
                  href="https://safe.pharmacy/buy-safely/?url=maximus.pharmacy"
                  target="_blank"
                  className={styles.badgesSafePharmacy}
                >
                  <img src={SafePharmacySrc} alt="Maximus.Pharmacy is verified" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.bottom}>
        <div className={styles.wrap}>
          <div className={styles.bottomLeft}>
            <p className={cx(styles.bottomItem, styles.bottomItemText)}>{footer.bottomlinks.copyright}</p>
            {debug && <a className={styles.debug} href="https://staging.maximustribe.com">staging.maximustribe.com</a>}
          </div>
          <div className={styles.bottomRight}>
            {footer.bottomlinks.links.map(item => (
              <a
                key={item._key}
                href={item.href}
                className={cx(styles.bottomItem, styles.bottomItemLink)}
                target="_blank"
              >
                {item.title}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
