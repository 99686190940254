import React from 'react'
import { srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ clients }) => {
  if (!clients || clients.isHide) return null

  return (
    <div className={styles.clients}>
      <img {...srcSetProps(sanityImageUrl(clients.image))} className={styles.image} alt={clients.image?.caption} />
      <div className={styles.wrap}>
        <h2 className={styles.title}>{clients.title}</h2>
        <div className={styles.online}>{clients.onlineText}</div>
        <div className={styles.results}>
          <div className={styles.resultsBox}>
            <div className={styles.resultsRow}>
              <span className={styles.resultsLine} dangerouslySetInnerHTML={{ __html: clients.totalText }} />
              <span className={styles.resultsLine} dangerouslySetInnerHTML={{ __html: clients.totalValue }} />
            </div>
            <div className={styles.resultsRow}>
              <span className={styles.resultsLine} dangerouslySetInnerHTML={{ __html: clients.freeText }} />
              <span className={styles.resultsLine} dangerouslySetInnerHTML={{ __html: clients.freeValue }} />
            </div>
          </div>
          <p className={styles.resultsText}>{clients.resultsText}</p>
        </div>
      </div>
    </div>
  )
}
