import React from 'react'
import cx from 'classnames'
import { prepareParagraph, titleToId } from '../../../../utils/helpers'
import styles from './style.module.sass'

export default ({ drugFacts }) => {
  return (
    <div className={styles.drugFacts}>
      <div className={styles.anchor} id={titleToId(drugFacts.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{drugFacts.title}</h2>
      <div className={styles.list}>
        <div className={cx(styles.item, styles.ingredients)}>
          <p className={styles.keyTitle}>{drugFacts.keyTitle}</p>
          {drugFacts.ingredients &&
            drugFacts.ingredients.map(ingredient => (
              <div>
                <p className={styles.ingredient} key={ingredient._key}>
                  {ingredient.key}
                </p>
              </div>
            ))}
        </div>

        {drugFacts.options &&
          drugFacts.options.map((option, index) => (
            <div className={cx(styles.item, styles.option)} key={option._key}>
              <p className={styles.optionTitle}>{option.title}</p>
              <div className={styles.infoBox}>
                <p className={styles.infoDrugsTitle}>{option.text}</p>
                {option.items &&
                  option.items.map(row => (
                    <div className={styles.supplement} key={row._key}>
                      <p className={styles.supplementTitle}>{row.title}</p>
                      <p className={styles.supplementValue}>{row.value}</p>
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
