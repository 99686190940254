import React from 'react'
import { srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ articles }) => {
  return (
    <div className={styles.articles}>
      <div className={styles.wrap}>
        <ul className={styles.list}>
          {articles.items &&
            articles.items.map((item, index) => (
              <li key={item._key}>
                <img {...srcSetProps(sanityImageUrl(item.image))} alt={item.image?.caption} />
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}
