import React from 'react'
import { sanityImageUrl, prepareParagraph } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ low }) => {
  if (!low || low.isHide) return null

  return (
    <div className={styles.low}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{prepareParagraph(low.title)}</h2>
        <img className={styles.image} src={sanityImageUrl(low.image)} alt={low.image?.caption} />
      </div>
    </div>
  )
}
