import React, { useState } from 'react'
import { sanityImageUrl, prepareParagraph } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ live }) => {
  if (!live || live.isHide) return null

  const [safetyModalShow, setSafetyModalShow] = useState(false)

  const openSafetyModalHandler = () => {
    setSafetyModalShow(true)
  }

  const closeSafetyModalHandler = () => {
    setSafetyModalShow(false)
  }

  return (
    <div className={styles.live}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{live.title}</h2>
        <p className={styles.description}>{live.description}</p>

        {live.ClinicalStudyModal?.modalText && (
          <div className={styles.safetyModalLink}>
            <span onClick={openSafetyModalHandler}>ⓘ <span>{live.ClinicalStudyModal.modalText}</span></span>
          </div>
        )}

        <div className={styles.box}>
          <img src={sanityImageUrl(live.image)} className={styles.image} alt={live.image?.caption} />
          <div className={styles.table}>
            <p className={styles.tableTitle} dangerouslySetInnerHTML={{ __html: live.tableTitle }} />
            {live.tableRows && live.tableRows.map(row => (
              <div className={styles.tableRow} key={row._key}>
                <p className={styles.tableRowC1}>{row.column_1}</p>
                <p className={styles.tableRowC2}>{prepareParagraph(row.column_2)}</p>
              </div>
            ))}
          </div>
        </div>         
      </div>

      {safetyModalShow && (
        <div className={styles.safetyModal}>
          <div className={styles.safetyModalBg} onClick={closeSafetyModalHandler} />
          <div className={styles.safetyModalWrap}>
            <div className={styles.safetyModalBox}>
              <div className={styles.safetyModalClose} onClick={closeSafetyModalHandler} />
              <p className={styles.safetyModalTitle}>{live.ClinicalStudyModal.modalTitle}</p>
              <div className={styles.safetyModalContent} dangerouslySetInnerHTML={{ __html: live.ClinicalStudyModal.modalContent }} />
            </div>
          </div>
        </div>
      )}      
    </div>
  )
}
