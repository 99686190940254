import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import { srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ science }) => (
  <div className={styles.doctors} id="doctors">
    <div className={styles.wrap}>
      <div className={styles.top}>
        <h2 className={styles.title}>{science.navTitle2}</h2>
        <div className={styles.establishments}>
          {science.establishments &&
            science.establishments.map(item => (
              <div className={styles.establishmentsItem}>
                <img
                  className={styles.logo}
                  {...srcSetProps(sanityImageUrl(item.logo))}
                  alt={item.logo?.caption}
                  key={item._key}
                />
              </div>
            ))}
        </div>
      </div>

      <div className={cx(styles.list, styles.active)}>
        <div className={styles.items}>
          {science.doctors &&
            science.doctors.map(item => (
              <div className={styles.item} key={item._key}>
                <img
                  className={styles.image}
                  {...srcSetProps(sanityImageUrl(item.image))}
                  alt={item.image?.caption}
                />
                <p className={styles.name}>{item.name}</p>
                <p className={styles.info}>{item.position}</p>
                <a href={item.podcastUrl} target="_blank" rel="noreferrer" className={styles.link}>Watch interview</a>
              </div>
            ))}
        </div>
      </div>
    </div>
  </div>
)
