import React from 'react'
import Layout from '../../utils/layout'
import SEO from '../../utils/seo'
import Header from '../../components/WhitePaper/King/Header'
import Navigation from '../../components/WhitePaper/Nav/Navigation'
import NavigationWrap from '../../components/WhitePaper/Nav/NavigationWrap'
import NavigationContent from '../../components/WhitePaper/Nav/NavigationContent'
import Abstract from '../../components/WhitePaper/King/Abstract'
import Declining from '../../components/WhitePaper/King/Declining'
import Options from '../../components/WhitePaper/King/Options'
import Hypotheses from '../../components/WhitePaper/King/Hypotheses'
import Study from '../../components/WhitePaper/King/Study'
import Data from '../../components/WhitePaper/King/Data'
import Analysis from '../../components/WhitePaper/King/Analysis'
import Biomarker from '../../components/WhitePaper/King/Biomarker'
import Symptom from '../../components/WhitePaper/King/Symptom'
import Findings from '../../components/WhitePaper/King/Findings'
import Limitations from '../../components/WhitePaper/King/Limitations'
import References from '../../components/WhitePaper/King/References'
import Appendix from '../../components/WhitePaper/King/Appendix'
import { titleToId, buildLink } from '../../utils/helpers'

export default ({ pageContext: { data } }) => {
  const links = [
    buildLink(data.abstract.title, [data.declining.title, data.options.title, data.hypotheses.title]),
    buildLink(data.study.titleSectionNav, [data.study.title, data.data.title, data.analysis.title]),
    buildLink(data.biomarker.titleSection, [data.biomarker.title, data.symptom.title]),
    buildLink(data.findings.titleSection, [data.findings.title, data.limitations.title]),
    buildLink(data.references.title),
    buildLink(data.appendix.title),
  ]

  return (
    <Layout data={data}>
      <SEO {...data.seo} />
      <Header links={links} header={data.header} />
      <NavigationWrap>
        <Navigation links={links} />
        <NavigationContent>
          <Abstract abstract={data.abstract} />
          <Declining declining={data.declining} />
          <Options options={data.options} />
          <Hypotheses hypotheses={data.hypotheses} />
          <Study study={data.study} />
          <Data data={data.data} />
          <Analysis analysis={data.analysis} />
          <Biomarker biomarker={data.biomarker} />
          <Symptom symptom={data.symptom} />
          <Findings findings={data.findings} />
          <Limitations limitations={data.limitations} />
          <References references={data.references} />
          <Appendix appendix={data.appendix} />
        </NavigationContent>
      </NavigationWrap>
    </Layout>
  )
}
