import React from 'react'
import { Link } from 'gatsby'
import { srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ header }) => {
  if (!header) return null

  return (
    <div className={styles.header}>
      <img className={styles.image} {...srcSetProps(sanityImageUrl(header.image))} alt={header.image?.caption} />
      <div className={styles.wrap}>
        <div className={styles.text}>
          <h1 className={styles.title}>{header.title}</h1>
          <p className={styles.description}>{header.description}</p>
          <Link className={styles.button} to={header.buttonUrl}>{header.buttonText}</Link>
        </div>
      </div>
    </div>
  )
}
