import React from 'react'
import { titleToId, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ approach }) => {
  if (!approach || approach.isHide) return null

  return (
    <div className={styles.approach}>
      <span className={styles.anchor} id={titleToId(approach.title)}/>
      <div className={styles.wrap}>
        <div className={styles.image}>
          <img {...srcSetProps(sanityImageUrl(approach.image))} className={styles.imageSrc} alt={approach.image?.caption} />
        </div> 
        <div className={styles.info}>
          <h2 className={styles.title}>{approach.title}</h2>
          <div className={styles.items}>
            {approach.items && approach.items.map(item => (
              <div className={styles.item} key={item._key}>
                <p className={styles.step}>{item.title}</p>
                <p className={styles.text}>{item.text}</p>
              </div>
            ))}
          </div>
        </div>     
      </div>
    </div>
  )
}
