import React from 'react'
import { Link } from 'gatsby'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl, clickTrack } from '../../../utils/helpers'
import SwiperObj, { FreeMode, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { PopupButton } from '@typeform/embed-react'
import cx from 'classnames'
import styles from './style.module.sass'

const Button = ({ buttonUrl, buttonText, classes }) => {
  return (
    <>
      {buttonUrl?.includes('https://quiz/') ? (
        <PopupButton
          id={buttonUrl.replace('https://quiz/', '')}
          as="a"
          onReady={() => clickTrack(buttonText)}
          transitiveSearchParams={true}
          className={classes}
        >
          {buttonText}
        </PopupButton>
      ) : (
        <a href={buttonUrl} onClick={urlWithSearchParamsHandler} className={classes}>
          {buttonText}
        </a>
      )}
    </>
  )
}

export default ({ header }) => {
  if (!header) return null

  SwiperObj.use([FreeMode, Mousewheel])

  const sliderSettings = {
    spaceBetween: 26,
    slidesPerView: 3,
    freeMode: {
      enabled: true,
      momentumVelocityRatio: 0.5,
    },
    mousewheel: {
      releaseOnEdges: true,
      sensitivity: 0.5,
      forceToAxis: true,
    },
    className: styles.slider,
    breakpoints: {
      0: {
        slidesPerView: 1.8,
        spaceBetween: 16,
      },
      701: {
        slidesPerView: 3,
      },
    },
  }

  return (
    <div className={styles.header}>
      <div className={styles.wrap}>
        <p className={styles.titleBefore}>{header.title}</p>
        <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: header.description }} />

        <div className={styles.protocols}>
          <Swiper {...sliderSettings}>
            {header.protocols &&
              header.protocols.map(item => (
                <SwiperSlide className={styles.item}>
                  <Link to={item.buttonUrl} className={styles.content}>
                    <img
                      className={styles.image}
                      {...srcSetProps(sanityImageUrl(item.image))}
                      alt={item.image?.caption}
                    />
                    <span className={styles.name}>{item.name}</span>
                    <div className={styles.bottom}>
                      <p className={styles.info}>{item.title}</p>
                      <p className={styles.more}>{item.buttonText}</p>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>

        <Button buttonUrl={header.buttonUrl} buttonText={header.buttonText} classes={cx(styles.link)} />
      </div>
    </div>
  )
}
