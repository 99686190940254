import React from 'react'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ benefits }) => {
  if (!benefits || benefits.isHide) return null

  return (
    <div className={styles.benefits}>
      <div className={styles.items}>
        {benefits.items && benefits.items.map(item => (
          <div className={styles.item} key={item._key}>
            <div className={styles.image}>
              <img
                {...srcSetProps(sanityImageUrl(item.image))}
                className={styles.imageSrc}
                alt={item.image?.caption}
              />
            </div>
            <div className={styles.wrap}>
              <div className={styles.text}>
                <p className={styles.itemTitle}>{item.title}</p>
                <p className={styles.itemDescription} dangerouslySetInnerHTML={{ __html: item.description }} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
