import React from 'react'
import cx from 'classnames'

import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Title from '../../Shared/UI/Title'
import Content from '../../Shared/UI/Content'
import Button from '../../Shared/UI/Button'

import styles from './style.module.sass'

import { prepareParagraph, timeSince } from '../../../utils/helpers'

export default ({ openPositions }) => {
  return (
    <Container classes={styles.outer} id="positions">
      <Wrapper classes={styles.wrapper}>
        <Item classes={styles.contentContainer}>
          <Title classes={styles.title}>{openPositions.title}</Title>
        </Item>
        <Item classes={cx(styles.contentContainer, styles.positions)}>
          {openPositions.positions &&
            openPositions.positions.map(position => {
              return (
                <div className={styles.position} key={position._key}>
                  <div className={styles.position__location}>{position.location}</div>
                  <div className={styles.position__heading}>{position.title}</div>
                  {/*<div className={styles.position__salary}>*/}
                  {/*  Salary Range:&nbsp;*/}
                  {/*  <span className={styles.position__salary_range}>{position.salaryRange}</span>*/}
                  {/*</div>*/}
                  <div className={styles.position__footer}>
                    {position.publishedAt ? (
                      <div className={styles.position__footer__calendar}>
                        {timeSince(new Date(position.publishedAt))}
                      </div>
                    ) : (
                      <div />
                    )}
                    <Button btnType="full" classes={styles.position__footer__apply_now} url={position.applyNowUrl}>
                      Apply Now
                    </Button>
                  </div>
                </div>
              )
            })}
        </Item>
      </Wrapper>
    </Container>
  )
}
