import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import { sortComponents } from '../utils/helpers'
import Header from '../components/PartnerPage/Header'
import Programs from '../components/PartnerPage/Programs'
import Products from '../components/PartnerPage/Products'
import Science from '../components/PartnerPage/Science'
import Athletes from '../components/PartnerPage/Athletes'
import Reviews from '../components/PartnerPage/Reviews'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Programs programs={data.programs} />,
      priority: data.programs?.priority || 99,
    },
    {
      render: <Products products={data.protocols} />,
      priority: data.protocols?.priority || 99,
    },
    {
      render: <Science science={data.science} />,
      priority: data.science?.priority || 99,
    },
    {
      render: <Athletes athletes={data.athletes} />,
      priority: data.athletes?.priority || 99,
    },
    {
      render: <Reviews reviews={data.reviews} />,
      priority: data.reviews?.priority || 99,
    },
  ].sort(sortComponents)

  return (
    <Layout data={data} signUpUrl={data.header?.buttonUrl} signUpText={data.header?.buttonText}>
      <SEO {...data.seo} />
      <Header header={data.header} />
      {components.map(component => component.render)}
    </Layout>
  )
}
