import React from 'react'
import { Link } from 'gatsby'
import { srcSetProps, urlWithSearchParamsHandler, sanityImageUrl } from '../../../utils/helpers'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ bloodFlow }) => {
  if (!bloodFlow || bloodFlow.isHide) return null

  return (
    <div className={styles.bloodFlow}>
      <div className={styles.wrap}>
        <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: bloodFlow.title }} />

        <div className={styles.items}>
          {bloodFlow.items &&
            bloodFlow.items.map(item => (
              <div className={styles.item} key={item._key}>
                <div className={styles.left}>
                  <p className={styles.key}>{item.title}</p>
                  <p className={styles.info}>{item.description}</p>
                  <div className={styles.links}>
                    <a href={item.getStartedButtonUrl} onClick={urlWithSearchParamsHandler} className={styles.start}>
                      {item.getStartedButtonText}
                    </a>
                    <Link className={styles.more} to={item.learnMoreButtonUrl}>
                      {item.learnMoreButtonText}
                    </Link>
                  </div>
                </div>
                <div className={styles.middle}>
                  <img
                    {...srcSetProps(sanityImageUrl(item.image))}
                    alt={item.image?.caption}
                    className={styles.image}
                  />
                </div>
                <div className={styles.right}>
                  {item.benefits &&
                    item.benefits.map(benefit => (
                      <div className={styles.benefit}>
                        <img
                          {...srcSetProps(sanityImageUrl(benefit.icon))}
                          alt={benefit.icon?.caption}
                          className={styles.benefitIcon}
                        />
                        <p className={styles.benefitText}>{benefit.text}</p>
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
