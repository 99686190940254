import React from 'react'
import { prepareParagraph, titleToId } from '../../../../utils/helpers'
import styles from './style.module.sass'

export default ({ sideEffects }) => {
  return (
    <div className={styles.sideEffects}>
      <div className={styles.anchor} id={titleToId(sideEffects.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{sideEffects.title}</h2>
      <div className={styles.content}>
        <div className={styles.left}>
          <p className={styles.info}>{prepareParagraph(sideEffects.info)}</p>
        </div>
        <div className={styles.right}>
          {sideEffects.items &&
            sideEffects.items.map(row => (
              <div className={styles.supplement} key={row._key}>
                <p className={styles.supplementTitle}>{row.title}</p>
                <p className={styles.supplementValue}>{row.value}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
