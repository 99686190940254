import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import { sortComponents } from '../utils/helpers'
import ProductSchema from '../components/Shared/Schema/Product'
import FaqSchema from '../components/Shared/Schema/Faq'
import Header from '../components/ManWeightLossPage/Header'
import Info from '../components/ManWeightLossPage/Info'
import Calculator from '../components/ManWeightLossPage/Calculator'
import Steps from '../components/ManWeightLossPage/Steps'
import Customers from '../components/ManWeightLossPage/Customers'
import Options from '../components/ManWeightLossPage/Options'
import Biology from '../components/ManWeightLossPage/Biology'
import Product from '../components/ManWeightLossPage/Product'
import Faq from '../components/ManWeightLossPage/Faq'
import FoodAndDrugAdministration from '../components/ManWeightLossPage/FoodAndDrugAdministration'
import References from '../components/ManWeightLossPage/References'
import Wim from '../components/ManWeightLossPage/Wim'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Info info={data.info} />,
      priority: data.info?.priority || 99,
    },
    {
      render: <Calculator calculator={data.calculator} />,
      priority: data.calculator?.priority || 99,
    },
    {
      render: <Steps steps={data.steps} />,
      priority: data.steps?.priority || 99,
    },
    {
      render: <Customers customers={data.customers} />,
      priority: data.customers?.priority || 99,
    },
    {
      render: <Options options={data.options} />,
      priority: data.options?.priority || 99,
    },
    {
      render: <Biology biology={data.biology} />,
      priority: data.biology?.priority || 99,
    },
    {
      render: <Product product={data.product} />,
      priority: data.product?.priority || 99,
    },
    {
      render: <Faq faq={data.faq} />,
      priority: data.faq?.priority || 99,
    },
    {
      render: <FoodAndDrugAdministration foodAndDrugAdministration={data.foodAndDrugAdministration} />,
      priority: data.foodAndDrugAdministration?.priority || 99,
    },
    {
      render: <References references={data.references} />,
      priority: data.references?.priority || 99,
    },
    {
      render: <Wim wim={data.wim} />,
      priority: data.wim?.priority || 99,
    },
  ].sort(sortComponents)

  return (
    <Layout data={data} signUpUrl={data.header?.buttonUrl} signUpText={data.header?.buttonText}>
      <SEO {...data.seo} />
      <ProductSchema {...data.productSchema} />
      {(data?.faq?.faqList?.items || data?.faq?.items) && (
        <FaqSchema questions={data?.faq?.faqList?.items || data?.faq?.items} />
      )}
      <Header header={data.header} />
      {components.map(component => component.render)}
    </Layout>
  )
}
