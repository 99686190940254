import React, { useMemo } from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import Form from '../Form'
import getYouTubeId from 'get-youtube-id'
import PostPreview from '../PostPreview'
import BlockContent from '@sanity/block-content-to-react'
import { srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

const CATEGORIES_MAP = {
  articles: 'Article',
  podcasts: 'Podcast',
  guides: 'Guide',
}

const publishDate = date => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  return new Date(date).toLocaleDateString('en-US', options)
}

const blockSerializers = {
  marks: {
    link: props => (
      <a href={props.mark.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    ),
  },
  types: {
    youtube: ({ node }) => {
      const { url } = node
      const id = getYouTubeId(url)
      return (
        <div className={styles.embedYoutube}>
          <iframe src={`https://www.youtube.com/embed/${id}`} frameBorder="0" allowfullscreen=""></iframe>
        </div>
      )
    },
  },
}

const Post = ({
  data,
  similarPosts,
  post: { title, publishedAt, readingTime, author, imageBlock, body, category },
}) => {
  const { pageSettings, blogSettings } = data
  const postCategory = category.title.toLowerCase()

  return (
    <div className={styles.post}>
      <div className={styles.wrap}>
        <Link to={pageSettings.path} className={styles.link}>
          View All Resources
        </Link>

        <div className={styles.top}>
          <span className={styles.type}>{CATEGORIES_MAP[postCategory]}</span>
          {readingTime && <span className={styles.time}>{readingTime} min read</span>}
        </div>

        <div className={styles.body}>
          <div className={styles.bodyLeft}>
            <div className={styles.image}>
              <img
                className={styles.imageSrc}
                alt={imageBlock?.caption}
                {...srcSetProps(
                  sanityImageUrl(imageBlock),
                  [
                    [800, 800],
                    [480, 480],
                  ],
                  70,
                  1000,
                )}
              />
            </div>
            <div className={styles.bodyForm}>
              <Form />
            </div>
          </div>

          <div className={styles.bodyRight}>
            {publishedAt && <div className={styles.date}>{publishDate(publishedAt)}</div>}
            <h1 className={styles.title}>{title}</h1>
            <BlockContent className={styles.content} blocks={body} serializers={blockSerializers} />
            <Link to={pageSettings.path} className={styles.link}>
              View All Resources
            </Link>
          </div>
        </div>

        <Form />
        <SimilarPosts {...{ pageSettings, blogSettings, postCategory, similarPosts }} />
      </div>
    </div>
  )
}

const SimilarPosts = ({ pageSettings, blogSettings, postCategory, similarPosts }) => {
  const categoryInfo = useMemo(() => {
    return {
      articles: {
        title: blogSettings.articlesTitle,
        btnUrl: blogSettings.articlesLink,
        btnText: 'See All Articles',
        maxPosts: 3,
        style: 'sectionContentArticles',
      },
      podcasts: {
        title: blogSettings.podcastsTitle,
        btnUrl: blogSettings.podcastsLink,
        btnText: 'See All Podcasts',
        maxPosts: 2,
        style: 'sectionContentPodcasts',
      },
      guides: {
        title: blogSettings.guidesTitle,
        btnUrl: blogSettings.guidesLink,
        btnText: 'See All Guides',
        maxPosts: 3,
        style: 'sectionContentGuides',
      },
    }[postCategory]
  }, [blogSettings, postCategory])

  return (
    <div className={styles.section}>
      <p className={styles.sectionTitle}>{postCategory}</p>
      <div className={styles.sectionTop}>
        <h2 className={styles.sectionH2}>{categoryInfo.title}</h2>
        <Link className={styles.sectionLink} to={`${pageSettings.path}${categoryInfo.btnUrl}`}>
          {categoryInfo.btnText}
        </Link>
      </div>
      <div className={cx(styles.sectionContent, styles[categoryInfo.style])}>
        {similarPosts &&
          similarPosts
            .slice(0, categoryInfo.maxPosts)
            .map(post => (
              <PostPreview key={post._id} {...post} pageSettings={pageSettings} blogSettings={blogSettings} />
            ))}
      </div>
    </div>
  )
}

export default Post
