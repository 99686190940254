import React from 'react'
import { sanityImageUrl, prepareParagraph, titleToId } from '/src/utils/helpers'
import styles from './style.module.sass'

export default ({ results }) => {
  return (
    <div className={styles.results}>
      <div className={styles.anchor} id={titleToId(results.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{results.title}</h2>

      <div className={styles.content}>
        {results.content &&
          results.content.map(item => (
            <div className={styles.item} key={item._key}>
              {item._type === 'paragraph' && <p className={styles.paragraph}>{prepareParagraph(item.paragraph)}</p>}

              {item._type === 'iconTitle' && (
                <div className={styles.info}>
                  <img src={sanityImageUrl(item.icon)} className={styles.infoImage} alt={item.icon?.caption} />
                  <p className={styles.infoText}>{prepareParagraph(item.title)}</p>
                </div>
              )}

              {item._type === 'titleParagraph' && (
                <div className={styles.block}>
                  <div className={styles.blockTitle}>
                    <img src={sanityImageUrl(item.icon)} className={styles.blockTitleImage} alt={item.icon?.caption} />
                    <p className={styles.blockTitleText}>{prepareParagraph(item.title)}</p>
                  </div>
                  <p className={styles.blockParagraph}>{prepareParagraph(item.paragraph)}</p>
                </div>
              )}

              {item._type === 'table' && (
                <div className={styles.table}>
                  <div className={styles.anchor} id={item.title.replace(' ', '_').toLowerCase()} />
                  <p className={styles.tableTitle}>{item.title}</p>
                  <img src={sanityImageUrl(item.image)} className={styles.tableImage} alt={item.image?.caption} />
                  {item.info && <p className={styles.tableInfo}>{prepareParagraph(item.info)}</p>}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}
