import React from 'react'
import { sanityImageUrl, prepareParagraph } from '../../../utils/helpers'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ science }) => {
  if (!science || science.isHide) return null

  return (
    <div className={styles.science}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{science.title}</h2>
        <p className={styles.description}>{science.description}</p>
        <div className={styles.items}>
          {science.ingredients &&
            science.ingredients.map(ingredient => (
              <div className={styles.item} key={ingredient._key}>
                <img src={sanityImageUrl(ingredient.image)} className={styles.image} alt={ingredient.image?.caption} />
                <p className={styles.name}>{ingredient.name}</p>
                <p className={styles.desc}>{ingredient.info}</p>

                <div className={styles.faq}>
                  {ingredient.faq &&
                    ingredient.faq.map(faq => (
                      <div key={faq._key}>
                        <p className={styles.faqQuestion}>{faq.question}</p>
                        <p className={styles.faqanswer}>{prepareParagraph(faq.answer)}</p>
                      </div>
                    ))}
                </div>

                {ingredient.buttonText && ingredient.buttonUrl && (
                  <Link to={ingredient.buttonUrl} className={styles.link}>
                    {ingredient.buttonText}
                  </Link>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
