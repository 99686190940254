import React from 'react'
import { urlWithSearchParamsHandler } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ cta }) => {
  return (
    <div className={styles.cta}>
      <div className={styles.wrap}>
        <div className={styles.text}>
          <h2 className={styles.title}>{cta.title}</h2>
          <a href={cta.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>
            {cta.buttonText}
          </a>
        </div>
      </div>
    </div>
  )
}
