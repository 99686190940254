import React from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ boost }) => {
  if (!boost || boost.isHide) return null

  return (
    <div className={styles.boost}>
      <div className={styles.wrap}>
        <div className={styles.info}>
          <h2 className={styles.title}>{boost.title}</h2>
          <a href={boost.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>{boost.buttonText}</a>
        </div>
        <div className={styles.image}>
          <img {...srcSetProps(sanityImageUrl(boost.image))} className={styles.imageSrc} alt={boost.image?.caption} />
        </div>        
      </div>
    </div>
  )
}
