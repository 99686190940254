import React from 'react'
import { prepareParagraph, sanityImageUrl } from '../../../utils/helpers'
import { Link } from 'gatsby'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ reviews }) => {
  if (!reviews || !reviews.reviewList.title || reviews.isHide) return null

  return (
    <div className={styles.reviews}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={styles.top}>
            <h2 className={styles.title}>{reviews.reviewList.title}</h2>
            {reviews.rating?.url && (
              <Link to={reviews.rating.url} className={styles.rating}>
                {reviews.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(reviews.rating.starsImage)} alt={reviews.rating?.text}/>}
                {reviews.rating.text && <span className={styles.ratingText}>{reviews.rating.text}</span>}
              </Link>
            )} 
          </div>

          <div className={styles.info}>
            <p className={styles.infoCount}>{reviews.reviewList.reviewsCount}</p>
            <p className={styles.infoOrder}>{reviews.reviewList.reviewsOrder}</p>
          </div>

          <div className={styles.list}>
            {reviews.reviewList.items &&
              reviews.reviewList.items.map((item, index) => (
                <div className={styles.item} key={item._key}>
                  <div className={styles.left}>
                    <div className={styles.user}>
                      <div className={styles.userInfo}>
                        <p className={styles.name}>{item.name}</p>
                        <p className={styles.verified}>Verified buyer</p>
                      </div>
                    </div>
                    <p className={styles.protocol}>
                      Reviewing: <span>{item.protocol}</span>
                    </p>
                  </div>
                  <div className={styles.middle}>
                    <p className={styles.header}>{item.title}</p>
                    <p className={styles.body}>{prepareParagraph(item.review)}</p>
                  </div>
                  <div className={styles.right}>
                    <p className={styles.date}>{item.date}</p>
                  </div>
                </div>
              ))}
          </div>

          <div className={styles.link}>
            <a
              className={styles.button}
              href={reviews.reviewList.buttonUrl}
              target="_blank"
              dangerouslySetInnerHTML={{ __html: reviews.reviewList.buttonText }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
