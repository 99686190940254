import React from 'react'
import { urlWithSearchParamsHandler, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ save }) => {
  if (!save || save.isHide) return null

  return (
    <div className={styles.save}>
      <div className={styles.wrap}>
        <p className={styles.stats}>{save.stats}</p>
        <h2 className={styles.title}>{save.title}</h2>
        <div className={styles.items}>
          {save.items && save.items.map(item => (
            <div className={styles.item} key={item._key}>
              <img className={styles.image} src={sanityImageUrl(item.image)} alt={item.image?.caption} />
              <div className={styles.box}>
                <div className={styles.boxTop}>
                  <p className={styles.top}>{item.top}</p>
                  <p className={styles.head}>{item.title}</p>
                  <p className={styles.text}>{item.text}</p>
                </div>
                <a href={item.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>{item.buttonText}</a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}