import React from 'react'
import { srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ buildingBlocks }) => {
  if (!buildingBlocks || buildingBlocks.isHide) return null

  return (
    <div className={styles.buildingBlocks}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{buildingBlocks.title}</h2>

        <div className={styles.container}>
          <img
            {...srcSetProps(sanityImageUrl(buildingBlocks.image))}
            alt={buildingBlocks.image?.caption}
            className={styles.image}
          />
          <img
            {...srcSetProps(sanityImageUrl(buildingBlocks.imageMobile))}
            alt={buildingBlocks.imageMobile?.caption}
            className={styles.imageMobile}
          />

          <div className={cx(styles.text, styles.text1)}>
            <p className={styles.textTitle}>{buildingBlocks.testosteroneTitle}</p>
            <p className={styles.textProtocol}>{buildingBlocks.testosteroneProtocol}</p>
            <p className={styles.textDescription}>{buildingBlocks.testosteroneDescription}</p>
          </div>
          <div className={cx(styles.text, styles.text2)}>
            <p className={styles.textTitle}>{buildingBlocks.buildingBlocksTitle}</p>
            <p className={styles.textProtocol}>{buildingBlocks.buildingBlocksProtocol}</p>
            <p className={styles.textDescription}>{buildingBlocks.buildingBlocksDescription}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
