import React from 'react'
import { prepareParagraph, sanityImageUrl } from '../../../utils/helpers'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ recruitment }) => {
  if (!recruitment || recruitment.isHide) return null

  return (
    <div className={styles.recruitment}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{recruitment.title}</h2>
        <div className={styles.items}>
          {recruitment.items &&
            recruitment.items.map(item => (
              <div className={styles.item} key={item._key}>
                <img src={sanityImageUrl(item.icon)} className={styles.itemImage} alt={item.icon?.caption} />
                <p className={styles.itemTitle}>{item.title}</p>
                <p className={styles.itemText}>{prepareParagraph(item.text)}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}