import React from 'react'
import { urlWithSearchParamsHandler, sanityImageUrl, clickTrack } from '../../../utils/helpers'
import { Link } from 'gatsby'
import { PopupButton } from '@typeform/embed-react'
import SwiperObj, { FreeMode, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ top }) => {
  if (!top) return null

  SwiperObj.use([FreeMode, Mousewheel])

  const sliderSettings = {
    spaceBetween: top.isLifeStyleVersion ? 27 : 64,
    slidesPerView: 3,
    freeMode: {
      enabled: true,
      momentumVelocityRatio: 0.5,
    },
    mousewheel: {
      releaseOnEdges: true,
      sensitivity: 0.5,
      forceToAxis: true,
    },
    className: styles.slider,
    breakpoints: {
      0: {
        slidesPerView: 2.2,
        spaceBetween: top.isLifeStyleVersion ? 16 : 32
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: top.isLifeStyleVersion ? 27 : 64
      }
    },
  }

  return (
    <div className={cx(styles.top, {
      [styles.topLifestyleVersion]: top.isLifeStyleVersion
    })}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.column}>
            <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: top.title }} />
            <p className={styles.info}>{top.info}</p>
          </div>
          <div className={styles.column}>
            <PopupButton
              id={top.buttonUrl.replace('https://quiz/', '')}
              as="a"
              onReady={() => clickTrack(top.buttonUrl)}
              transitiveSearchParams={true}
              className={styles.btn}
            >
              {top.buttonText}
            </PopupButton>
            {top.rating?.url && (
              <Link to={top.rating.url} className={styles.rating}>
                {top.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(top.rating.starsImage)} alt={top.rating?.text}/>}
                {top.rating.text && <span className={styles.ratingText}>{top.rating.text}</span>}
              </Link>
            )}            
          </div>
          <p className={cx(styles.info, styles.infoMobile)}>{top.info}</p>
        </div>
        <div className={styles.bottom}>
          <Swiper {...sliderSettings}>
            {top.isLifeStyleVersion ? (
              <>
                {top.lifestyleItems && top.lifestyleItems.map(item => (
                  <SwiperSlide className={styles.lifestyle} key={item._key}>
                    <img src={sanityImageUrl(item.image)} className={styles.image} alt={item.image?.caption} />
                    <p className={styles.name}>{item.title}</p>

                    <div className={styles.line}>
                      <p className={styles.description}>{item.description}</p>
                      <div className={styles.btns}>
                        <Link className={styles.btnsMore} to={item.buttonUrl1}>
                          {item.buttonText1}
                        </Link>
                        <a
                          href={item.buttonUrl2}
                          onClick={urlWithSearchParamsHandler}
                          className={styles.btnsStarted}
                        >
                          {item.buttonText2}
                        </a>                    
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </>
            ) : (
              <>
                {top.productItems && top.productItems.map(item => (
                  <SwiperSlide className={styles.protocol} key={item._key}>
                    <div className={styles.box}>
                      <img src={sanityImageUrl(item.image)} className={styles.image} alt={item.image?.caption} />
                      <p className={styles.name}>{item.title}</p>
                      <p className={styles.description}>{item.description}</p>
                    </div>
                    <div className={styles.btns}>
                      <Link className={styles.btnsMore} to={item.buttonUrl1}>
                        {item.buttonText1}
                      </Link>
                      <a
                        href={item.buttonUrl2}
                        onClick={urlWithSearchParamsHandler}
                        className={styles.btnsStarted}
                      >
                        {item.buttonText2}
                      </a>
                    </div>
                  </SwiperSlide>
                ))}
              </>
            )}
          </Swiper>
        </div>
        <div className={styles.mobileList}>
          {top.isLifeStyleVersion ? (
            <>
              {top.lifestyleItems && top.lifestyleItems.map(item => (
                <div className={styles.lifestyle} key={item._key}>
                  <img src={sanityImageUrl(item.image)} className={styles.image} alt={item.image?.caption} />
                  <p className={styles.name}>{item.title}</p>

                  <div className={styles.line}>
                    <p className={styles.description}>{item.description}</p>
                    <div className={styles.btns}>
                      <Link className={styles.btnsMore} to={item.buttonUrl1}>
                        {item.buttonText1}
                      </Link>
                      <a
                        href={item.buttonUrl2}
                        onClick={urlWithSearchParamsHandler}
                        className={styles.btnsStarted}
                      >
                        {item.buttonText2}
                      </a>                    
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              {top.productItems && top.productItems.map(item => (
                <div className={styles.protocol} key={item._key}>
                  <div className={styles.box}>
                    <img src={sanityImageUrl(item.image)} className={styles.image} alt={item.image?.caption} />
                    <p className={styles.name}>{item.title}</p>
                    <p className={styles.description}>{item.description}</p>
                  </div>
                  <div className={styles.btns}>
                    <Link className={styles.btnsMore} to={item.buttonUrl1}>
                      {item.buttonText1}
                    </Link>
                    <a
                      href={item.buttonUrl2}
                      onClick={urlWithSearchParamsHandler}
                      className={styles.btnsStarted}
                    >
                      {item.buttonText2}
                    </a>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        {top.rating?.url && (
          <Link to={top.rating.url} className={cx(styles.rating, styles.ratingMobile)}>
            {top.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(top.rating.starsImage)} alt={top.rating?.text}/>}
            {top.rating.text && <span className={styles.ratingText}>{top.rating.text}</span>}
          </Link>
        )}               
      </div>
    </div>
  )
}