import React from 'react'
import { Link } from 'gatsby'
import { srcSetProps, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ howItWorks }) => {
  if (!howItWorks || howItWorks.isHide) return null

  return (
    <div className={styles.howItWorks}>
      <div className={styles.wrap}>
        <p className={styles.smallTitle}>{howItWorks.smallTitle}</p>

        <div className={styles.top}>
          <h2 className={styles.title}>{howItWorks.title}</h2>
          <Link className={styles.button} to={howItWorks.buttonUrl}>
            {howItWorks.buttonText}
          </Link>
        </div>

        <div className={styles.items}>
          {howItWorks.items &&
            howItWorks.items.map(item => (
              <div className={styles.item} key={item._key}>
                <img
                  {...srcSetProps(sanityImageUrl(item.image))}
                  className={styles.itemImage}
                  alt={item.image?.caption}
                />
                <p className={styles.itemTitle}>{item.title}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
