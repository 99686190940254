import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import { sortComponents } from '../utils/helpers'
import Header from '../components/ScienceHomePage/Header'
import Menu from '../components/ScienceHomePage/Menu'
import Products from '../components/ScienceHomePage/Products'
import Studies from '../components/ScienceHomePage/Studies'
import Approach from '../components/ScienceHomePage/Approach'
import Ceo from '../components/ScienceHomePage/Ceo'
import Team from '../components/ScienceHomePage/Team'
import Boost from '../components/ScienceHomePage/Boost'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Products products={data.products} />,
      priority: data.products?.priority || 99,
    },
    {
      render: <Studies studies={data.studies} />,
      priority: data.studies?.priority || 99,
    },
    {
      render: <Approach approach={data.approach} />,
      priority: data.approach?.priority || 99,
    },
    {
      render: <Ceo ceo={data.ceo} />,
      priority: data.ceo?.priority || 99,
    },
    {
      render: <Team team={data.team} />,
      priority: data.team?.priority || 99,
    },
    {
      render: <Boost boost={data.boost} />,
      priority: data.boost?.priority || 99,
    }
  ].sort(sortComponents)

  return (
    <Layout data={data} signUpUrl={data.header?.buttonUrl} signUpText={data.header?.buttonText}>
      <SEO {...data.seo} />
      <Header header={data.header} />
      <Menu menu={data.menu} />
      {components.map(component => component.render)}
    </Layout>
  )
}
