import React from 'react'
import { urlWithSearchParamsHandler, sanityImageUrl } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ benefits }) => {
  if (!benefits || benefits.isHide) return null

  return (
    <div className={styles.benefits}>
      <img className={styles.benefitsBg} src={sanityImageUrl(benefits.image)} alt={benefits.image?.caption} />
      <img className={styles.benefitsBgMobile} src={sanityImageUrl(benefits.imageMobile)} alt={benefits.imageMobile?.caption} />
      <div className={styles.wrap}>
        <div className={styles.column}>
          <h2 className={styles.title}>{benefits.title}</h2>
          <ul className={styles.list}>
            {benefits.benefits && benefits.benefits.map((item, index) => <li key={index}>{item}</li>)}
          </ul>
          <a href={benefits.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>{benefits.buttonText}</a>
        </div>
        <div className={styles.column}>
          {benefits.results && benefits.results.map(result => (
            <div className={styles.result} key={result._key}>
              <img className={styles.resultImage} src={sanityImageUrl(result.image)} alt={result.image?.caption} />
              <p className={styles.resultText}>{result.item}</p>
              <p className={styles.resultValue}>{result.value}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}