import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { urlWithSearchParamsHandler, sanityImageUrl, srcSetProps, prepareParagraph } from '../../../utils/helpers'
import SwiperObj, { FreeMode, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import styles from './style.module.sass'

export default ({ info, protocols }) => {
  SwiperObj.use([FreeMode, Mousewheel])

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin)
    const animatedLinks = document.querySelectorAll(`.${styles.benefits} a`)
    animatedLinks.forEach(link => {
      link.addEventListener('click', e => {
        if (e.target.hash) {
          e.preventDefault()
          scrollTo(e.target.hash.replace('#', ''))
        }
      })
    })
  }, [])

  const scrollTo = anchor => {
    gsap.to(window, {
      duration: 1.5,
      scrollTo: document.getElementById(anchor),
    })
  }

  const sliderSettings = {
    spaceBetween: 32,
    slidesPerView: 3.2,
    freeMode: {
      enabled: true,
      momentumVelocityRatio: 0.5,
    },
    mousewheel: {
      releaseOnEdges: true,
      sensitivity: 0.5,
      forceToAxis: true,
    },
    className: styles.slider,
    breakpoints: {
      0: {
        slidesPerView: 1.3,
        spaceBetween: 16,
      },
      830: {
        slidesPerView: 2.2,
      },
      1200: {
        slidesPerView: 3.2,
      },
      1500: {
        slidesPerView: 4.2,
      },
    },
  }

  if (!info || !protocols) {
    return null
  }

  return (
    <div className={styles.protocols}>
      <div className={styles.wrap}>
        <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: info.title }} />
        <ul className={styles.benefits}>
          {info.benefits &&
            info.benefits.map((item, index) => (
              <li className={styles.benefit} key={index}>
                {prepareParagraph(item)}
              </li>
            ))}
        </ul>

        <div className={styles.items}>
          <div className={styles.anchor} id="protocols" />
          <Swiper {...sliderSettings}>
            {protocols.items &&
              protocols.items.filter(item => !item.hiddenInCatalog).map(item => (
                <SwiperSlide className={styles.item}>
                  <div className={styles.content}>
                    <div className={styles.contentTop}>
                      <p className={styles.name}>{item.name}</p>
                      <p className={styles.motto}>
                        <span>{item.motto}</span>
                      </p>
                      <img
                        className={styles.image}
                        {...srcSetProps(sanityImageUrl(item.imageVertical))}
                        alt={item.imageVertical?.caption}
                      />
                      <p className={styles.desc}>{item.descriptionShort}</p>
                    </div>
                    <div className={styles.links}>
                      <a
                        aria-label={item.getStartedButtonAreaLabel}
                        href={item.getStartedButtonUrl}
                        onClick={urlWithSearchParamsHandler}
                        className={styles.blue}
                      >
                        {item.getStartedButtonText}
                      </a>
                      <Link
                        aria-label={item.learnMoreButtonAreaLabel}
                        className={styles.white}
                        to={item.learnMoreButtonUrl}
                      >
                        {item.learnMoreButtonText}
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}
