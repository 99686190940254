import React from 'react'
import { prepareParagraph } from '/src/utils/helpers'
import styles from './style.module.sass'

export default ({ info }) => {
  return (
    <div className={styles.info}>
      <p className={styles.text}>{prepareParagraph(info.info)}</p>
    </div>
  )
}
